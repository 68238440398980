/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { FixedSizeList } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';
import { FaCaretDown, FaCaretUp } from 'react-icons/fa';
import { useTable, useFlexLayout, useSortBy, useResizeColumns } from 'react-table';

import LoadingThreeDots from '../LoadingIndicators/LoadingThreeDots';
import { debounced } from '../../../utils/general';

export const SORT_ASC = 'asc';
export const SORT_DESC = 'desc';

export default function InfiniteScrollTable({
  containerRef,
  columns,
  data,
  defaultSorted,
  rowHeight,
  loadMoreRows,
  onRowClick,
  onRowSort,
  numTotalRows,
  isLoading,
  ...rest
}) {
  const [tableHeight, setTableHeight] = useState(100);

  const table = useTable(
    { columns, data, manualSortBy: true, initialState: { sortBy: defaultSorted } },
    useFlexLayout,
    useSortBy,
    useResizeColumns,
  );

  useEffect(() => {
    if (!containerRef.current) {
      return;
    }

    const resize = debounced(100, () => {
      // container didn't exist when comp unmounted in unit tests
      if (containerRef.current) {
        const headerHeight = 57;
        setTableHeight(containerRef.current.clientHeight - headerHeight);
      }
    });

    // run one time when container is initialized then after each page resize
    resize();

    window.addEventListener('resize', resize);

    // eslint-disable-next-line consistent-return
    return () => window.removeEventListener('resize', resize);
  }, [containerRef]);

  return (
    <S.InfiniteScrollTable {...table.getTableProps()} {...rest}>
      {table.headerGroups.map((headerGroup) => {
        const { key, ...restHeaderGroupProps } = headerGroup.getHeaderGroupProps();

        return (
          <S.THead key={key} {...restHeaderGroupProps}>
            {headerGroup.headers.map((column) => {
              let SortIcon = null;
              if (column.isSorted) {
                SortIcon = column.isSortedDesc ? FaCaretDown : FaCaretUp;
              }

              const { key: keyHeader, ...restColumn } = column.getHeaderProps();

              return (
                <S.TH
                  key={keyHeader}
                  {...restColumn}
                  onClick={() => {
                    if (!column.canSort) {
                      return;
                    }

                    onRowSort(column.id, column.isSortedDesc ? SORT_ASC : SORT_DESC);
                    column.toggleSortBy(!column.isSortedDesc);
                  }}
                  className={[
                    column.isResizing ? 'resizing' : '',
                    column.headerClassName || '',
                  ].join(' ')}
                >
                  {column.render('Header')}
                  {SortIcon && (
                    <SortIcon size='15' color='#b4b4b4' style={{ marginLeft: '20px' }} />
                  )}
                  {column.canResize && <S.Resizer {...column.getResizerProps()} />}
                </S.TH>
              );
            })}
          </S.THead>
        );
      })}
      <S.TBody {...table.getTableBodyProps()}>
        <InfiniteLoader
          isItemLoaded={(index) => index < data.length}
          itemCount={numTotalRows}
          loadMoreItems={loadMoreRows}
          threshold={0}
        >
          {({ onItemsRendered, ref }) => (
            <FixedSizeList
              onItemsRendered={onItemsRendered}
              ref={ref}
              height={tableHeight}
              itemCount={numTotalRows}
              itemSize={rowHeight}
            >
              {({ index, style }) => {
                const row = table.rows[index];
                if (!row) {
                  return null;
                }

                table.prepareRow(row);
                const { key, ...restRowProps } = row.getRowProps({
                  style,
                });

                return (
                  <S.TR
                    key={key}
                    {...restRowProps}
                    onClick={() => onRowClick(row.original)}
                    className={index % 2 === 0 ? 'even' : 'odd'}
                  >
                    {row.cells.map((cell) => {
                      const { key: keyCell, ...restCellProps } = cell.getCellProps();

                      return (
                        <S.TD
                          key={keyCell}
                          {...restCellProps}
                          className={cell.column.cellClassName}
                        >
                          {cell.render('Cell')}
                        </S.TD>
                      );
                    })}
                  </S.TR>
                );
              }}
            </FixedSizeList>
          )}
        </InfiniteLoader>
        {isLoading && (
          <S.Loader>
            <S.LoadingThreeDots
              numRows={numTotalRows}
              tableHeight={tableHeight}
              rowHeight={rowHeight}
              color='#b4b4b4'
            />
          </S.Loader>
        )}
      </S.TBody>
    </S.InfiniteScrollTable>
  );
}

const S = {};

S.InfiniteScrollTable = styled.div`
  .table-cell {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

S.THead = styled.div`
  background-color: #ffffff;
  color: black;
  font-size: 15px;
  font-weight: 700;
  font-family: ${(props) => props.theme.fonts.fontFamilyDefault};
  height: 57px;
  border-bottom: 2px solid #e9e9e9;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

S.TH = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-start;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &.resizing {
    pointer-events: none;
  }

  &:first-child {
    padding-left: 100px;
    @media (max-width: 1440px) {
      padding-left: 60px;
    }
  }
`;

S.TBody = styled.div``;

S.TR = styled.div`
  background-color: #ffffff;
  border-bottom: 1px solid #e9e9e9;
  cursor: pointer;

  &:hover {
    background: #f4f4f4;
  }

  &.even {
    background-color: #f7f7f7;
  }
`;

S.TD = styled.div`
  text-align: left;
  font-size: 14px;
  font-family: ${(props) => props.theme.fonts.fontFamilyDefault};
  color: black;
  align-self: center;

  &:first-child {
    padding-left: 100px;
    @media (max-width: 1440px) {
      padding-left: 60px;
    }
  }
`;

S.Resizer = styled.div`
  height: 50px;
  width: 10px;
  position: absolute;
  right: 0;
`;

S.Loader = styled.div`
  position: absolute;
  left: 50%;
  width: 36px;
  transform: translateX(-50%);
  pointer-events: none;
`;

S.LoadingThreeDots = styled(LoadingThreeDots)`
  position: absolute;

  ${(props) => {
    const { numRows, tableHeight, rowHeight } = props;
    const numTableRows = tableHeight / rowHeight;
    if (numRows < numTableRows) {
      return `bottom: ${(numTableRows - numRows) * rowHeight - 40}px;`;
    }
    return 'bottom: 10px;';
  }}
`;
