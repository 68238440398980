import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import BaseComponent from '../../components/shared/BaseComponent';
import ContainerHome from '../../components/shared/Container/ContainerHome';
import TextInput from '../../components/shared/Inputs';
import { ButtonDefault } from '../../components/shared/Buttons';

import {
  showNotificationSuccess,
  showNotificationError,
} from '../../modules/notifications/showNotification';

import { getId } from '../../utils/general';
import { sendPasswordResetRequest } from '../../utils/requests';
import Lock from '../../images/password-reset-page/lock.svg';

export class PasswordResetRequestComponent extends BaseComponent {
  constructor(...args) {
    super(...args);

    this.state = {
      email: '',
    };

    this.emailInputGroupAddonId = getId('password-reset-request-email-input-group-addon');
  }

  handleSubmit(evt) {
    evt.preventDefault();

    sendPasswordResetRequest(this.state.email)
      .then(() => {
        this.props.showNotificationSuccess("We've just sent you an email to reset your password.");
        this.setState({ email: '' });
      })
      .catch((err) => {
        this.props.showNotificationError(
          'There was a problem with your request.<br /> Please try again.',
        );
        console.error(err); // eslint-disable-line no-console
      });
  }

  handleEmailChange(evt) {
    this.setState({ email: evt.target.value });
  }

  render() {
    const { email } = this.state;

    return (
      <ContainerHome>
        <MenuContainer>
          <Title>
            <img src={Lock} alt='lock' />
            <MenuTitle>Forgot Password?</MenuTitle>
          </Title>
          <Seperator />
          <FlavorText>
            Enter your email below and we&apos;ll send you an email with a link to create a new
            password.
          </FlavorText>
          <FormStyling onSubmit={this.handleSubmit} id='forgot-password'>
            <Label>Email</Label>
            <TextInput
              type='email'
              name='email'
              value={email}
              onChange={this.handleEmailChange}
              required
              data-test='email'
            />
            <ButtonLayout>
              <SubmitButton type='submit' name='submit' data-test='request-password-button'>
                Submit
              </SubmitButton>
              <BackButton to='/login'>Back To Login</BackButton>
            </ButtonLayout>
          </FormStyling>
        </MenuContainer>
      </ContainerHome>
    );
  }
}

PasswordResetRequestComponent.displayName = 'PasswordResetRequest'; // fixes jest snapshot tests

PasswordResetRequestComponent.defaultProps = {
  showNotificationSuccess: PropTypes.func.isRequired,
  showNotificationError: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  showNotificationSuccess,
  showNotificationError,
};

export default connect(undefined, mapDispatchToProps)(PasswordResetRequestComponent);

const MenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.colors.white};
  width: 437px;
  box-shadow: 0 8px 10px -5px ${(props) => props.theme.colors.colorShadowHoverDark},
    0 6px 30px 5px ${(props) => props.theme.colors.colorShadowHoverLight},
    0 16px 24px 2px ${(props) => props.theme.colors.colorShadowHoverMedium};
  padding: 20px 40px;
  border-radius: 4px;
`;

const Title = styled.div`
  display: flex;
  height: 50px;
  align-items: center;
  justify-content: center;
`;

const MenuTitle = styled.div`
  font-weight: 600;
  font-size: 18px;
  font-family: ${(props) => props.theme.fonts.fontFamilyDefault};
  margin-left: 15px;
`;

const Seperator = styled.div`
  border-color: ${(props) => props.theme.colors.yellow60};
  border-width: 2px;
  border-style: solid;
`;

const FlavorText = styled.p`
  font-size: 14px;
  font-family: ${(props) => props.theme.fonts.fontFamilyDefault};
  margin: 25px 0;
  color: ${(props) => props.theme.colors.black};
`;

const FormStyling = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 200px;
`;

const Label = styled.div`
  height: 30px;
  margin-bottom: 10px;
  font-size: 14px;
  font-family: ${(props) => props.theme.fonts.fontFamilyDefault};
`;

const ButtonLayout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 160px;
  justify-content: space-around;
`;

const SubmitButton = styled(ButtonDefault)`
  outline: none;
  background-color: ${(props) => props.theme.colors.purple70};
  width: 100%;

  :hover {
    color: ${(props) => props.theme.colors.white};
    background-color: ${(props) => props.theme.colors.purple70};
  }

  :active,
  :focus {
    background-color: ${(props) => props.theme.colors.purple90};
  }
`;

const BackButton = styled(Link)`
  color: #4d317c;
  font-weight: bold;
  font-family: ${(props) => props.theme.fonts.fontFamilyDefault};
`;
