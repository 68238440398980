import { fromJS } from 'immutable';

import * as types from '../actions';
import { SORT_ASC } from '../../components/shared/Tables/InfiniteScrollTable';

export const searchFields = {
  HOSPITAL: 'hospital',
  STATE: 'state',
};

const INITIAL_STATE = fromJS({
  affilsTable: {
    search: {
      field: searchFields.HOSPITAL,
      value: '',
    },
    sort: {
      field: searchFields.HOSPITAL,
      dir: SORT_ASC,
    },
    isLoading: false,
  },
});

function resetAffilsTable(state) {
  return state.set('affilsTable', INITIAL_STATE.get('affilsTable'));
}

function setAffilsTableSearch(state, { field, value }) {
  let newState = state;

  if (field !== undefined) {
    newState = newState.setIn(['affilsTable', 'search', 'field'], field);
  }
  if (value !== undefined) {
    newState = newState.setIn(['affilsTable', 'search', 'value'], value);
  }

  return newState;
}

function setAffilsTableSort(state, { field, dir }) {
  let newState = state;

  if (field !== undefined) {
    newState = newState.setIn(['affilsTable', 'sort', 'field'], field);
  }
  if (dir !== undefined) {
    newState = newState.setIn(['affilsTable', 'sort', 'dir'], dir);
  }

  return newState;
}

function setAffilsTableIsLoading(state, isLoading) {
  return state.setIn(['affilsTable', 'isLoading'], isLoading);
}

export default function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.AUTH_LOGOUT:
      return INITIAL_STATE;
    case types.RESET_AFFILS_TABLE:
      return resetAffilsTable(state);
    case types.SET_AFFILS_TABLE_SEARCH:
      return setAffilsTableSearch(state, action);
    case types.SET_AFFILS_TABLE_SORT:
      return setAffilsTableSort(state, action);
    case types.SET_AFFILS_TABLE_IS_LOADING:
      return setAffilsTableIsLoading(state, action.isLoading);
    default:
      return state;
  }
}
