export const LOGOUT_WARNING_INTERVAL_MS = 55000; // 55 seconds
export const REFRESH_THROTTLE_MS = LOGOUT_WARNING_INTERVAL_MS;

export const SCAN_EXPIRE_MONTHS = 3;

export const REENTER_PASSWORD_MODAL_TIMEOUT_MS = 60 * 1000; // 1 minute
export const MAX_TOKEN_REFRESH_MS = 3 * 60 * 60 * 1000; // 3 hours, set by api

export const IP_WHITELIST_LOGOUT_MS = 5 * 60 * 1000; // 5 minutes

export const MIN_PASSWORD_LENGTH = 8;

export const SCAN_EXPIRE_DAYS = 90;

export const USERTYPE_ADMINISTRATOR = 'administrator';
export const USERTYPE_TECHNICIAN = 'technician';
export const USERTYPE_DOCTOR = 'doctor';
export const USERTYPE_EQUIP_MANAGER = 'equipment_manager';
export const USERTYPE_BILLING_MANAGER = 'billing_manager';
export const USERTYPE_SALES_REP = 'sales_rep';
export const USERTYPE_ASSISTANT = 'assistant';
export const USERTYPE_TESTER = 'tester';

export const USERTYPES = {
  USERTYPE_ADMINISTRATOR,
  USERTYPE_TECHNICIAN,
  USERTYPE_DOCTOR,
  USERTYPE_EQUIP_MANAGER,
  USERTYPE_BILLING_MANAGER,
  USERTYPE_SALES_REP,
  USERTYPE_ASSISTANT,
  USERTYPE_TESTER,
};
