export const ADD_CHILD_TO_OBJECT = 'ADD_CHILD_TO_OBJECT';
export const ADD_FAKE_SPHERE = 'ADD_FAKE_SPHERE';
export const ADD_GROUP = 'ADD_GROUP';
export const ADD_LABEL = 'ADD_LABEL';
export const ADD_MESH = 'ADD_MESH';
export const ADD_OBJECT = 'ADD_OBJECT';
export const ADD_SPHERE = 'ADD_SPHERE';
export const ADD_STENT_SPHERE = 'ADD_STENT_SPHERE';
export const ADD_TO_GROUP = 'ADD_TO_GROUP';
export const CREATE_SPHERE_MAIN_BRONC = 'CREATE_SPHERE_MAIN_BRONC';
export const CREATE_SPHERE_JOIN_BRONC = 'CREATE_SPHERE_JOIN_BRONC';
export const CREATE_STENT_SPHERE_SECONDARY_1 = 'CREATE_STENT_SPHERE_SECONDARY_1';
export const CREATE_STENT_SPHERE_SECONDARY_2 = 'CREATE_STENT_SPHERE_SECONDARY_2';
export const CREATE_STENT_SPHERE = 'CREATE_STENT_SPHERE';
export const CREATE_STENT_SPHERE_WITH_CLICK = 'CREATE_STENT_SPHERE_WITH_CLICK';
export const DELETE_SELECTED_SPHERES = 'DELETE_SELECTED_SPHERES';
export const DELETE_STENT_SPHERES = 'DELETE_STENT_SPHERES';
export const DESELECT_OBJECTS = 'DESELECT_OBJECTS';
export const DRAG_OBJECTS = 'DRAG_OBJECTS';
export const DRAW_STENT = 'DRAW_STENT';
export const FETCH_STENT = 'FETCH_STENT';
export const LABEL_OBJECT = 'LABEL_OBJECT';
export const LOAD_AIRWAY = 'LOAD_AIRWAY';
export const LOAD_AIRWAY_DONE = 'LOAD_AIRWAY_DONE';
export const LOAD_AIRWAY_ERROR = 'LOAD_AIRWAY_ERROR';
export const LOAD_CENTERLINE = 'LOAD_CENTERLINE';
export const LOAD_CENTERLINE_DONE = 'LOAD_CENTERLINE_DONE';
export const LOAD_CENTERLINE_ERROR = 'LOAD_CENTERLINE_ERROR';
export const LOAD_STENT = 'LOAD_STENT';
export const LOAD_STENT_DONE = 'LOAD_STENT_DONE';
export const LOAD_STENT_ERROR = 'LOAD_STENT_ERROR';
export const LOAD_SCENE = 'LOAD_SCENE';
export const LOAD_STENT_SPHERES = 'LOAD_STENT_SPHERES';
export const LOAD_STL = 'LOAD_STL';
export const LOAD_VFF = 'LOAD_VFF';
export const LOCK_SCENE = 'LOCK_SCENE';
export const MOVE_FAKE_STENT_SPHERE = 'MOVE_FAKE_STENT_SPHERE';
export const RECEIVE_STENT_SPHERES = 'RECEIVE_STENT_SPHERES';
export const RECEIVE_STENT_OBJECT = 'RECEIVE_STENT_OBJECT';
export const REQUEST_STENT = 'REQUEST_STENT';
export const REMOVE_ATTRIBUTE = 'REMOVE_ATTRIBUTE';
export const REMOVE_BRANCH = 'REMOVE_BRANCH';
export const REMOVE_CHILDREN_FROM_OBJECT = 'REMOVE_CHILDREN_FROM_OBJECT';
export const REMOVE_GROUPS = 'REMOVE_GROUPS';
export const REMOVE_MESHES = 'REMOVE_MESHES';
export const REMOVE_OBJECTS = 'REMOVE_OBJECTS';
export const REMOVE_SELECTED_OBJECTS = 'REMOVE_SELECTED_OBJECTS';
export const REMOVE_SPHERES = 'REMOVE_SPHERES';
export const REMOVE_STENT = 'REMOVE_STENT';
export const REMOVE_STENT_SPHERES = 'REMOVE_STENT_SPHERES';
export const REMOVE_LABELS = 'REMOVE_LABELS';
export const RESET_OBJECTS_STATE = 'RESET_OBJECTS_STATE';
export const SAVE_DRAGGING_STENT_SPHERES = 'SAVE_DRAGGING_STENT_SPHERES';
export const SAVE_STENT_SPHERE = 'SAVE_STENT_SPHERE';
export const SAVE_STENT_SPHERE_SUCCESS = 'SAVE_STENT_SPHERE_SUCCESS';
export const SCALE_SELECTED_WITH_SCROLL = 'SCALE_SELECTED_WITH_SCROLL';
export const SELECT_INTERSECTED_OBJECTS = 'SELECT_INTERSECTED_OBJECTS';
export const SELECT_INTERSECTED_SPHERES = 'SELECT_INTERSECTED_SPHERES';
export const SELECT_OBJECTS = 'SELECT_OBJECTS';
export const SET_ADD_STENT_SPHERE_BRANCH = 'SET_ADD_STENT_SPHERE_BRANCH';
export const SET_LATEST_OBJECT = 'SET_LATEST_OBJECT';
export const SET_BRANCH_LENGTH = 'SET_BRANCH_LENGTH';
export const SET_MESHES_COLOR = 'SET_MESHES_COLOR';
export const SET_MESHES_LONG_AXIS = 'SET_MESHES_LONG_AXIS';
export const SET_MESHES_OPACITY = 'SET_MESHES_OPACITY';
export const SET_OBJECT_INTERSECTIONS = 'SET_OBJECT_INTERSECTIONS';
export const SET_OBJECTS_ATTRIBUTE = 'SET_OBJECTS_ATTRIBUTE';
export const SET_OBJECTS_DRAGGABLE = 'SET_OBJECTS_DRAGGABLE';
export const SET_OBJECTS_DRAGGING = 'SET_OBJECTS_DRAGGING';
export const SET_OBJECTS_FLASH = 'SET_OBJECTS_FLASH';
export const SET_OBJECTS_INTERSECTABLE = 'SET_OBJECTS_INTERSECTABLE';
export const SET_OBJECTS_LOCKED = 'SET_OBJECTS_LOCKED';
export const SET_OBJECTS_OFFSET = 'SET_OBJECTS_OFFSET';
export const SET_OBJECTS_POSITION = 'SET_OBJECTS_POSITION';
export const SET_OBJECTS_ROTATION = 'SET_OBJECTS_ROTATION';
export const SET_OBJECTS_SCALABLE = 'SET_OBJECTS_SCALABLE';
export const SET_OBJECTS_SCALE = 'SET_OBJECTS_SCALE';
export const SET_OBJECTS_SELECTABLE = 'SET_OBJECTS_SELECTABLE';
export const SET_OBJECTS_SNAPPED = 'SET_OBJECTS_SNAPPED';
export const SET_OBJECTS_VISIBLE = 'SET_OBJECTS_VISIBLE';
export const SET_PROJECTED_CENTERLINE_POINTS = 'SET_PROJECTED_CENTERLINE_POINTS';
export const SET_SPHERE_MIN_MAX_SCALE = 'SET_SPHERE_MIN_MAX_SCALE';
export const SET_STENT_JOIN_SPHERE = 'SET_STENT_JOIN_SPHERE';
export const SET_STENT_SPHERE_BRANCH = 'SET_STENT_SPHERE_BRANCH';
export const SET_STENT_SPHERE_ORDER = 'SET_STENT_SPHERE_ORDER';
export const SET_STENT_THICKNESS = 'SET_STENT_THICKNESS';
export const SET_STENT_THICKNESS_VISIBLE = 'SET_STENT_THICKNESS_VISIBLE';
export const SET_HAS_THICKNESS_BEEN_TURNED_ON = 'SET_HAS_THICKNESS_BEEN_TURNED_ON';
export const SET_STENT_VISIBLE = 'SET_STENT_VISIBLE';
export const SET_STENT_TYPE = 'SET_STENT_TYPE';
export const SET_STENT_ISLOADING = 'SET_STENT_ISLOADING';
export const SNAP_DRAGGING_OBJECTS = 'SNAP_DRAGGING_OBJECTS';
export const SNAP_OBJECTS_TO_CENTERLINE_BRONC = 'SNAP_OBJECTS_TO_CENTERLINE_BRONC';
export const SNAP_OBJECT_TO_CLOSEST_VERTEX = 'SNAP_OBJECT_TO_CLOSEST_VERTEX';
export const UPDATE_STENT_JOIN_SPHERE = 'UPDATE_STENT_JOIN_SPHERE';
export const UPDATE_STENT_SPHERE_ORDER = 'UPDATE_STENT_SPHERE_ORDER';
