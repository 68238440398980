export const API_SCANS_URL = 'api/scans/';

export const getScansByPatientURL = (patientID) => `${API_SCANS_URL}?patient=${patientID}`;

export const getScansReportURL = (startDate, endDate) =>
  `${API_SCANS_URL}report?startDate=${startDate}&endDate=${endDate}`;

export const getScanURL = (scanID) => `${API_SCANS_URL}${scanID}/`;

export const getScanWithSegsURL = (scanID) => `${API_SCANS_URL}${scanID}?withSeg=true`;

export const getScanByPatientInfoURL = (patientID, patientScanNum) =>
  `${API_SCANS_URL}?patientID=${patientID}&patientScanNum=${patientScanNum}`;

export const getScanByScanNumURL = (scanNum) => `${API_SCANS_URL}?scanNum=${scanNum}`;

export const updateScanURL = (scanID) => `${API_SCANS_URL}${scanID}/`;

export const getScanFileURL = (scanID, fileName) => `static/scans/${scanID}/${fileName}/`;
export const downloadScanZipURL = (scanID) => `${API_SCANS_URL}${scanID}/download`;

export const API_DIAGNOSES_URL = 'api/diagnoses';

export const getDiagnosesURL = () => `${API_DIAGNOSES_URL}/`;

export const updateScanDiagnosesURL = (scanID) => `${API_SCANS_URL}${scanID}/diagnoses`;

export const searchScansURL = ({
  scanNum,
  affiliationID,
  doctorName,
  patientName,
  scanStatus = [],
  acqDate = { start: null, end: null }, // ISO 8601 format
  uploadDate = { start: null, end: null }, // ISO 8601 format
  verifRejDate = { start: null, end: null }, // ISO 8601 format
  limit,
  offset,
} = {}) => {
  const queryStrings = [];
  if (scanNum) {
    queryStrings.push(`scanNum=${scanNum}`);
  }
  if (affiliationID) {
    queryStrings.push(`affiliationID=${affiliationID}`);
  }
  if (doctorName) {
    queryStrings.push(`doctorName=${doctorName}`);
  }
  if (patientName) {
    queryStrings.push(`patientName=${patientName}`);
  }
  if (scanStatus?.length) {
    const scanStatusList = [].concat(scanStatus);
    queryStrings.push(`scanStatus=${scanStatusList.join(',')}`);
  }
  if (acqDate?.start) {
    queryStrings.push(`acqDateStart=${acqDate.start}`);
  }
  if (acqDate?.end) {
    queryStrings.push(`acqDateEnd=${acqDate.end}`);
  }
  if (uploadDate?.start) {
    queryStrings.push(`uploadDateStart=${uploadDate.start}`);
  }
  if (uploadDate?.end) {
    queryStrings.push(`uploadDateEnd=${uploadDate.end}`);
  }
  if (verifRejDate?.start) {
    queryStrings.push(`verifiedRejectedDateStart=${verifRejDate.start}`);
  }
  if (verifRejDate?.end) {
    queryStrings.push(`verifiedRejectedDateEnd=${verifRejDate.end}`);
  }
  if (limit) {
    queryStrings.push(`limit=${limit}`);
  }
  if (offset) {
    queryStrings.push(`offset=${offset}`);
  }

  return `${API_SCANS_URL}search?${queryStrings.join('&')}`;
};

export const airwaySTLFilename = (scanID) => `scan_${scanID}.airway.stl`;
export const centerlineJSONFilename = (scanID) => `scan_${scanID}.centerline.json`;
