import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from 'styled-components';
import { StylesProvider, ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import * as Pace from 'pace-progress';
import { renderer } from '@visionair/scene-state-3js';

import Routes from './pages/public/Routes';
import theme from './stylesheets/theme/theme';

import './utils/bundleStats';

Pace.start();

window.renderer = renderer;

ReactDOM.render(
  <StylesProvider injectFirst>
    <MuiThemeProvider theme={theme}>
      <ThemeProvider theme={theme}>
        <Routes />
      </ThemeProvider>
    </MuiThemeProvider>
  </StylesProvider>,
  document.getElementById('app'),
);
