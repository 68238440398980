/* eslint-disable object-shorthand, func-names */

// https://github.com/mui-org/material-ui/blob/next/packages/material-ui/src/styles/createBreakpoints.js

export default {
  sm: 1024,
  lg: 1280,
  up: function (sizeKey) {
    const value = typeof this[sizeKey] === 'number' ? this[sizeKey] : sizeKey;
    return `@media (min-width:${value}px)`;
  },
  down: function (sizeKey) {
    const value = typeof this[sizeKey] === 'number' ? this[sizeKey] : sizeKey;
    return `@media (max-width:${value}px)`;
  },
};
