import * as types from '../actions/auth';
import setLogoutWarningTimeout from './setLogoutWarningTimeout';
import showIPWhitelistWarning from './showIPWhitelistWarning';

import { apiFetch } from '../../utils/requests';
import { API_TOKEN_URL } from '../../settings/api';

export function authLoginAction(token) {
  return {
    type: types.AUTH_LOGIN,
    payload: {
      token,
    },
  };
}

export default function authLogin(email, password) {
  return (dispatch) => {
    const data = {
      email,
      password,
    };

    return apiFetch(API_TOKEN_URL, null, 'POST', data)
      .then(({ token, forcePasswordChange, pwnedPasswordCount }) => {
        localStorage.setItem('token', token);
        dispatch(authLoginAction(token));
        dispatch(setLogoutWarningTimeout(token));
        dispatch(showIPWhitelistWarning(false));

        return {
          token,
          forcePasswordChange,
          pwnedPasswordCount,
        };
      })
      .catch((error) => {
        dispatch(authLoginAction(null));
        throw error;
      });
  };
}
