import React from 'react';
import PropTypes from 'prop-types';
import { ProgressBar as ProgressBarBs } from 'react-bootstrap';

import BaseComponent from '../BaseComponent';

export default class ProgressBar extends BaseComponent {
  componentDidUpdate(prevProps) {
    const prevProgress = this.constructor.aggregateProgress(prevProps.sources);
    const currentProgress = this.constructor.aggregateProgress(this.props.sources);

    if (currentProgress === 100 && prevProgress !== 100) {
      // delay so user sees bar hit 100% for a bit
      setTimeout(() => {
        this.props.onComplete();
      }, 600);
    }
  }

  static aggregateProgress(sources) {
    const numOrZero = (n) => (!n ? 0 : n);

    const loadedAgg = sources
      .map((source) => source.loaded)
      .reduce((a, b) => numOrZero(a) + numOrZero(b), 0);

    const totalAgg = sources
      .map((source) => source.total)
      .reduce((a, b) => numOrZero(a) + numOrZero(b), 0);

    if (loadedAgg === 0 || totalAgg === 0) {
      return 0;
    }

    const percentAgg = (loadedAgg / totalAgg) * 100;

    // round down since 100% triggers onComplete() callback
    // e.g. 99.6 shouldn't round to 100, since loading is technically not fully complete
    return Math.floor(percentAgg);
  }

  render() {
    const { sources, onComplete, ...rest } = this.props;

    const percentLoaded = this.constructor.aggregateProgress(sources);

    return <ProgressBarBs now={percentLoaded} {...rest} />;
  }
}

ProgressBar.propTypes = {
  // aggregate multiple progress sources
  sources: PropTypes.arrayOf(
    PropTypes.shape({
      loaded: PropTypes.number,
      total: PropTypes.number,
    }),
  ).isRequired,
  onComplete: PropTypes.func,
};

ProgressBar.defaultProps = {
  onComplete: () => {},
};
