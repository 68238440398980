import { fromJS } from 'immutable';
import * as types from '../actions';

export const INITIAL_STATE = fromJS({});

export default function progress(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.RESET_PROGRESS:
      return resetProgress(state, action);
    case types.SET_PROGRESS:
      return setProgress(state, action);
    default:
      return state;
  }
}

function resetProgress(state, { progressKey }) {
  if (progressKey) {
    return state.delete(progressKey);
  }

  // reset all progress if progressKey is undefined
  return INITIAL_STATE;
}

function setProgress(state, { progressKey, loaded, total }) {
  return state.setIn([progressKey, 'loaded'], loaded).setIn([progressKey, 'total'], total);
}
