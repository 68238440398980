import * as types from '../actions/notifications';

export default function setBannerIsOpen(isOpen) {
  localStorage.setItem('bannerIsOpen', isOpen);

  return {
    type: types.SET_BANNER_IS_OPEN,
    isOpen,
  };
}
