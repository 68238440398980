import { fromJS } from 'immutable';

import * as types from '../actions';
import { SORT_ASC } from '../../components/shared/Tables/InfiniteScrollTable';

export const searchFields = {
  NAME: 'name',
  EMAIL: 'email',
  USERTYPE: 'usertype',
};

const INITIAL_STATE = fromJS({
  usersTable: {
    search: {
      field: searchFields.NAME,
      value: '',
    },
    sort: {
      field: 'lastName',
      dir: SORT_ASC,
    },
    isLoading: false,
  },
});

function resetUsersTable(state) {
  return state.set('usersTable', INITIAL_STATE.get('usersTable'));
}

function setUsersTableSearch(state, { field, value }) {
  let newState = state;

  if (field !== undefined) {
    newState = newState.setIn(['usersTable', 'search', 'field'], field);
  }
  if (value !== undefined) {
    newState = newState.setIn(['usersTable', 'search', 'value'], value);
  }

  return newState;
}

function setUsersTableSort(state, { field, dir }) {
  let newState = state;

  if (field !== undefined) {
    newState = newState.setIn(['usersTable', 'sort', 'field'], field);
  }
  if (dir !== undefined) {
    newState = newState.setIn(['usersTable', 'sort', 'dir'], dir);
  }

  return newState;
}

function setUsersTableIsLoading(state, isLoading) {
  return state.setIn(['usersTable', 'isLoading'], isLoading);
}

export default function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.AUTH_LOGOUT:
      return INITIAL_STATE;
    case types.RESET_USERS_TABLE:
      return resetUsersTable(state);
    case types.SET_USERS_TABLE_IS_LOADING:
      return setUsersTableIsLoading(state, action.isLoading);
    case types.SET_USERS_TABLE_SEARCH:
      return setUsersTableSearch(state, action);
    case types.SET_USERS_TABLE_SORT:
      return setUsersTableSort(state, action);
    default:
      return state;
  }
}
