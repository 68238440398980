export const API_SHIPPING_ADDRESS_URL = 'api/shipping-addresses';

export const createShippingAddressURL = () => `${API_SHIPPING_ADDRESS_URL}/`;

export const getShippingAddressesByUserURL = (userID) =>
  `${API_SHIPPING_ADDRESS_URL}/?userID=${userID}`;

export const getShippingAddressURL = (shippingAddressId) =>
  `${API_SHIPPING_ADDRESS_URL}/${shippingAddressId}/`;

export const updateShippingAddressURL = (shippingAddressId) =>
  `${API_SHIPPING_ADDRESS_URL}/${shippingAddressId}/`;

export const deleteShippingAddressURL = (shippingAddressId) =>
  `${API_SHIPPING_ADDRESS_URL}/${shippingAddressId}/`;
