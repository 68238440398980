import React, { useState } from 'react';
import styled from 'styled-components';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

import Checkbox from '../shared/Inputs/Checkbox';

import vaLogo from '../../images/va-logo-black-text.png';
import visionairSolutions from '../../images/visionair-solutions-teal.jpg';

export default function TermsAndConditionsModal({ open, onClose, onApprove }) {
  const [isChecked, setIsChecked] = useState(false);

  return (
    <Modal
      open={open}
      maxWidth={false}
      scroll='body'
      disableBackdropClick
      data-test='terms-and-conditions-modal'
    >
      <Header>
        <img src={vaLogo} style={{ height: '31px' }} alt='VA' />
        <div style={{ fontSize: '20px', fontWeight: 'bold' }}>Terms and Conditions</div>
        <img src={visionairSolutions} style={{ height: '31px' }} alt='Visionair Solutions' />
      </Header>
      <DialogContent>
        <Terms>
          <h4>END USER LICENSE AGREEMENT</h4>
          <p>
            This is a legal agreement (“Agreement”) between the end-user customer, referred to
            herein after as “you”, and New COS, Inc. d/b/a VisionAir Solutions, referred to herein
            after as “VisionAir”, for the stent design software (“Licensed Software”). BY ACCESSING
            AND/OR USING THE LICENSED SOFTWARE, YOU AGREE TO BE BOUND BY THE TERMS OF THIS
            AGREEMENT. IF YOU DO NOT AGREE TO THE TERMS OF THIS AGREEMENT, DO NOT ACCESS AND/OR USE
            THE LICENSED SOFTWARE. Nothing contained in any purchase order or any other document
            submitted by you shall in any way modify or add to the terms and conditions contained in
            this Agreement.
          </p>
          <p>
            <strong>1. Software License.</strong> VisionAir hereby grants you a limited,
            non-exclusive, nontransferable, non- sublicensable, revocable license to access and use
            the Licensed Software for internal business purposes only. You acknowledge that you are
            solely responsible for providing accurate, complete, and current CT scans, data, files,
            and other information (collectively, “Data”) for designing a patient specific stent and
            that VisionAir shall have no liability whatsoever arising or resulting from your failure
            to provide such accurate, complete, and current Data. You represent and warrant that (i)
            you have the authority and all consents, authorizations, licenses, rights, or other
            agreements necessary to use the Data with the Licensed Software and to grant the rights
            to VisionAir set forth herein; and (ii) you are a licensed physician.
          </p>
          <p>
            <strong>2. Restrictions.</strong> You shall not, directly or indirectly, nor shall you
            permit anyone else to: (i) modify, disassemble, decompile, reverse engineer, or create
            any derivative works based upon the Licensed Software, including any customizations, any
            modifications or enhancements, translation or localization; (ii) remove, obscure,
            modify, or otherwise change any product identification, trademark, copyright, or other
            notice or legends contained in or on, or available through the Licensed Software; (iii)
            transfer the Licensed Software or license, whether in whole or in part, or grant any
            rights in the Licensed Software; (iv) license, sublicense, sell, resell, lend transfer,
            assign, distribute or otherwise commercially exploit or allow others to use or have
            access to the Licensed Software or any portion thereof; (v) use the Licensed Software to
            build a competitive product; (vi) use the Licensed Software in any service bureau or
            time-sharing arrangement, outsourcing service, or otherwise use the Licensed Software
            for the benefit of third parties; (vii) use the Licensed Software for purposes other
            than designing a stent; and/or (viii) use the Licensed Software as a visualization tool
            or for training purposes.
          </p>
          <p>
            <strong>3. Your Responsibilities.</strong> You shall be responsible for compliance with
            this Agreement and shall be responsible for all Data you provide to, process on, or
            store in the Licensed Software and all stents you design through use of the Licensed
            Software. You shall not (i) use the Licensed Software to store or transmit infringing,
            libelous, or otherwise unlawful or tortious material, or to store, process, or transmit
            material in violation of any third party rights (including, but not limited to, privacy
            rights); (ii) use the Licensed Software to store or transmit any software that
            maliciously or without VisionAir’s authorization destroys, damages, disables, or gains
            unauthorized access to any computer system, software, or electronic data or otherwise
            maliciously affect the confidentiality, integrity, or availability of any systems or
            data; (iii) interfere with or disrupt the integrity or performance of the Licensed
            Software; and/or (iv) use the Licensed Software in any way that could violate any local,
            state, or federal rules, laws, regulations, ordinances, statutes, or codes. You agree to
            ensure that your systems, applications, hardware, software, network, and devices meet
            the applicable requirements of the Health Insurance Portability and Accountability Act
            of 1996 (“HIPAA”), and American Recovery and Reinvestment Act of 2009 and associated
            Health Information Technology for Economic and Clinical Health Act (“HITECH”) and
            related regulations, as they may be amended from time to time, and other applicable data
            security laws and regulations.
          </p>
          <p>
            <strong>4. Username and Password.</strong> You are solely responsible for maintaining
            the confidentiality of each username and password used with your account. You are solely
            responsible for any and all activities that occur under your account. You may not share
            your username and password with anyone else or allow anyone else to access and use your
            account. You agree to notify VisionAir immediately of any unauthorized access or use of
            your account or any other breach in security. VisionAir shall not be liable for any loss
            that you may incur as a result of someone else using your passwords or account, either
            with or without your knowledge.
          </p>
          <p>
            <strong>5. Intellectual Property Ownership.</strong> All intellectual property rights,
            including without limitation, all trade secrets, copyrights, trademarks, and patent
            rights in and to the Licensed Software remain the sole property of VisionAir and its
            licensors. No title or license right is granted to you except as expressly set forth in
            this Agreement. You grant VisionAir a license and right to use the Data you upload to
            the Licensed Software for purposes of carrying out its obligations and exercising its
            rights in connection with this Agreement, for the proper management administration of
            its business, and as permissible or required under applicable law. You acknowledge and
            agree that VisionAir may aggregate and use the Data received from or inputted by you
            into or stored on the Licensed Software for any legal purpose including, but not limited
            to, product research and development. If any of the data or information you provide
            includes protected health information, you acknowledge and agree that VisionAir may
            create “De-identified Data” containing no individual patient identifiers constituting
            protected health information, which “De-identified Data” shall have the meaning ascribed
            to it in the HIPAA Privacy Rule at 45 CFR 164.514(a). You acknowledge and agree further
            that VisionAir may use, disclose, license, and otherwise dispose of such De-identified
            Data for any and all lawful purposes including, but not limited to, product research and
            development.
          </p>
          <p>
            <strong>6. Confidentiality.</strong> VisionAir considers the Licensed Software to be
            proprietary, and you agree to treat the Licensed Software as confidential material in a
            manner no less protective then you use to protect your own similar assets, but in no
            event will you use less than reasonable care to protect the Licensed Software. You agree
            not to permit any third party access to or use of the Licensed Software without
            VisionAir’s advance written notice.
          </p>
          <p>
            <strong>7. Disclaimer of Warranties.</strong> THE LICENSED SOFTWARE IS PROVIDED ON AN
            “AS IS” AND “AS AVAILABLE” BASIS WITHOUT ANY WARRANTY OF ANY KIND, EXPRESS OR IMPLIED.
            USE OF THE LICENSED SOFTWARE IS AT YOUR SOLE RISK. TO THE FULLEST EXTENT PERMISSIBLE
            PURSUANT TO APPLICABLE LAW, VISIONAIR AND ITS LICENSORS DISCLAIM ALL WARRANTIES, EXPRESS
            OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY AND
            FITNESS FOR A PARTICULAR PURPOSE, TITLE, AND NONINFRINGEMENT. VISIONAIR AND ITS
            LICENSORS DO NOT WARRANT OR MAKE ANY REPRESENTATIONS REGARDING THE USE OR THE RESULTS OF
            THE USE OF THE LICENSED SOFTWARE IN TERMS OF THEIR CORRECTNESS, ACCURACY, RELIABILITY,
            OR OTHERWISE. YOU ASSUME ALL RESPONSIBILITIES AND OBLIGATIONS WITH RESPECT TO ANY
            DECISIONS OR ADVICE MADE OR GIVEN AS A RESULT OF THE USE OR APPLICATION OF THE LICENSED
            SOFTWARE, INCLUDING THOSE TO ANY THIRD PARTY.
          </p>
          <p>
            <strong>8. Indemnification.</strong> You agree to defend, indemnify, reimburse, and hold
            VisionAir, its affiliates, its licensors and any of their respective offices, directors,
            employees, subcontractors, agents, successors, assigns, affiliates, or subsidiaries,
            from and against any and all claims, causes of action, lawsuits, proceedings, losses,
            damages, costs and expenses (including reasonable legal and accounting fees) arising
            from or related to (i) your violation of this Agreement or any other applicable terms,
            conditions, representations, or warranties; (ii) your unauthorized use or misuse of the
            Licensed Software; (iii) any incomplete or inaccurate Data provided by you; (iv) any
            designs made by you through your use of the Licensed Software; and/or (v) your acts or
            omissions. VisionAir reserves the right, at its sole discretion, to assume or
            participate, at your and your organization’s expense, in the investigation, settlement,
            and defense of any action or claim to which it is entitled to indemnification. No claim
            shall be settled without VisionAir’s prior written consent unless such settlement
            includes a complete release of VisionAir from all liability and does not contain or
            contemplate any payment by, or injunctive or other equitable relief binding upon,
            VisionAir.
          </p>
          <p>
            <strong>9. Limitation of Liability.</strong> UNDER NO CIRCUMSTANCES SHALL VISIONAIR OR
            ITS AFFILIATES OR LICENSORS BE LIABLE FOR ANY INDIRECT, INCIDENTAL, CONSEQUENTIAL,
            PUNITIVE, OR SPECIAL DAMAGES, INCLUDING ANY LOST PROFIT OR LOST SAVINGS, WHETHER BASED
            IN CONTRACT, TORT (INCLUDING BUT NOT LIMITED TO, NEGLIGENCE) OR ANY OTHER LEGAL THEORY
            AND WHETHER OR NOT SUCH PARTY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, OR
            FOR ANY CLAIM BY ANY THIRD PARTY. VISIONAIR’S AGGREGATE LIABILITY ARISING OUT OF THIS
            AGREEMENT OR YOUR USE OR INABILITY TO USE THE LICENSED SOFTWARE WILL NOT EXCEED THE
            LESSER OF ONE THOUSAND DOLLARS ($1,000) OR THE AMOUNT ACTUALLY PAID BY YOU TO VISIONAIR
            FOR THE LICENSED SOFTWARE GIVING RISE TO SUCH LIABILITY.
          </p>
          <p>
            <strong>10. Export Restrictions.</strong> You agree that the Licensed Software will not
            be shipped, transferred, or exported into any country or used in any manner prohibited
            by the United States Export Administration Act or any other export laws, restrictions or
            regulations (collectively the “Export Laws”). In addition, if the Licensed Software is
            identified as an export controlled item under the Export Laws, you represent and warrant
            that you are not a citizen of, or located within, an embargoed or otherwise restricted
            nation (including without limitation Iran, Syria, Sudan, Libya, Cuba and North Korea)
            and that you are not otherwise prohibited under the Export Laws from receiving the
            Licensed Software. All rights to use the Licensed Software are granted on condition that
            such rights are forfeited if you fail to comply with the terms of this Agreement.
          </p>
          <p>
            <strong>11. Compliance with Law.</strong> You agree that by entering into this Agreement
            you agree to comply with all applicable local, federal and state laws, rules,
            regulations, statutes, and codes in your use of the Licensed Software.
          </p>
          <p>
            <strong>12. HIPAA.</strong> If you are subject to HIPAA and use the Licensed Software in
            manner that causes VisionAir to create, receive, maintain, or transmit protected health
            information (as that term is defined in HIPAA) on your behalf, then you agree to the
            HIPAA Business Associate Agreement below.
          </p>
          <p>
            <strong>13. Termination and Survival.</strong> This Agreement is effective until
            terminated. This Agreement will terminate immediately without notice from VisionAir if
            in VisionAir’s sole discretion you fail to comply with any term or provision of this
            Agreement. VisionAir may also suspend or terminate your access to or use of the Licensed
            Software at any time and for any reason. Upon termination, you must cease use of the
            Licensed Software. The Sections that by their nature are intended to survive shall
            survive and continue in effect to the extent necessary to protect the rights of the
            parties, including but not limited to Sections 5, 6, 7, 8, 9, 13, 15, 16, 18, and 19.
          </p>
          <p>
            <strong>14. Government Restricted Rights.</strong> The Licensed Software is provided
            with “RESTRICTED RIGHTS”. Use, duplication, or disclosure by the government is subject
            to restrictions as set forth in applicable laws and regulations and in this Agreement.
            Use of any Licensed Software by the government constitutes acknowledgment of VisionAir’s
            or other owner’s proprietary rights in them.
          </p>
          <p>
            <strong>15. Choice of Law and Venue.</strong> The parties agree that this Agreement
            shall be governed by and construed in accordance with the laws of the State of Ohio
            without regard to its conflict of laws provisions. The parties agree further that all
            actions brought under or in connection with this Agreement shall be brought in a court
            or competent jurisdiction located in Cuyahoga County, Ohio. The application of the model
            Uniform Computer Information Transactions Act approved by the National Conference of
            Commissioners on Uniform State Laws (as enacted and/or modified into any state law in
            the U.S.A.), is expressly excluded and shall not apply.
          </p>
          <p>
            <strong>16. Feedback.</strong> In the event you provide VisionAir with feedback, ideas,
            suggests, or other input regarding the Licensed Software (“Feedback”), you hereby assign
            to VisionAir all rights in the Feedback and agree that VisionAir shall have the right to
            use the Feedback and related information in any manner it deems appropriate without any
            compensation to you.
          </p>
          <p>
            <strong>17. Assignment.</strong> This Agreement, and your rights and obligations herein,
            may not be assigned, subcontracted, delegated, or otherwise transferred by you without
            VisionAir’s prior written consent, and any attempted assignment, subcontract, or
            transfer in violation of the foregoing will be null and void. The terms of this
            Agreement shall be binding upon assignees.
          </p>
          <p>
            <strong>18. Waiver &amp; Severability.</strong> Any waiver or failure to enforce any
            provision of this Agreement on one occasion will not be deemed a waiver of any other
            provision or of such provision on any other occasion. If any provision of this Agreement
            is found to be invalid or unenforceable, such provision will be changed and interpreted
            to accomplish the objectives to the greatest extent possible under any applicable law
            and the remaining provisions will continue in full force and effect.
          </p>
          <p>
            <strong>19. Complete Agreement.</strong> This Agreement, including the HIPAA Business
            Associate Agreement, if applicable, is the final, complete and exclusive agreement
            between the parties with respect to the subject matter hereof and supersedes and merges
            all prior discussions between the parties. No modification or amendment to this
            Agreement, nor any waiver of any rights under this Agreement, will be effective unless
            in writing and signed by the party to be charged (which in the case of VisionAir shall
            require the signature of a duly authorized officer of VisionAir). You agree that you
            shall not bring any action against VisionAir or its licensors related to, based upon, or
            arising out of this Agreement unless such action is commenced within twelve (12) months
            of the accrual of the cause of action or your knowledge that such cause of action exists
            (whichever occurs later).
          </p>
          <h4>HIPAA BUSINESS ASSOCIATE AGREEMENT</h4>
          <p>
            By agreeing to the End User License Agreement (“EULA”) for the Licensed Product, you (as
            the “Covered Entity”) are allowing VisionAir (the “Business Associate”) to process,
            store, and/or use your PHI (as defined below) under the terms and conditions of this
            HIPAA Business Associate Agreement (the “BAA”).
          </p>
          <p>
            The parties are obligated to comply with the Standards for Privacy of Individually
            Identifiable Health Information (the “Privacy Rule”) issued under the Health Insurance
            Portability and Accountability Act of 1996 (“HIPAA”); the American Recovery and
            Reinvestment Act of 2009 and associated Health Information Technology for Economic and
            Clinical Health Act (“HITECH”); and the Final Omnibus Rule of 2013, collectively
            referred to herein as “HIPAA Regulations”. Statutory and regulatory references herein
            are to the aforementioned laws as currently in effect or as subsequently updated,
            amended, or revised upon the date of such update, amendment or revision. For the
            purposes of this BAA, all terms shall have the meaning set forth under the HIPAA
            Regulations, except as expressly provided herein.
          </p>
          <p>
            The Covered Entity has entered into the EULA with the Business Associate pursuant to
            which the Business Associate may use and/or disclose the Covered Entity’s patient’s
            individually identifiable health information defined as Protected Health Information at
            45 C.F.R. § 160.103 (“PHI”, which for the terms of this Addendum collectively include
            electronic PHI or “ePHI”). The Business Associate may use and/or disclose Covered
            Entity’s PHI in order to provide the Covered Entity a hosted software solution for
            designing patient stents.
          </p>
          <p>
            This BAA supplements, modifies and amends any and all agreements, whether written or
            otherwise between the parties. The provisions of this BAA supersede any conflicting or
            inconsistent terms of any agreement, including any exhibits and attachments thereto.
          </p>
          <p>
            This BAA sets forth the terms and conditions pursuant to which PHI that is provided,
            created, or received by the Business Associate from or on behalf of the Covered Entity
            will be handled between the Business Associate and the Covered Entity and with third
            parties. The parties agree as follows:
          </p>
          <p>
            <strong>1. Permitted Uses and Disclosures.</strong> The Business Associate may use and
            disclose PHI necessary to perform its obligations to the Covered Entity except as
            otherwise specified or restricted herein. The Business Associate may also (a) use PHI if
            necessary for its proper management and administration and to carry out its legal
            responsibilities; (b) disclose PHI to third parties for the same purposes so long as (i)
            the disclosure is required or permitted by applicable law or (ii) the Business Associate
            obtains reasonable assurances from said third party that the PHI will be held
            confidentially and used or further disclosed only as required by law or for the purpose
            for which it was disclosed and that the third party will notify the Business Associate
            of any instances of which it is aware in which the confidentiality of the PHI has been
            breached; and (c) use and disclose PHI to de-identify the PHI in accordance with 45
            C.F.R. § 164.514(a)–(c). All other uses and disclosures not authorized by this BAA or
            the EULA are prohibited.
          </p>
          <p>
            <strong>2. Obligations of the Business Associate.</strong> The Business Associate will:
            (a) not use or further disclose PHI other than as permitted or required herein, in any
            written agreement, or as required by applicable law. (b) use appropriate safeguards to
            prevent uses or disclosures of PHI other than as provided for herein or as required by
            applicable law. (c) report to the Covered Entity any use or disclosure of PHI not
            provided for herein or by any written agreement of which it becomes aware. (d) ensure
            that any agents, including a subcontractor, to whom the Business Associate provides PHI
            on behalf of the Covered Entity agree to the same restrictions and conditions that apply
            to the Business Associate with respect to the PHI. (e) make uses, disclosures, and
            requests for PHI consistent with the minimum necessary standard pursuant to 45 C.F.R.
            §164.502(b). (f) within 20 days of receiving a written request from the Covered Entity
            for a copy of PHI, if Business Associate maintains a Designated Record Set, as defined
            in 45 C.F.R. §164.501, make the requested PHI available to the Covered Entity to enable
            the Covered Entity to respond to an individual who seeks to inspect or copy PHI. (g)
            within 45 days of receiving a written request from the Covered Entity to make PHI
            available to amend PHI, if Business Associate maintains a Designated Record Set, as
            defined in 45 C.F.R. §164.501, make the requested PHI available to the Covered Entity
            for amendment and incorporate any amendments to PHI directed by the Covered Entity. (h)
            within 45 days of receiving a written request from the Covered Entity for an accounting
            of disclosures of PHI about an individual, provide to the Covered Entity a listing of
            the persons or entities to which the Business Associate has disclosed PHI about the
            individual within the prior 6 years (excluding disclosures for reasons of treatment,
            payment, and health care operations as defined in the Privacy Rule) along with the dates
            of, reasons for, and brief descriptions of the disclosures to enable the Covered Entity
            to respond to an individual seeking an accounting of the disclosures of the
            individual&#39;s PHI. (i) make its internal practices, books, and records relating to
            the use and disclosure of PHI received from, created by, or received by the Business
            Associate on behalf of the Covered Entity available to the U.S. Department of Health and
            Human Services so that it may evaluate the Covered Entity’s compliance with the Privacy
            Rule. (j) at the termination of any agreement, or of the uses and/or disclosures of the
            PHI by the Business Associate, if feasible, return or destroy all PHI received from,
            created by, or received by the Business Associate on behalf of the Covered Entity that
            the Business Associate still maintains in any form in connection with this BAA and
            retain no copies of such information or, if such return or destruction is not feasible,
            extend the protections of this BAA to the PHI and limit further uses and disclosures to
            those purposes that make the return or destruction of the PHI infeasible.
          </p>
          <p>
            <strong>3. Obligations of the Business Associate with respect to ePHI.</strong> Pursuant
            to 45 C.F.R. § 164.314(a)(2)(i), Business Associate will: (a) implement administrative,
            physical, and technical safeguards that reasonably and appropriately protect the
            confidentiality, integrity and availability of the ePHI that it creates, receives,
            maintains, or transmits on behalf of Covered Entity. (b) ensure that any agent,
            including a subcontractor, to whom it provides ePHI agrees to implement reasonable and
            appropriate safeguards to protect it. (c) report to the Covered Entity any Security
            Incident of which it becomes aware, as that term is defined under 45 C.F.R. § 164.304.
          </p>
          <p>
            <strong>4. Effective Date, Survival, and Termination.</strong> This BAA shall become
            effective as of the effective date of the EULA and shall survive the termination or
            expiration of any agreement and/or the uses and/or disclosures of the PHI by the
            Business Associate. Notwithstanding the foregoing and notwithstanding any other
            provision of any agreement, the Covered Entity may terminate this BAA and any related
            agreements if the Business Associate has breached a material term of this BAA and such
            breach is not cured within thirty (30) days.
          </p>
          <p>
            <strong>5. Notices and Reporting.</strong> Any notices or reporting to be given
            hereunder to a party shall be made via U.S. Mail or express courier to Business
            Associate’s address given below, and/or (other than for the delivery of fees) via
            facsimile to the facsimile telephone numbers listed below and, as to Covered Entity, to
            the address provided to or requested by Business Associate.
          </p>
          <p>
            If to the Business Associate, to: New COS, Inc. d/b/a VisionAir Solutions 7100 Euclid
            Ave #180, Cleveland, OH 44103 Attn: Keith Grafmeyer Email:
            kgrafmeyer@customorthopaedics.com With a copy to: The Cleveland Clinic Foundation Law
            Department 3050 Science Park Drive - Mail Code AC321 Beachwood, OH 44122 Attention:
            Chief Legal Officer, CC Innovations Email: cicarej@ccf.org With a copy to:
            legalcontracts@ccf.org
          </p>
          <p>
            <strong>6. Compliance Related Changes.</strong> The parties recognize that the rules,
            laws and regulations may change or may be clarified, such laws, include without
            limitation, HITECH, HIPAA, and the Final Omnibus Rule of 2013, and that terms of this
            BAA may need to be revised, on advice of counsel, in order to remain in compliance with
            such changes or clarifications, and the parties agree that Business Associate may modify
            or amend this BAA as required to bring the entire BAA into compliance.
          </p>
          <p>
            <strong>7. Covered Entity Obligations.</strong> Covered Entity: (i) shall notify
            Business Associate of any limitations in the notice of privacy practices of Covered
            Entity under 45 CFR 164.520, to the extent such limitation may affect Business
            Associate’s use or disclosure of PHI; (ii) shall not cause Business Associate to use or
            disclose PHI in any manner that would not be permissible under HIPAA if done directly by
            Covered Entity; (iii) represents and warrants that, to the extent Covered Entity
            provides PHI to Business Associate, such PHI is the minimum necessary PHI for the
            accomplishment of Business Associate’s purpose; (iv) represents and warrants that
            Covered Entity has obtained the consents, authorizations, and/or other forms of legal
            permission required under HIPAA and other applicable laws; (v) notify Business Associate
            of any changes in, or revocation of, the permission by an individual to use or disclose
            his or her PHI, to the extent such change may affect Business Associate’s use or
            disclosure of PHI; and (vi) notify Business Associate of any restriction on the use or
            disclosure of PHI that Covered Entity has agree to or is required to abide by under 45
            CFR 164.522, to the extent such restriction may affect Business Associate’s use or
            disclosure of PHI.
          </p>
        </Terms>
        <Footer>
          <CheckboxContainer onClick={() => setIsChecked(!isChecked)}>
            <Check checked={isChecked} />I have read and agree to the terms and conditions.
          </CheckboxContainer>
          <ProceedBtn onClick={onApprove} disabled={!isChecked}>
            Proceed
          </ProceedBtn>
          <CancelBtn onClick={onClose}>Cancel</CancelBtn>
        </Footer>
      </DialogContent>
    </Modal>
  );
}

const Modal = styled(Dialog)`
  .MuiDialogContent-root {
    max-width: 1190px;
  }

  .MuiDialog-paperScrollPaper {
    max-height: unset;
  }

  .MuiPaper-root {
    max-height: unset;
  }
`;

const Header = styled.div`
  height: 125px;
  background: white;
  padding: 0 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Terms = styled.div`
  background: #f5f5f5;
  font-size: 12px;
  font-weight: 500;
  padding: 80px 80px 0 80px;
  height: 450px;
  overflow: auto;
  line-height: 1.25;
`;

const Footer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const CheckboxContainer = styled.div`
  height: 80px;
  width: 475px;
  display: flex;
  align-items: center;

  font-weight: 600;
  color: #4d4d4d;
  text-align: left;
  cursor: pointer;

  border: none;
  border-radius: 4px;
  outline: none;
  background: none;
`;

const ProceedBtn = styled.button`
  width: 475px;
  color: white;
  font-size: 16px;
  font-weight: 600;
  background: #6624b4;
  border-radius: 6px;
  border: none;
  height: 40px;

  ${(props) => props.disabled && 'opacity: 0.5;'}
`;

const CancelBtn = styled.button`
  width: 475px;
  height: 40px;
  background: none;
  border: none;
  font-size: 16px;
  color: #6e6e6e;
  font-weight: 600;
  margin: 20px 0;
`;

const Check = styled(Checkbox)`
  border: 1px solid ${(props) => props.theme.colors.grey40};
  border-radius: 4px;
  margin-right: 20px;
`;
