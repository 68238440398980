import { fromJS, Map, OrderedMap } from 'immutable';

import * as types from '../actions';
import { SORT_DESC } from '../../components/shared/Tables/InfiniteScrollTable';

export const searchFields = {
  ORDER_NUMBER: 'orderNumber', // value should match URL query string param
  ORDER_STATUS: 'orderStatus',
  ORDER_DATE: 'orderDate',
  PATIENT_NAME: 'patientName',
  PATIENT_MRN: 'patientMRN',
  SCAN_NUM: 'scanNum',
  DOC_NAME: 'doctorName',
};

export const INITIAL_STATE = Map({
  byId: OrderedMap(),
  ordersTable: Map({
    search: Map({
      field: searchFields.ORDER_NUMBER,
      value: '',
    }),
    sort: Map({
      field: 'orderedAt',
      dir: SORT_DESC,
    }),
    isLoading: false,
    selectedAffilID: null,
  }),
});

function clearOrders(state) {
  return state.set('byId', OrderedMap());
}

function addOrder(state, order) {
  return state.setIn(['byId', order.id], fromJS(order));
}

export function addOrders(state, orders) {
  return state.withMutations((mutState) => {
    orders.forEach((order) => {
      mutState.setIn(['byId', order.id], fromJS(order));
    });
  });
}

function setOrdersTableSearch(state, { field, value }) {
  let newState = state;

  if (field !== undefined) {
    newState = newState.setIn(['ordersTable', 'search', 'field'], field);
  }
  if (value !== undefined) {
    newState = newState.setIn(['ordersTable', 'search', 'value'], value);
  }

  return newState;
}

function setOrdersTableSort(state, { field, dir }) {
  let newState = state;

  if (field !== undefined) {
    newState = newState.setIn(['ordersTable', 'sort', 'field'], field);
  }
  if (dir !== undefined) {
    newState = newState.setIn(['ordersTable', 'sort', 'dir'], dir);
  }

  return newState;
}

function setOrdersTableIsLoading(state, isLoading) {
  return state.setIn(['ordersTable', 'isLoading'], isLoading);
}

function resetOrdersTable(state) {
  return state.set('ordersTable', INITIAL_STATE.get('ordersTable'));
}

function setOrdersTableSelectedAffilID(state, affilID) {
  return state.setIn(['ordersTable', 'selectedAffilID'], affilID);
}

export default function ordersReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.AUTH_LOGOUT:
      return INITIAL_STATE;
    case types.CLEAR_ORDERS:
      return clearOrders(state);
    case types.ADD_ORDERS:
      return addOrders(state, action.orders);
    case types.ADD_ORDER:
      return addOrder(state, action.order);
    case types.RESET_ORDERS_TABLE:
      return resetOrdersTable(state);
    case types.SET_ORDERS_TABLE_SEARCH:
      return setOrdersTableSearch(state, action);
    case types.SET_ORDERS_TABLE_SORT:
      return setOrdersTableSort(state, action);
    case types.SET_ORDERS_TABLE_IS_LOADING:
      return setOrdersTableIsLoading(state, action.isLoading);
    case types.SET_ORDERS_TABLE_SELECTED_AFFIL:
      return setOrdersTableSelectedAffilID(state, action.affilID);
    default:
      return state;
  }
}
