import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import BaseComponent from '../../components/shared/BaseComponent';
import Login from '../../components/homePage/Login';
import Register from '../../components/homePage/Register';
import ContainerHome from '../../components/shared/Container/ContainerHome';

import authLogout from '../../modules/auth/authLogout';
import { getToken, getUserType } from '../../modules/auth/selectors';
import fetchBanner from '../../modules/notifications/fetchBanner';
import { USERTYPES } from '../../settings/broncUsers';

const LOGIN = 'login';
const REGISTER = 'register';

export class HomePageComponent extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: props.initialActiveTab,
      showRegisterSuccess: false,
    };
  }

  componentDidMount() {
    if (this.props.isLoggedIn && this.props.shouldLogout) {
      this.props.authLogout(this.props.history);
    }

    if (this.props.isLoggedIn && !this.props.shouldLogout) {
      let from = { pathname: '/patients' };
      if (
        this.props.usertype === USERTYPES.USERTYPE_BILLING_MANAGER ||
        this.props.usertype === USERTYPES.USERTYPE_EQUIP_MANAGER
      ) {
        from.pathname = '/orders';
      }

      if (this.props.location.state?.from) {
        ({ from } = this.props.location.state);
      }

      this.props.history.push(from);
    }

    this.props.fetchBanner();
  }

  handleTabClick({ target: { name } }) {
    this.setState({ activeTab: name });
  }

  handleRegisterSuccess() {
    this.setState({ activeTab: LOGIN, showRegisterSuccess: true });
  }

  render() {
    return (
      <ContainerHome>
        <Tabs>
          <TabsHeader>
            <TabButton
              name={LOGIN}
              active={this.state.activeTab === LOGIN}
              onClick={this.handleTabClick}
              data-test='login-tab'
            >
              Login
            </TabButton>
            <TabButton
              name={REGISTER}
              active={this.state.activeTab === REGISTER}
              onClick={this.handleTabClick}
              data-test='register-tab'
            >
              Register
            </TabButton>
          </TabsHeader>
          {this.state.activeTab === LOGIN ? (
            <Login
              showRegisterSuccess={this.state.showRegisterSuccess}
              shouldLogout={this.props.shouldLogout}
            />
          ) : (
            <Register
              onSuccess={this.handleRegisterSuccess}
              shouldRenderRecaptcha={process.env.VITE_TARGET_ENV === 'production'}
            />
          )}
        </Tabs>
      </ContainerHome>
    );
  }
}

HomePageComponent.defaultProps = {
  shouldLogout: false,
  initialActiveTab: LOGIN,
  isLoggedIn: false,
};

HomePageComponent.propTypes = {
  shouldLogout: PropTypes.bool,
  initialActiveTab: PropTypes.string,
  isLoggedIn: PropTypes.bool,
  authLogout: PropTypes.func.isRequired,
};

const mapStateToProps = ({ auth }) => ({
  isLoggedIn: Boolean(getToken(auth)),
  usertype: getUserType(auth),
});

const mapDispatchToProps = {
  authLogout,
  fetchBanner,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HomePageComponent));

const Tabs = styled.div`
  width: 383px;
  background-color: ${(props) => props.theme.colors.white};
  border-radius: 4px;
  padding: 20px 40px 40px 40px;
  height: min-content;
`;

const TabsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  font-family: ${(props) => props.theme.fonts.fontFamilyDefault};
  height: 64px;
  margin-bottom: 15px;
  border-bottom: 4px solid ${(props) => props.theme.colors.grey40};
  position: relative;
`;

const TabButton = styled.button`
  border: none;
  background: none;
  outline: none;
  text-align: center;
  height: 100%;
  width: min-content;
  color: ${(props) => props.theme.colors.grey50};
  font-weight: 600;
  position: relative;
  top: 4px;

  &:first-child {
    padding: 0 30px 0 0;
  }

  &:last-child {
    padding: 0 0 0 30px;
  }

  ${(props) =>
    props.active &&
    `
    color: #000;
    border-bottom: 4px solid ${props.theme.colors.yellow60};
  `};
`;
