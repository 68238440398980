import styled, { css } from 'styled-components';

const ButtonDefault = styled.button`
  outline: none;
  border: none;
  background: ${(props) => props.theme.colors.grey50};
  color: ${(props) => props.theme.colors.white};
  ${(props) => props.theme.buttons.buttonDefault}
  ${(props) =>
    props.disabled &&
    `
    && {
      opacity: 0.5;
      cursor: not-allowed;
    }
  `}

  :hover {
    ${(props) => !props.disabled && props.theme.buttons.buttonDefaultHover}
  }

  :active {
    ${(props) => props.theme.buttons.buttonDefaultActive}
  }

  :focus {
    ${(props) => props.theme.buttons.buttonDefaultFocus}
  }
`;

export const greenButtonMixin = css`
  && :hover {
    background-color: ${(props) => props.theme.colors.green70};
  }

  :active,
  :focus,
  :active:focus {
    outline: none;
    background-color: ${(props) => props.theme.colors.green90};
    color: ${(props) => props.theme.colors.white};
  }

  ${(props) => props.disabled && 'box-shadow: none;'}

  && {
    background-color: ${(props) => props.theme.colors.green70};
    color: ${(props) => props.theme.colors.white};
  }
`;

export const greyButtonMixin = css`
  && :hover {
    background-color: ${(props) => props.theme.colors.grey60};
  }

  :active,
  :focus,
  :active:focus {
    outline: none;
    background-color: ${(props) => props.theme.colors.grey90};
    color: ${(props) => props.theme.colors.white};
  }

  ${(props) => props.disabled && 'box-shadow: none;'}

  && {
    background-color: ${(props) => props.theme.colors.grey60};
    color: ${(props) => props.theme.colors.white};
  }
`;

export const whiteButtonMixin = css`
  && :hover {
    background-color: ${(props) => props.theme.colors.white};
  }

  :active,
  :focus,
  :active:focus {
    outline: none;
    background-color: ${(props) => props.theme.colors.white};
    color: ${(props) => props.theme.colors.grey70};
  }

  ${(props) =>
    props.disabled && {
      'box-shadow': 'none',
      border: 'none !important',
    }}

  && {
    background-color: ${(props) => props.theme.colors.white};
    color: ${(props) => props.theme.colors.grey70};
    border: solid 1px #666666;
  }
`;

export const purpleButtonMixin = css`
  && :hover {
    background-color: ${(props) => props.theme.colors.purple50};
  }

  :active,
  :focus,
  :active:focus {
    outline: none;
    background-color: ${(props) => props.theme.colors.purple70};
    color: ${(props) => props.theme.colors.white};
  }

  ${(props) => props.disabled && 'box-shadow: none;'}

  && {
    background-color: ${(props) => props.theme.colors.purple50};
    color: ${(props) => props.theme.colors.white};
  }
`;

export default ButtonDefault;
