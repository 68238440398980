import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Modal } from 'react-bootstrap';

import BaseComponent from '../shared/BaseComponent';
import ToJS from '../shared/ToJS';
import ProgressBar from '../shared/LoadingIndicators/ProgressBar';

import VisionAirLogoCircle from '../../images/visionair-logo-circle.svg';
import WordmarkWhite from '../../images/main-wordmark-white.svg';

import resetProgress from '../../modules/progress/resetProgress';
import setViewerLoadingScreenVisible from '../../modules/viewerPage/setViewerLoadingScreenVisible';
import { getViewerLoadingScreenIsVisible } from '../../modules/viewerPage/selectors';

import {
  getProgress,
  LOAD_AIRWAY_PROGRESS,
  LOAD_CENTERLINE_PROGRESS,
  LOAD_AIRWAY_WORKER_PROGRESS,
  LOAD_PRIVATE_ROUTES_PROGRESS,
  LOAD_VIEWER_PROGRESS,
} from '../../modules/progress/selectors';

export class ViewerLoadingScreenComponent extends BaseComponent {
  handleLoadComplete() {
    // delay closing modal so user sees progress bar hit 100%
    setTimeout(() => {
      this.props.setViewerLoadingScreenVisible(false);
      this.props.resetProgress();
    }, 1000);
  }

  render() {
    const {
      isVisible,
      animation,
      loadAirwayProgress,
      loadCenterlineProgress,
      loadAirwayWorkerProgress,
      loadPrivateRoutesProgress,
      loadViewerProgress,
    } = this.props;

    return (
      <ProgressModal
        show={isVisible}
        animation={Boolean(animation)}
        backdrop='static'
        backdropClassName='viewer-loading-backdrop'
        dialogClassName='viewer-loading-dialog'
        data-test='viewer-loading-screen'
      >
        <Logo src={VisionAirLogoCircle} alt='Stent Architect' />
        <Wordmark src={WordmarkWhite} alt='VisionAir 3D' />
        <ProgressBarContainer>
          <ViewerProgressBar
            sources={[
              loadAirwayProgress,
              loadCenterlineProgress,
              loadAirwayWorkerProgress,
              loadPrivateRoutesProgress,
              loadViewerProgress,
            ]}
            onComplete={this.handleLoadComplete}
            active
          />
        </ProgressBarContainer>
      </ProgressModal>
    );
  }
}

const progressPropType = PropTypes.shape({
  loaded: PropTypes.number,
  total: PropTypes.number,
});

ViewerLoadingScreenComponent.propTypes = {
  isVisible: PropTypes.bool,
  animation: PropTypes.bool,
  loadAirwayProgress: progressPropType,
  loadCenterlineProgress: progressPropType,
  loadAirwayWorkerProgress: progressPropType,
  loadPrivateRoutesProgres: progressPropType,
  loadViewerProgress: progressPropType,
  setViewerLoadingScreenVisible: PropTypes.func.isRequired,
  resetProgress: PropTypes.func.isRequired,
};

function mapStateToProps({ viewerPage, progress }) {
  return {
    isVisible: getViewerLoadingScreenIsVisible(viewerPage),
    loadAirwayProgress: getProgress(progress, LOAD_AIRWAY_PROGRESS),
    loadCenterlineProgress: getProgress(progress, LOAD_CENTERLINE_PROGRESS),
    loadAirwayWorkerProgress: getProgress(progress, LOAD_AIRWAY_WORKER_PROGRESS),
    loadPrivateRoutesProgress: getProgress(progress, LOAD_PRIVATE_ROUTES_PROGRESS),
    loadViewerProgress: getProgress(progress, LOAD_VIEWER_PROGRESS),
  };
}

export default connect(mapStateToProps, { setViewerLoadingScreenVisible, resetProgress })(
  ToJS(ViewerLoadingScreenComponent),
);

const ProgressModal = styled(Modal)`
  pointer-events: none;
  display: flex !important;
  justify-content: center;
  align-items: center;

  &.fade {
    transition: transform 0.5s ease-out;
  }

  .viewer-loading-dialog {
    position: absolute;
    top: 30%;
    left: unset;
    transform: none !important;
    margin: 0;
  }

  .modal-content {
    width: 400px;
    padding: 20px 0;

    display: flex;
    flex-direction: column;
    justify-content: center;

    background: none;
    border: none;
    box-shadow: none;
  }
`;

const Logo = styled.img`
  height: 110px;
  filter: drop-shadow(0px 5px 15px rgba(0, 0, 0, 0.5));
`;

const Wordmark = styled.img`
  height: 50px;
  margin-bottom: 25px;
`;

const ProgressBarContainer = styled.div`
  height: 40px;
  border-radius: 20px;

  background-color: #ffffff;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);

  display: flex;
  justify-content: center;
  align-items: center;
`;

const ViewerProgressBar = styled(ProgressBar)`
  margin-bottom: 0;

  width: 370px;
  height: 10px;

  background-color: ${(props) => props.theme.colors.grey30};

  .progress-bar {
    background-color: ${(props) => props.theme.colors.purple70};
  }
`;
