import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import auth from './modules/auth/reducer';
import viewer from './modules/viewer/reducer';
import patients from './modules/patients/reducer';
import scans from './modules/scans/reducer';
import stents from './modules/stents/reducer';
import orders from './modules/orders/reducer';
import viewerPage from './modules/viewerPage/reducer';
import notifications from './modules/notifications/reducer';
import progress from './modules/progress/reducer';
import users from './modules/users/reducer';
import affiliations from './modules/affiliations/reducer';

const rootReducer = combineReducers({
  auth,
  viewer,
  patients,
  scans,
  stents,
  orders,
  viewerPage,
  notifications,
  progress,
  users,
  affiliations,
});

export const reducer = (state, action) => rootReducer(state, action);

export function makeStore() {
  return createStore(reducer, composeWithDevTools(applyMiddleware(thunk)));
}

export const defaultStore = makeStore();

export default defaultStore;
