import * as types from '../actions';

export default function setProgressAction(progressKey, loaded, total) {
  return {
    type: types.SET_PROGRESS,
    progressKey,
    loaded,
    total,
  };
}
