import * as types from '../actions/auth';
import { showNotificationSuccess } from '../notifications/showNotification';

export function authLogoutAction() {
  return {
    type: types.AUTH_LOGOUT,
  };
}

export default function authLogout(
  history,
  { message = "You've been logged out successfully.", showForMS = 5000 } = {},
) {
  return (dispatch) => {
    localStorage.removeItem('token');

    history.push('/logout');

    dispatch(authLogoutAction());
    dispatch(showNotificationSuccess(message, showForMS));
  };
}
