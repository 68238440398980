import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Link, Redirect, withRouter } from 'react-router-dom';
import jwt from 'jwt-decode';

import BaseComponent from '../shared/BaseComponent';
import TextInput from '../shared/Inputs';
import { ButtonDefault } from '../shared/Buttons';

import authLogin from '../../modules/auth/authLogin';
import fetchUser from '../../modules/auth/fetchUser';
import clearNotification from '../../modules/notifications/clearNotification';
import showHaveIBeenPwnedModal from '../../modules/auth/showHaveIBeenPwnedModal';

import {
  HttpUnauthorizedError,
  HttpForbiddenError,
  resendConfirmationEmail,
} from '../../utils/requests';
import { USERTYPES } from '../../settings/broncUsers';

export class LoginComponent extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      redirectToReferrer: false,
      errorMessage: null,
      email: '',
      password: '',
      usertype: null,
      mounted: false,
      isSubmitting: false,
    };
  }

  handleSubmit(e) {
    e.preventDefault();
    this.setState({ errorMessage: null, isSubmitting: true });
    this.props.clearNotification();

    const { email, password } = this.state;

    this.props
      .authLogin(email, password)
      .then(async ({ token, forcePasswordChange, pwnedPasswordCount }) => {
        if (forcePasswordChange) {
          localStorage.removeItem('token');
          this.setState({
            isSubmitting: false,
          });
          this.props.showHaveIBeenPwnedModal(true, pwnedPasswordCount);
          return;
        }

        const { id: userID } = jwt(token);
        const userInfo = await this.props.fetchUser(userID);

        this.setState({
          redirectToReferrer: true,
          usertype: userInfo.usertype,
          isSubmitting: false,
        });
      })
      .catch(async (err) => {
        this.setState({ password: '', isSubmitting: false });

        // TODO: specify our email domain to search inbox for
        if (err.response?.error.includes('inactive')) {
          this.setState({
            errorMessage: (
              <span>
                Your account has not been verified yet.
                <br />
                <br />
                Please check your email at <q>{email}</q> for an email from us.
                <br />
                <br />
                Please click the link inside it to verify your account.
              </span>
            ),
          });

          resendConfirmationEmail(email);
          return;
        }

        if (err.response?.error === 'Exceeded max login attempts') {
          this.setState({
            errorMessage: (
              <span>
                Your account has been temporarily disabled due to failed login attempts.
                <br />
                <br />
                Click &quot;Forgot Password&quot; below to reset your password.
              </span>
            ),
          });
          return;
        }

        if (err instanceof HttpUnauthorizedError) {
          this.setState({
            errorMessage: (
              <span>
                Email or Password is incorrect.
                <br />
                Forgot Password? Click the link below.
              </span>
            ),
          });
          return;
        }

        if (err instanceof HttpForbiddenError) {
          this.setState({
            errorMessage: (
              <span>
                You are not connected to your medical center&apos;s network.
                <br />
                <br />
                As a security precaution, your medical center requires you to be connected to its
                network when using this website.
                <br />
                <br />
                This may require you to be on-site at your medical center or remotely connect to
                your medical center&apos;s VPN.
                <br />
                <br />
                Try logging in again once you are connected to your medical center&apos;s network.
              </span>
            ),
          });

          return;
        }

        this.setState({
          errorMessage: (
            <span>
              An unexpected error occurred during login.
              <br />
              Please try again.
            </span>
          ),
        });
      });
  }

  handleChange({ target: { name, value } }) {
    this.setState({ [name]: value });
  }

  render() {
    const { usertype } = this.state;

    let from = { pathname: '/patients' };
    if (
      usertype === USERTYPES.USERTYPE_BILLING_MANAGER ||
      usertype === USERTYPES.USERTYPE_EQUIP_MANAGER
    ) {
      from.pathname = '/orders';
    }

    if (this.props.location.state?.from) {
      ({ from } = this.props.location.state);
    }

    if (from.pathname.match(/\/activate\/.*\/.*$/)) {
      from.pathname = '/patients';
    }

    const { redirectToReferrer, email, password } = this.state;

    if (redirectToReferrer) {
      return <Redirect to={from} />;
    }

    return (
      <form id='login-form' data-test='login-form' onSubmit={this.handleSubmit}>
        <TextInput
          type='email'
          name='email'
          label='Email'
          value={email}
          onChange={this.handleChange}
          required
          data-test='login-email'
        />
        <TextInput
          type='password'
          name='password'
          label='Password'
          value={password}
          onChange={this.handleChange}
          required
          data-test='login-password'
        />
        <ErrorMessage data-test='login-error-message'>{this.state.errorMessage}</ErrorMessage>
        <ForgotPasswordContainer data-test='login-forgot-password'>
          <ForgotLink id='login-forgot-password-link' to='/password-reset/request'>
            Forgot Password?
          </ForgotLink>
        </ForgotPasswordContainer>
        <LoginButton
          id='login-btn'
          name='login'
          type='submit'
          data-test='login-button'
          disabled={this.state.isSubmitting}
        >
          Login
        </LoginButton>
      </form>
    );
  }
}

LoginComponent.displayName = 'Login'; // fixes jest snapshot tests
LoginComponent.propTypes = {
  shouldLogout: PropTypes.bool,
};
LoginComponent.defaultProps = {
  shouldLogout: false,
};

export default withRouter(
  connect(undefined, { authLogin, clearNotification, fetchUser, showHaveIBeenPwnedModal })(
    LoginComponent,
  ),
);

const ErrorMessage = styled.div`
  color: #a94442;
`;

const ForgotPasswordContainer = styled.div`
  margin: 25px 0;
  text-align: center;
`;

const ForgotLink = styled(Link)`
  font-family: ${(props) => props.theme.fonts.fontFamilyDefault};
  font-size: 14px;
  font-weight: 600;
  color: #003de3;
`;

const LoginButton = styled(ButtonDefault)`
  outline: none;
  background-color: ${(props) => props.theme.colors.purple70};
  width: 100%;

  :hover {
    color: ${(props) => props.theme.colors.white};
    background-color: ${(props) => props.theme.colors.purple70};
  }

  :active,
  :focus {
    background-color: ${(props) => props.theme.colors.purple90};
  }
`;
