import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import BaseComponent from '../../components/shared/BaseComponent';

import { confirmEmailChange } from '../../utils/requests';

import {
  showNotificationSuccess,
  showNotificationError,
} from '../../modules/notifications/showNotification';

export class EmailChangeComponent extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      isFetchComplete: false,
    };
  }

  componentDidMount() {
    const { uid, token } = this.props.match.params;

    confirmEmailChange(uid, token)
      .then(({ newEmail }) => {
        this.setState({ isFetchComplete: true });
        this.props.showNotificationSuccess(
          `Success! Your email has been changed to ${newEmail}.<br/><br/> Please log in with it from now on.`,
          10000,
        );
      })
      .catch((err) => {
        this.setState({ isFetchComplete: true });
        this.props.showNotificationError(
          'Your verification link has expired.<br/><br/> You can request to change your email again via your User Profile.',
        );
        // eslint-disable-next-line no-console
        console.error(err);
      });
  }

  render() {
    if (this.state.isFetchComplete) {
      return (
        <Redirect
          to={{
            pathname: '/login',
          }}
        />
      );
    }

    return null;
  }
}

EmailChangeComponent.propTypes = {
  showNotificationSuccess: PropTypes.func.isRequired,
  showNotificationError: PropTypes.func.isRequired,
};

export default connect(undefined, {
  showNotificationSuccess,
  showNotificationError,
})(EmailChangeComponent);
