// https://redux.js.org/recipes/using-immutable.js-with-redux#use-a-higher-order-component-to-convert-your-smart-components-immutable-js-props-to-your-dumb-components-javascript-props
import React from 'react';
import { Iterable } from 'immutable';

function ToJS(WrappedComponent) {
  function toJS(wrappedCompProps) {
    const KEY = 0;
    const VALUE = 1;

    const propsJS = Object.entries(wrappedCompProps).reduce((newProps, wrappedCompProp) => {
      const newPropValue = Iterable.isIterable(wrappedCompProp[VALUE])
        ? wrappedCompProp[VALUE].toJS()
        : wrappedCompProp[VALUE];

      newProps[wrappedCompProp[KEY]] = newPropValue; /* eslint-disable-line no-param-reassign */

      return newProps;
    }, {});

    return <WrappedComponent {...propsJS} />;
  }

  toJS.displayName = `toJS(${WrappedComponent.displayName || WrappedComponent.name})`;

  return toJS;
}

export default ToJS;
