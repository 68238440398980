import colors from './colors';
import fonts from './fonts';

export default {
  textInput: {
    height: '40px',
    width: '100%',
    'padding-left': '10px',
    'padding-right': '10px',
    'background-color': colors.white,
    border: `solid 1px ${colors.grey50}`,
    'border-radius': '4px',
    'font-family': fonts.fontFamilyDefault,
    'font-size': '14px',
    color: colors.grey80,
  },
  textInputHover: {
    'box-shadow': '0 1px 6px 0 rgba(0, 0, 0, 0.16)',
  },
  textInputFocus: {
    'box-shadow': '0 1px 6px 0 rgba(0, 0, 0, 0.16)',
    border: `solid 2px ${colors.cyan60}`,
    color: colors.black,
  },
  textInputError: {
    border: `solid 1px ${colors.red100}`,
  },
  textInputDisabled: {
    'background-color': colors.grey30,
    cursor: 'not-allowed',
  },

  label: {
    'font-family': fonts.fontFamilyDefault,
    'font-size': '14px',
    color: colors.black,
    'margin-bottom': '10px',
  },

  helpText: {
    color: colors.grey50,
    'font-size': '12px',
    'margin-top': '6px',
  },
  feedbackError: {
    color: colors.red70,
    'font-size': '12px',
    'margin-top': '6px',
  },
};
