import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { MdError } from 'react-icons/md';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

import showHaveIBeenPwnedModal from '../../../modules/auth/showHaveIBeenPwnedModal';
import { getShowHaveIBeenPwnedModal, getPwnedPasswordCount } from '../../../modules/auth/selectors';

export default function HaveIBeenPwnedModal() {
  const dispatch = useDispatch();

  const { show, pwnedPasswordCount } = useSelector(({ auth }) => ({
    show: getShowHaveIBeenPwnedModal(auth),
    pwnedPasswordCount: getPwnedPasswordCount(auth),
  }));

  const handleClose = () => dispatch(showHaveIBeenPwnedModal(false, 0, null));

  return (
    <S.HaveIBeenPwnedModal open={show} onClose={handleClose} data-test='modal-hibp'>
      <S.DialogTitle disableTypography>
        <MdError size={40} color='#ff0000' style={{ marginRight: '14px' }} />
        You are required to change your password.
      </S.DialogTitle>
      <S.DialogContent>
        <DialogContentText>
          Your password has appeared{' '}
          <S.PasswordCount>
            {new Intl.NumberFormat('en-US').format(pwnedPasswordCount)}
          </S.PasswordCount>{' '}
          times in public data breaches. Continuing to use this password will expose your account to
          automated attacks.
          <br />
          <br />
          <strong>We&apos;ve sent you an email containing a link to change your password.</strong>
          <br />
          <br />
          We recommend using a password manager to generate and store your new password. Reputable
          password managers include{' '}
          <a target='_blank' rel='noopener noreferrer' href='https://www.lastpass.com/'>
            LastPass
          </a>
          ,{' '}
          <a target='_blank' rel='noopener noreferrer' href='https://bitwarden.com/'>
            Bitwarden
          </a>
          , and{' '}
          <a target='_blank' rel='noopener noreferrer' href='https://1password.com/'>
            1Password
          </a>
          .
          <br />
          <br />
          Results were retrieved from{' '}
          <a target='_blank' rel='noopener noreferrer' href='https://haveibeenpwned.com/Passwords'>
            haveibeenpwned.com
          </a>
          . For more information about how we securely check your password, check out{' '}
          <a
            target='_blank'
            rel='noopener noreferrer'
            href='https://blog.cloudflare.com/validating-leaked-passwords-with-k-anonymity/'
          >
            Cloudflare&apos;s blog post
          </a>
          .
        </DialogContentText>
      </S.DialogContent>
      <DialogActions>
        <S.LogoutButton onClick={handleClose}>Close</S.LogoutButton>
      </DialogActions>
    </S.HaveIBeenPwnedModal>
  );
}

const S = {};

S.HaveIBeenPwnedModal = styled(Dialog)`
  .MuiBackdrop-root {
    backdrop-filter: blur(5px);
    background-color: rgba(0, 0, 0, 0.7);
  }
`;

S.DialogTitle = styled(DialogTitle)`
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: 500;
`;

S.DialogContent = styled(DialogContent)``;

S.PasswordCount = styled.span`
  color: #ff0000;
`;

S.LogoutButton = styled(Button)`
  font-size: 14px;
  margin-right: 20px;
  padding: 6px 15px;
`;
