export const API_PATIENTS_URL = 'api/patients/';

export const getPatientsURL = () => `${API_PATIENTS_URL}`;

export const getPatientsWithScansURL = () => `${API_PATIENTS_URL}?withScans=true`;

export const getPatientURL = (patientID) => `${API_PATIENTS_URL}${patientID}/`;

export const updatePatientNotesURL = (patientID) => `${API_PATIENTS_URL}${patientID}/`;

export function searchPatientsURL({
  name,
  doctorName,
  mrn,
  dob,
  latestUploadDateStart, // ISO 8601 format
  latestUploadDateEnd, // ISO 8601 format
  offset,
  limit,
}) {
  const params = [];
  Object.entries({
    name,
    doctorName,
    mrn,
    dob,
    latestUploadDateStart,
    latestUploadDateEnd,
    offset,
    limit,
  }).forEach(([key, val]) => {
    if (!val) {
      return;
    }

    params.push(`${key}=${val}`);
  });

  return `${API_PATIENTS_URL}search?${params.join('&')}`;
}
