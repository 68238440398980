import * as types from '../actions/notifications';
import {
  SUCCESS_NOTIFICATION,
  ERROR_NOTIFICATION,
} from '../../components/shared/Notification/Notification';

export default function showNotification(message, showForMS, notificationType) {
  return {
    type: types.SHOW_NOTIFICATION,
    message,
    showForMS,
    notificationType,
  };
}

export function showNotificationSuccess(message, showForMS) {
  return showNotification(message, showForMS, SUCCESS_NOTIFICATION);
}

export function showNotificationError(message, showForMS) {
  return showNotification(message, showForMS, ERROR_NOTIFICATION);
}
