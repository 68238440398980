import { List, Iterable } from 'immutable';
import moment from 'moment';

let lastId = 0;

export const getId = (prefix = 'id') => {
  lastId += 1;
  return `${prefix}${lastId}`;
};

// https://stackoverflow.com/a/44198641/5781184
export function isValidDate(date) {
  return date && Object.prototype.toString.call(date) === '[object Date]' && !Number.isNaN(date);
}

// formats date string coming from DB into desired format (without time)
export const dateStringWithoutTime = (dateString) => {
  if (!dateString) {
    return '';
  }

  return dateString.split('T')[0];
};

export function formatDateWithoutTime(date) {
  const year = date.getUTCFullYear();
  const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
  const day = date.getUTCDate().toString().padStart(2, '0');
  return `${year}-${month}-${day}`;
}

// returns two dates - start date & end date string, encompassing a range determined by input length
// NOTE: input should match ISO 8601 format
// ex. input = 2020 -> year range
// start date: 2020-01-01T00:00:00.000Z
// end date: 2020-01-01T00:00:00.000Z
// ex. input = 2020-08 -> month range
// start date: 2020-08-01T00:00:00.000Z
// end date: 2020-09-01T00:00:00.000Z
// ex. input = 2020-08-14 -> day range
// start date: 2020-08-14T00:00:00.000Z
// end date: 2020-08-15T00:00:00.000Z
export function dateRangeFromISO8601Date(iso8601Date) {
  const startDate = moment(iso8601Date);
  const endDate = moment(startDate);

  if (!startDate.isValid()) {
    return {
      startDate: null,
      endDate: null,
    };
  }

  const dateNoHyphens = iso8601Date.replace(/-/g, '');
  if (!dateNoHyphens.length) {
    return {
      startDate: null,
      endDate: null,
    };
  }

  if (dateNoHyphens.length === 'YYYY'.length) {
    // year search
    endDate.add(1, 'years');
  }
  if (dateNoHyphens.length === 'YYYYMM'.length) {
    // month search
    endDate.add(1, 'months');
  }
  if (dateNoHyphens.length === 'YYYYMMDD'.length) {
    // day search
    endDate.add(1, 'days');
  }

  return {
    startDate,
    endDate,
  };
}

// convert input to immutable List
export function convertToList(input) {
  if (List.isList(input)) {
    return input;
  }

  if (input == null) {
    return List();
  }

  let inputAsList;
  if (Array.isArray(input)) {
    inputAsList = List(input);
  } else {
    inputAsList = List.of(input);
  }

  return inputAsList;
}

// checks if any values of specified fields differ between two Map objects
export function fieldsDiffer(map1, map2, fields) {
  return fields.some((field) => {
    // convert to array so that deep, nested checks are possible
    let fieldAsArray = field;
    if (!Array.isArray(fieldAsArray)) {
      fieldAsArray = [field];
    }
    // compare values
    const map1Value = map1.getIn(fieldAsArray);
    const map2Value = map2.getIn(fieldAsArray);
    return map1Value && map2Value && map1Value !== map2Value;
  });
}

// returns new array with new item added at specified index
// NOTE: does NOT mutate original array like splice() does!
export const insertIntoArray = (array, index, newItem) => [
  ...array.slice(0, index),
  newItem,
  ...array.slice(index),
];

export function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.substring(1);
}

export function delay(ms) {
  // eslint-disable-next-line promise/avoid-new
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, ms);
  });
}

export function toTitleCase(str) {
  if (!str) {
    return '';
  }

  return str
    .toLocaleLowerCase()
    .replace(/(^|\w)\S*/g, (txt) => txt.charAt(0).toLocaleUpperCase() + txt.substring(1));
}

export function getInitials(nameString) {
  if (!nameString || !nameString.length) {
    return '';
  }

  let firstInitial = '';
  let lastInitial = '';

  const firstLetters = nameString.match(/\b(\w)/g);

  // handle (<Last Name>, <First Name>)
  const isLastNameFirst = nameString.split(',').length > 1;
  if (isLastNameFirst) {
    const firstName = nameString.substring(nameString.indexOf(',') + 1).trim();
    if (firstName.length) {
      firstInitial = firstName[0].toUpperCase();
    }
    lastInitial = firstLetters[0].toUpperCase();
  } else {
    firstInitial = firstLetters[0].toUpperCase();
    if (firstLetters.length > 1) {
      lastInitial = firstLetters[firstLetters.length - 1].toUpperCase();
    }
  }

  return `${firstInitial}${lastInitial}`;
}

export function downloadFile(url, fileName) {
  const dummyAnchor = document.createElement('a');

  dummyAnchor.style = 'display: none;';
  dummyAnchor.href = url;
  dummyAnchor.download = fileName;

  document.body.appendChild(dummyAnchor);
  dummyAnchor.click();
  document.body.removeChild(dummyAnchor);
}

/**
 * Sorts array of objects in place in order of {dateKey} value, most recent first
 * @param {string} dateKey - key in Stent object (with value that can be converted to date)
 * to sort by, ex. updated_at, approved_at, archived_at
 * @param {Object} array - array of objects
 */
export const sortArrayByDate = (dateKey, array) =>
  [...array].sort((a, b) => new Date(b[dateKey]) - new Date(a[dateKey]));

// debounces provided function
export function debounced(delayMs, fn) {
  let timeout;
  return (...args) => {
    if (timeout) {
      clearTimeout(timeout);
    }

    timeout = setTimeout(() => {
      fn(...args);
      timeout = null;
    }, delayMs);
  };
}

// debounces async function, useful when you need to attach variable .then method
// promise will reject when the function is debounced
// and resolve when the provided function gets called
export function debouncedAsync(delayMs, fn) {
  let timeout;
  let prevPromiseReject;

  return (...args) => {
    if (timeout) {
      clearTimeout(timeout);
      prevPromiseReject('debounced');
    }

    // eslint-disable-next-line promise/avoid-new
    return new Promise((resolve, reject) => {
      prevPromiseReject = reject;

      timeout = setTimeout(() => {
        timeout = null;
        prevPromiseReject = null;

        resolve(fn(...args));
      }, delayMs);
    });
  };
}

// like Promise.race except waits for first resolved promise to fulfill
// resolves with first resolved promise
// rejects with array of all different error types encountered
export async function raceResolved(promiseArr) {
  const rejectedErrs = {};

  for (let i = 0; i < promiseArr.length; i += 1) {
    try {
      const dicomInfo = await promiseArr[i]; // eslint-disable-line no-await-in-loop

      // cancel remaining promises
      Promise.race(promiseArr).catch(() => {});

      return dicomInfo;
    } catch (err) {
      // keep latest instance of unique errors
      rejectedErrs[err.message] = err;
    }
  }

  return Promise.reject(Object.values(rejectedErrs));
}

export function getMedian(array, sortFn) {
  const sorted = [...array].sort(sortFn);
  const middleIndex = (sorted.length + 1) / 2;
  const isEven = sorted.length % 2 === 0;

  return isEven
    ? (sorted[middleIndex - 1.5] + sorted[middleIndex - 0.5]) / 2 // average of two middle els
    : sorted[middleIndex - 1]; // middle element
}

export function convertImmutable(immutableReduxProps) {
  if (immutableReduxProps == null) {
    return null;
  }

  if (Iterable.isIterable(immutableReduxProps)) {
    return immutableReduxProps.toJS();
  }

  const jsProps = immutableReduxProps instanceof Array ? [] : {};

  Object.entries(immutableReduxProps).forEach(([key, value]) => {
    jsProps[key] = Iterable.isIterable(value) ? value.toJS() : value;
  });

  return jsProps;
}

export function phoneInputMaskFactory(value) {
  // ignore input mask if user enters country code
  if (value[0] === '+') {
    return false;
  }

  return [/[1-9,+]/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
}

export function formatPhoneNumber(phoneNumberString) {
  const cleaned = `${phoneNumberString}`.replace(/\D/g, '');
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (!match) {
    return phoneNumberString;
  }

  const intlCode = match[1] ? '+1 ' : '';

  return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
}

export function parseQueryString(query) {
  const parts = query
    .replace(/\?/, '')
    .split('&')
    .map((queryPart) => queryPart.split('='));

  const queryMap = {};
  parts.forEach(([key, value]) => {
    queryMap[key] = value;
  });

  return queryMap;
}

export function genSerialNum(ctNum, designLetter, coreNum) {
  // append "C" to core number if necessary
  let cn = String(coreNum);
  cn = cn.charAt(coreNum.length - 1) === 'C' ? cn : `${cn}C`;

  return `Y.${ctNum}.${designLetter}.${cn}`;
}

export function convertFileToImg(file) {
  const img = document.createElement('img');
  const reader = new FileReader();

  // eslint-disable-next-line promise/avoid-new
  return new Promise((resolve, reject) => {
    reader.addEventListener('load', () => {
      img.src = reader.result;
      resolve(img);
    });

    reader.addEventListener('error', reject);

    reader.readAsDataURL(file);
  });
}
