export const API_USERS_URL = 'api/users';

export const createUserURL = () => `${API_USERS_URL}/`;

export const getUsersURL = ({ withAffils }) =>
  withAffils ? `${API_USERS_URL}/?withAffils=true` : `${API_USERS_URL}/`;

export const getUsersReportURL = () => `${API_USERS_URL}/report/`;

export const getUserURL = (userID) => `${API_USERS_URL}/${userID}/`;

export const getUsersByUsertypeURL = (usertype) => `${API_USERS_URL}/?usertype=${usertype}`;

export const updateUserURL = (userID) => `${API_USERS_URL}/${userID}/`;

export const deleteUserURL = (userID) => `${API_USERS_URL}/${userID}/`;

export const changeEmailURL = (userID) => `${API_USERS_URL}/${userID}/email/change/`;

export const changePasswordURL = (userID) => `${API_USERS_URL}/${userID}/password/change/`;

export const resendAccountCreationEmailURL = (userID) =>
  `${API_USERS_URL}/${userID}/activate/resend/`;

export function searchUsersURL({ name, usertype, email, withAffils, limit, offset }) {
  const params = [];
  Object.entries({
    name,
    usertype,
    email,
    withAffils,
    limit,
    offset,
  }).forEach(([key, val]) => {
    if (!val) {
      return;
    }

    params.push(`${key}=${val}`);
  });

  return `${API_USERS_URL}/?${params.join('&')}`;
}
