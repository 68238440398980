export const API_STENTS_URL = 'api/stents/';

export const createStentURL = () => `${API_STENTS_URL}`;

export const duplicateStentURL = (stentID) => `${API_STENTS_URL}${stentID}/duplicate/`;

export const getStentsByScanURL = (scanID) => `${API_STENTS_URL}?scan=${scanID}`;

export const getStentURL = (stentID) => `${API_STENTS_URL}${stentID}/`;

export const updateStentURL = (stentID) => `${API_STENTS_URL}${stentID}/`;

export const uploadStentFileURL = (stentID) => `${API_STENTS_URL}${stentID}/upload/`;

export const getStentFileURL = (stentID, fileName) => `static/stents/${stentID}/${fileName}`;

export const createJailedAirwayURL = (stentID) => `${API_STENTS_URL}${stentID}/jailed-airways`;

export const getJailedAirwaysURL = (stentID) => `${API_STENTS_URL}${stentID}/jailed-airways`;
