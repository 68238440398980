import React from 'react';

import LoadingScreen from './LoadingScreen';

export default function lazyLoadHOC(LazyComponent, LoadingComponent = LoadingScreen) {
  return function LazyLoad(props) {
    return (
      <React.Suspense fallback={<LoadingComponent />}>
        <LazyComponent {...props} />
      </React.Suspense>
    );
  };
}
