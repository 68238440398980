import { Map, fromJS, OrderedMap } from 'immutable';
import * as types from '../actions';
import { SORT_DESC } from '../../components/shared/Tables/InfiniteScrollTable';

export const searchFields = {
  SCAN_NUM: 'scanNum', // value should match URL query string param
  DOC_NAME: 'doctorName',
  PATIENT_NAME: 'patientName',
  UPLOAD_DATE: 'uploadDate',
};

export const INITIAL_STATE = new Map({
  byId: new OrderedMap(),
  scansTable: new Map({
    search: new Map({
      value: '',
      field: searchFields.SCAN_NUM,
    }),
    sort: new Map({
      sortField: 'uploaded_at',
      sortDir: SORT_DESC,
    }),
    isLoading: false,
  }),
});

function clearScans(state) {
  return state.set('byId', OrderedMap());
}

function insertScan(state, payload) {
  const { scan } = payload;

  return state.setIn(['byId', scan.id], fromJS(scan));
}

export function insertScans(state, scans) {
  let newState = state;
  scans.forEach((scan) => {
    newState = insertScan(newState, { scan });
  });

  return newState;
}

function setScansTableSearch(state, { value, field }) {
  let newState = state;

  if (value !== undefined) {
    newState = newState.setIn(['scansTable', 'search', 'value'], value);
  }
  if (field !== undefined) {
    newState = newState.setIn(['scansTable', 'search', 'field'], field);
  }

  return newState;
}

function setScansTableSort(state, { sortField, sortDir }) {
  return state
    .setIn(['scansTable', 'sort', 'sortField'], sortField)
    .setIn(['scansTable', 'sort', 'sortDir'], sortDir);
}

function setScansTableIsLoading(state, isLoading) {
  return state.setIn(['scansTable', 'isLoading'], isLoading);
}

function resetScansTable(state) {
  return state.set('scansTable', INITIAL_STATE.get('scansTable'));
}

export default function scansReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.AUTH_LOGOUT:
      return INITIAL_STATE;
    case types.RESET_SCANS_TABLE:
      return resetScansTable(state);
    case types.CLEAR_SCANS:
      return clearScans(state);
    case types.UPDATE_SCAN:
    case types.RECEIVE_SCAN:
      return insertScan(state, action.payload);
    case types.RECEIVE_SCANS:
    case types.RECEIVE_SCANS_BY_PATIENT:
      return insertScans(state, action.payload);
    case types.SET_SCANS_TABLE_SEARCH:
      return setScansTableSearch(state, action);
    case types.SET_SCANS_TABLE_SORT:
      return setScansTableSort(state, action.payload);
    case types.SET_SCANS_TABLE_IS_LOADING:
      return setScansTableIsLoading(state, action.payload);
    default:
      return state;
  }
}
