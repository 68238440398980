export const API_AFFILIATIONS_URL = 'api/affiliations';

export const createAffiliationURL = () => `${API_AFFILIATIONS_URL}/`;

export const getAffiliationByID = (affiliationID) => `${API_AFFILIATIONS_URL}/${affiliationID}/`;

export const getAffiliationsURL = () => `${API_AFFILIATIONS_URL}/`;

export const getAffiliationsByUserURL = (userID) => `${API_AFFILIATIONS_URL}/?userID=${userID}`;

export const linkAffiliationURL = (affilID) => `${API_AFFILIATIONS_URL}/${affilID}/link/`;

export const updateAffiliationURL = (affiliationID) => `${API_AFFILIATIONS_URL}/${affiliationID}/`;

export const updateAffilBillingURL = (affiliationID) =>
  `${API_AFFILIATIONS_URL}/${affiliationID}/billing/`;

export const deleteAffiliationURL = (affiliationID) => `${API_AFFILIATIONS_URL}/${affiliationID}/`;

export function searchAffiliationsURL({ hospital, state, offset, limit }) {
  const params = [];
  Object.entries({ hospital, state, offset, limit }).forEach(([key, val]) => {
    if (!val) {
      return;
    }

    params.push(`${key}=${val}`);
  });

  return `${API_AFFILIATIONS_URL}/?${params.join('&')}`;
}
