import {
  USERTYPE_TECHNICIAN,
  USERTYPE_ADMINISTRATOR,
  USERTYPE_DOCTOR,
  USERTYPE_ASSISTANT,
  USERTYPE_EQUIP_MANAGER,
  USERTYPE_BILLING_MANAGER,
} from '../../settings/broncUsers';

export const getTokenExpirationMs = (state) => state.get('tokenExpiration') * 1000;
export const getTokenIssuedAtMs = (state) => state.get('tokenIssuedAt') * 1000;
export const getTokenOrigIssuedAtMs = (state) => state.get('tokenOrigIssuedAt') * 1000;
export const getTokenRefreshedAtMs = (state) => state.get('refreshedAtMs');
export const getUserID = (state) => state.get('userID');
export const getUserType = (state) => state.get('userType');
export const getUserEmail = (state) => state.get('email');
export const isLoggedIn = (state) => !!state.get('token');
export const getToken = (state) => state.get('token');
export const getUserIsActive = (state) => state.get('isActive');

export const isDoctor = (state) => getUserType(state) === USERTYPE_DOCTOR;
export const isAssistant = (state) => getUserType(state) === USERTYPE_ASSISTANT;
export const isTechnician = (state) => getUserType(state) === USERTYPE_TECHNICIAN;
export const isAdministrator = (state) => getUserType(state) === USERTYPE_ADMINISTRATOR;
export const isEquipManager = (state) => getUserType(state) === USERTYPE_EQUIP_MANAGER;
export const isBillingManager = (state) => getUserType(state) === USERTYPE_BILLING_MANAGER;

export const getIsCOSUser = (state) => isTechnician(state) || isAdministrator(state);

export const getShowLogoutWarning = (state) => state.get('showLogoutWarning');

export const getUserFirstName = (state) => state.get('firstName');
export const getUserLastName = (state) => state.get('lastName');

export const getUserFullName = (state) => `${state.get('firstName')} ${state.get('lastName')}`;

export const getShowOnboardingModal = (state) => state.get('showOnboardingModal');

export const getShowHaveIBeenPwnedModal = (state) => state.get('showHaveIBeenPwnedModal');
export const getPwnedPasswordCount = (state) => state.get('pwnedPasswordCount');

export const getShowIPWhitelistWarning = (state) => state.get('showIPWhitelistWarning');
