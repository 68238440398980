export const API_DEVICE_LABELS = 'api/device-labels';

export const getDeviceLabelsURL = (orderNum) => `${API_DEVICE_LABELS}?orderNum=${orderNum}`;
export const getDeviceLabelsByPackingListURL = (packingListID) =>
  `${API_DEVICE_LABELS}?packingList=${packingListID}`;

export const uploadDeviceLabelURL = () => `${API_DEVICE_LABELS}/upload`;

export const getDeviceLabelFileURL = (labelID, filename) =>
  `static/device-labels/${labelID}/${filename}`;
