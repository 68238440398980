import React from 'react';
import styled from 'styled-components';

import LoadingThreeDots from './LoadingThreeDots';
import VisionAirLogoCircle from '../../../images/visionair-logo-circle.svg';

export default function VisionairLoadingIcon() {
  return (
    <LoadingIconContainer data-test='loading-screen'>
      <Logo src={VisionAirLogoCircle} alt='loading' />
      <LoadingThreeDots color='#5f259f' />
    </LoadingIconContainer>
  );
}

const LoadingIconContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: calc(100vh - 160px);
`;

const Logo = styled.img`
  height: 110px;
  filter: drop-shadow(0px 5px 15px rgba(0, 0, 0, 0.5));
`;
