export const VIEWER_PAGE_RESET = 'VIEWER_PAGE_RESET';
export const DRAWER_TOGGLE = 'DRAWER_TOGGLE';
export const MODAL_TOGGLE = 'MODAL_TOGGLE';
export const MENU_TOGGLE = 'MENU_TOGGLE';
export const SET_SHOW_ADVANCED_SPHERE_CONTROLS = 'SET_SHOW_ADVANCED_SPHERE_CONTROLS';
export const SPHERE_BOUNDS_WARNING_TOGGLE = 'SPHERE_BOUNDS_WARNING_TOGGLE';
export const SET_VIEWER_LOADING_SCREEN_VISIBLE = 'SET_VIEWER_LOADING_SCREEN_VISIBLE';
export const SET_SHOW_VIEWER_BACKDROP = 'SET_SHOW_VIEWER_BACKDROP';
export const SET_IS_GENERATING_PDF = 'SET_IS_GENERATING_PDF';
export const SET_SHOW_ANGLE_WARNING = 'SET_SHOW_ANGLE_WARNING';
