import * as types from '../actions/notifications';
import setBannerIsOpen from './setBannerIsOpen';

import { fetchBannerMessage } from '../../utils/requests';

export function setBanner(message, expiresAt) {
  localStorage.setItem('bannerMessage', message);

  return {
    type: types.SET_BANNER,
    message,
    expiresAt,
  };
}

export default function fetchBanner() {
  return (dispatch) =>
    fetchBannerMessage('banner.json')
      .then((banner) => {
        const { message, expiresAt } = banner;

        if (!message) {
          // no message, hide banner
          dispatch(setBannerIsOpen(false));
          dispatch(setBanner('', null));
          return null;
        }

        if (expiresAt && Date.now() >= expiresAt) {
          // message expired, hide banner
          dispatch(setBannerIsOpen(false));
          dispatch(setBanner('', null));
          return null;
        }

        const messageLS = localStorage.getItem('bannerMessage');
        const isOpenLS = localStorage.getItem('bannerIsOpen') === 'true';

        if (message === messageLS) {
          // banner message has NOT changed, use saved "isOpen" state
          dispatch(setBannerIsOpen(isOpenLS));
        } else {
          // banner message has changed, show banner
          dispatch(setBannerIsOpen(true));
        }

        dispatch(setBanner(message, expiresAt));

        return banner;
      })
      .catch((err) => {
        console.error(err); // eslint-disable-line no-console
      });
}
