import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { Link, useLocation, useHistory } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';

import visionairWordmark from '../../../images/visionair-wordmark-black.svg';
import visionairLogo from '../../../images/visionair-logo-purple.png';

import { getUserID, getUserType } from '../../../modules/auth/selectors';
import { ButtonDefault } from '../Buttons';

import { USERTYPES, USERTYPE_EQUIP_MANAGER } from '../../../settings/broncUsers';

const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundImage: 'linear-gradient(to top, white 160%, white 14%, #f4f4f4)',
    zIndex: theme.zIndex.drawer + 1,
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    boxShadow: '0 3px 0 #ededed',
  },
  toolbar: {
    width: '100%',
    justifyContent: 'space-between',
    minWidth: 1024,
    minHeight: 64,
    paddingLeft: '100px',
    paddingRight: '100px',
    '@media (max-width: 1440px)': {
      paddingLeft: '60px',
      paddingRight: '60px',
    },
  },
}));

export default function Nav() {
  const classes = useStyles();

  const history = useHistory();
  const { pathname } = useLocation();
  const userID = useSelector(({ auth }) => getUserID(auth));
  const usertype = useSelector(({ auth }) => getUserType(auth));

  return (
    <AppBar position='sticky' color='inherit' className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <Link
          to={
            usertype === USERTYPES.USERTYPE_BILLING_MANAGER || usertype === USERTYPE_EQUIP_MANAGER
              ? '/orders'
              : '/patients'
          }
        >
          <Logo src={visionairLogo} alt='VA' />
          <Wordmark src={visionairWordmark} alt='VisionAir 3D' />
        </Link>
        <div style={{ display: 'flex', height: '100%' }}>
          {usertype === USERTYPES.USERTYPE_ADMINISTRATOR && (
            <NavLink
              to='/admin'
              active={pathname.match(/^\/admin(\/|$)/) ? 'true' : undefined}
              data-test='admin-nav-link'
            >
              Admin
            </NavLink>
          )}
          {(usertype === USERTYPES.USERTYPE_DOCTOR ||
            usertype === USERTYPES.USERTYPE_ADMINISTRATOR ||
            usertype === USERTYPES.USERTYPE_TESTER) && (
            <NavLink
              to='/patients'
              active={pathname.match(/^\/patients(\/|$)/) ? 'true' : undefined}
              data-test='patients-nav-link'
            >
              Patients
            </NavLink>
          )}
          {(usertype === USERTYPES.USERTYPE_ADMINISTRATOR ||
            usertype === USERTYPES.USERTYPE_TESTER) && (
            <NavLink
              to='/scans'
              active={pathname.match(/^\/scans(\/|$)/) ? 'true' : undefined}
              data-test='scans-nav-link'
            >
              Scans
            </NavLink>
          )}
          {usertype !== USERTYPES.USERTYPE_TESTER && (
            <NavLink to='/orders' active={pathname.match(/^\/orders(\/|$)/) ? 'true' : undefined}>
              Orders
            </NavLink>
          )}
          <NavLink
            to={`/users/${userID}`}
            active={pathname === `/users/${userID}` ? 'true' : undefined}
            data-test='profile-nav-link'
          >
            My Profile
          </NavLink>
          {usertype !== USERTYPES.USERTYPE_ADMINISTRATOR && (
            <SupportLink
              href='https://visionair3d.groovehq.com/help'
              target='_blank'
              rel='noopener noreferrer'
              data-test='support-nav-link'
            >
              Help &amp; Support
            </SupportLink>
          )}
        </div>
        <LogoutBtn data-test='logout-button' onClick={() => history.push('/logout')}>
          Logout
        </LogoutBtn>
      </Toolbar>
    </AppBar>
  );
}

const Logo = styled.img`
  width: 34px;
  margin-right: 15px;
`;

const Wordmark = styled.img`
  position: relative;
  top: -3px;
`;

const NavLink = styled(Link)`
  display: flex;
  align-items: center;
  margin: 0 25px;
  font-size: 14px;
  font-weight: 500;
  color: black;

  ${(props) =>
    props.active &&
    `
    font-weight: bold;
    border-width: 0;
    box-shadow: 0 3px 0 #1aa6a0;
  `}
`;

const SupportLink = styled.a`
  display: flex;
  align-items: center;
  margin: 0 25px;
  font-size: 14px;
  font-weight: 500;
  color: black;
`;

const LogoutBtn = styled(ButtonDefault)`
  height: 35px;
  padding: 0 60px;

  && {
    background: #f5f5f5;
    color: #414141;
  }
`;
