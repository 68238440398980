export const getDrawerIsOpen = (state, drawerID) =>
  Boolean(state.getIn(['drawers', drawerID, 'isOpen']));
export const getModalIsOpen = (state, modalID) =>
  Boolean(state.getIn(['modals', modalID, 'isOpen']));
export const getMenuIsOpen = (state, menuID) => Boolean(state.getIn(['menus', menuID, 'isOpen']));

export const getShowAdvancedSphereControls = (state) => state.get('showAdvancedSphereControls');

export const getShowSphereEnclosedWarning = (state) =>
  state.getIn(['modals', 'sphere-bounds-warning', 'showEnclosed']);
export const getShowSphereOutsideWarning = (state) =>
  state.getIn(['modals', 'sphere-bounds-warning', 'showOutside']);
export const getShowSphereOversizedWarning = (state) =>
  state.getIn(['modals', 'sphere-bounds-warning', 'showOversized']);

export const getHasThicknessBeenTurnedOn = (state) => state.get('hasThicknessBeenTurnedOn');

export const getViewerLoadingScreenIsVisible = (state) => state.get('viewerLoadingScreenVisible');
export const getShowBackdrop = (state) => state.get('showBackdrop');

export const getIsGeneratingPDF = (state) => state.get('isGeneratingPDF');

export const getShowAngleWarning = (state) => state.get('showAngleWarning');

export const DRAWER_RIGHT_ID = 'DRAWER_RIGHT';

export const MODAL_STENT_THICKNESS_ID = 'stent-thickness';
export const MODAL_SPHERE_DIAMS_ID = 'sphere-diams';
export const MODAL_SPHERE_BOUNDS_WARNING_ID = 'sphere-bounds-warning';

export const MENU_WALL_THICKNESS = 'wall-thickness';
export const MENU_3D_MEASURE = 'measure-3d';
export const MENU_3D_VISIBILITY = 'visibility-3d';
export const MENU_SNAP_CAMERA = 'snap-camera';
export const MENU_SLICE_VISIBILITY = 'visibility-slice';
export const MENU_SLICE_MEASURE = 'measure-slice';
export const MENU_VIEWER_SETTINGS = 'viewer-settings';
export const MENU_WINDOWING = 'windowing';
