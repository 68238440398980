import React from 'react';
import styled, { css } from 'styled-components';
import MaskedInput from 'react-text-mask';
import PropTypes from 'prop-types';

import BaseComponent from '../BaseComponent';

import { getId } from '../../../utils/general';

export default class TextInput extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      text: '',
      showError: false,
    };
  }

  handleChange(e) {
    const { value: newInputValue } = e.target;
    let showError = this.props.validateInput ? !this.props.validateInput(newInputValue) : false;

    if (this.props.regex) {
      showError = !new RegExp(this.props.regex).test(newInputValue);
    }

    this.props.handleChange(e);
    this.setState({ text: newInputValue, showError });
  }

  render() {
    const {
      value,
      type,
      regex,
      handleChange,
      label,
      showError,
      errorMessage,
      mask,
      forwardRef,
      className,
      id,
      'data-test': dataTest,
      ...rest
    } = this.props;
    const showErrorMessage = showError || this.state.showError;

    // eslint-disable-next-line no-use-before-define
    const Input = mask ? StyledMaskedInput : StyledTextInput;

    const inputID = id || `input-${getId()}`;

    return (
      <Container className={className}>
        {label && <StyledLabel htmlFor={inputID}>{label}</StyledLabel>}
        <Input
          mask={mask}
          value={value || this.state.text}
          onChange={this.handleChange}
          error={String(showErrorMessage)}
          type={type}
          ref={forwardRef}
          id={inputID}
          data-test={dataTest}
          {...rest}
        />
        {showErrorMessage && (
          <ErrorMessage data-test={`error-message-${dataTest}`}>{errorMessage}</ErrorMessage>
        )}
      </Container>
    );
  }
}

TextInput.defaultProps = {
  className: '',
  errorMessage: null,
  forwardRef: null,
  handleChange: () => {},
  label: null,
  regex: null,
  showError: false,
  type: 'text',
  value: null,
};

TextInput.propTypes = {
  className: PropTypes.string,
  errorMessage: PropTypes.string,
  forwardRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  handleChange: PropTypes.func,
  label: PropTypes.string,
  regex: PropTypes.string,
  showError: PropTypes.bool,
  type: PropTypes.string,
  validateInput: PropTypes.func,
  value: PropTypes.string,
};

const Container = styled.div`
  margin-bottom: 15px;
`;

const StyledLabel = styled.label`
  ${(props) => props.theme.forms.label}
`;

const inputStyling = css`
  outline: none;
  ${(props) => props.theme.forms.textInput}

  :hover {
    ${(props) => !props.disabled && props.theme.forms.textInputHover}
  }
  :focus {
    ${(props) => props.theme.forms.textInputFocus}
  }

  ${(props) => props.error === 'true' && props.theme.forms.textInputError}

  && {
    ${(props) => props.disabled && props.theme.forms.textInputDisabled}
  }
`;

const StyledTextInput = styled.input`
  ${inputStyling}
`;

const StyledMaskedInput = styled(MaskedInput)`
  ${inputStyling}
`;

const ErrorMessage = styled.div`
  ${(props) => props.theme.forms.feedbackError}
`;
