import { fromJS } from 'immutable';

import * as types from '../actions';

export const JAILED_STAGE = 0;
export const STENT_STAGE = 1;
export const APPROVE_STAGE = 2;
export const REVIEW_STAGES = {
  JAILED_STAGE,
  STENT_STAGE,
  APPROVE_STAGE,
};

export const INITIAL_STATE = fromJS({
  isMeasuringStentDiameter: false,
  isMeasuringAirway: false,
  isMeasuringCT: false,
  stage: null,
  stentID: null,
  scanID: null,
  patientID: null,
  isSceneLocked: false,
  showCTViews: false,
  review: {
    stage: JAILED_STAGE,
    stentRotateDegs: 0,
  },
});

function viewerSetStage(state, payload) {
  return state.set('stage', payload.stage);
}

function viewerSetStentInfo(state, { stentID, scanID, patientID }) {
  let newState = state;
  if (stentID !== undefined) {
    newState = newState.set('stentID', stentID);
  }
  if (scanID !== undefined) {
    newState = newState.set('scanID', scanID);
  }
  if (patientID !== undefined) {
    newState = newState.set('patientID', patientID);
  }

  return newState;
}

function viewerSetIsMeasuringStentDiameter(state, payload) {
  return state.set('isMeasuringStentDiameter', payload.isMeasuringStentDiameter);
}

function viewerSetSceneLocked(state, payload) {
  return state.set('isSceneLocked', payload.lock);
}

function viewerSetIsMeasuringAirway(state, isMeasuringAirway) {
  return state.set('isMeasuringAirway', isMeasuringAirway);
}

function viewerSetShowCTViews(state, showCTViews) {
  return state.set('showCTViews', showCTViews);
}

function viewerSetIsMeasuringCT(state, isMeasuringCT) {
  return state.set('isMeasuringCT', isMeasuringCT);
}

function viewerSetReviewStage(state, { reviewStage }) {
  return state.setIn(['review', 'stage'], reviewStage);
}

function viewerRotateStentReview(state, { rotateDegs }) {
  return state.setIn(['review', 'stentRotateDegs'], rotateDegs);
}

export default function viewer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.AUTH_LOGOUT:
    case types.VIEWER_RESET:
      return INITIAL_STATE;
    case types.VIEWER_SET_IS_MEASURING_STENT_DIAMETER:
      return viewerSetIsMeasuringStentDiameter(state, action.payload);
    case types.VIEWER_SET_STAGE:
      return viewerSetStage(state, action.payload);
    case types.VIEWER_SET_STENT_INFO:
      return viewerSetStentInfo(state, action.payload);
    case types.VIEWER_SET_SCENE_LOCKED:
      return viewerSetSceneLocked(state, action.payload);
    case types.VIEWER_SET_IS_MEASURING_AIRWAY:
      return viewerSetIsMeasuringAirway(state, action.isMeasuringAirway);
    case types.VIEWER_SET_SHOW_CT_VIEWS:
      return viewerSetShowCTViews(state, action.showCTViews);
    case types.VIEWER_SET_IS_MEASURING_CT:
      return viewerSetIsMeasuringCT(state, action.isMeasuringCT);
    case types.VIEWER_SET_REVIEW_STAGE:
      return viewerSetReviewStage(state, action.payload);
    case types.VIEWER_ROTATE_STENT_REVIEW:
      return viewerRotateStentReview(state, action.payload);
    default:
      return state;
  }
}
