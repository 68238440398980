import { fromJS } from 'immutable';
import * as types from '../actions';
import { DRAWER_RIGHT_ID, MODAL_STENT_THICKNESS_ID } from './selectors';

export const INITIAL_STATE = fromJS({
  drawers: {
    [DRAWER_RIGHT_ID]: { isOpen: false },
  },
  modals: {},
  menus: {},
  showAdvancedSphereControls: false,
  hasThicknessBeenTurnedOn: false,
  viewerLoadingScreenVisible: false,
  showBackdrop: false,
  isGeneratingPDF: false,
  showAngleWarning: false,
});

export default function viewerPage(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.VIEWER_PAGE_RESET:
      return INITIAL_STATE;
    case types.DRAWER_TOGGLE:
      return toggleDrawer(state, action);
    case types.MODAL_TOGGLE:
      return toggleModal(state, action);
    case types.MENU_TOGGLE:
      return toggleMenu(state, action);
    case types.SET_SHOW_ADVANCED_SPHERE_CONTROLS:
      return setShowAdvancedSphereControls(state, action.payload);
    case types.SPHERE_BOUNDS_WARNING_TOGGLE:
      return toggleSphereBoundsWarning(state, action);
    case types.SET_HAS_THICKNESS_BEEN_TURNED_ON:
      return setHasThicknessBeenTurnedOn(state, action.payload);
    case types.SET_VIEWER_LOADING_SCREEN_VISIBLE:
      return setViewerLoadingScreenVisible(state, action);
    case types.SET_SHOW_VIEWER_BACKDROP:
      return setShowViewerBackdrop(state, action.payload);
    case types.SET_IS_GENERATING_PDF:
      return setIsGeneratingPDF(state, action);
    case types.SET_SHOW_ANGLE_WARNING:
      return setShowAngleWarning(state, action);
    default:
      return state;
  }
}

function toggleDrawer(state, { drawerID, isOpen }) {
  return state.setIn(['drawers', drawerID, 'isOpen'], isOpen);
}

function toggleModal(state, { modalID, isOpen }) {
  return state.setIn(['modals', modalID, 'isOpen'], isOpen);
}

function toggleMenu(state, { menuID, isOpen }) {
  const newState = state.setIn(['menus', menuID, 'isOpen'], isOpen);
  const menus = newState.get('menus').map((menuData, id) => {
    // close all other modals
    if (id !== menuID) {
      return menuData.set('isOpen', false);
    }

    return menuData;
  });

  return newState.set('menus', menus);
}

function setShowAdvancedSphereControls(state, { show }) {
  return state.set('showAdvancedSphereControls', show);
}

function toggleSphereBoundsWarning(
  state,
  { modalID, isOpen, showEnclosed, showOutside, showOversized },
) {
  let newState = state.setIn(['modals', modalID, 'isOpen'], isOpen);
  // Don't update showEnclosed/showOutside unless they are provided (ie. true/false vs undefined)
  // TODO: These values will be logged at approval in future so they need to be maintained
  // even after modal closes
  if (typeof showEnclosed === 'boolean') {
    newState = newState.setIn(['modals', modalID, 'showEnclosed'], showEnclosed);
  }
  if (typeof showOutside === 'boolean') {
    newState = newState.setIn(['modals', modalID, 'showOutside'], showOutside);
  }
  if (typeof showOutside === 'boolean') {
    newState = newState.setIn(['modals', modalID, 'showOversized'], showOversized);
  }

  return newState;
}

function setHasThicknessBeenTurnedOn(state, { hasBeenTurnedOn }) {
  if (hasBeenTurnedOn) {
    const newState = state.set('hasThicknessBeenTurnedOn', true);
    return toggleModal(newState, { modalID: MODAL_STENT_THICKNESS_ID, isOpen: false });
  }

  return state;
}

function setViewerLoadingScreenVisible(state, { isVisible }) {
  return state.set('viewerLoadingScreenVisible', isVisible);
}

function setShowViewerBackdrop(state, { show }) {
  return state.set('showBackdrop', show);
}

function setIsGeneratingPDF(state, { isGeneratingPDF }) {
  return state.set('isGeneratingPDF', isGeneratingPDF);
}

function setShowAngleWarning(state, { showAngleWarning }) {
  return state.set('showAngleWarning', showAngleWarning);
}
