export const API_ORDERS_URL = 'api/orders/';

export const getStentOrderURL = (stentID) => `${API_ORDERS_URL}?stentID=${stentID}`;

export const getOrdersURL = () => `${API_ORDERS_URL}`;

export const getOrderFileURL = (orderNum, fileName) => `static/orders/${orderNum}/${fileName}`;

export const uploadOrderFileURL = (orderNum) => `${API_ORDERS_URL}${orderNum}/upload/`;

export const updateOrderStatusURL = (orderNum) => `${API_ORDERS_URL}${orderNum}/status`;

export const getOrderByOrderNumberURL = (orderNumber) => `${API_ORDERS_URL}${orderNumber}`;

export const getPOByOrderNumber = (orderNum) => `${API_ORDERS_URL}${orderNum}/po`;

export const getBillingReportURL = (startDate, endDate) =>
  `${API_ORDERS_URL}/billing-report?startDate=${startDate}&endDate=${endDate}`;

export const uploadPackingListURL = (orderNum) => `${API_ORDERS_URL}${orderNum}/packing-list`;

export const searchOrdersURL = ({
  orderNumber,
  orderStatus = [],
  orderDate = { start: null, end: null }, // ISO 8601 format
  patientName,
  patientMRN,
  scanNum,
  doctorName,
  affiliationID,
  limit,
  offset,
}) => {
  const queryStrings = [];
  if (orderNumber) {
    queryStrings.push(`orderNumber=${orderNumber}`);
  }
  if (orderStatus?.length) {
    const orderStatusList = [].concat(orderStatus);
    queryStrings.push(`orderStatus=${orderStatusList.join(',')}`);
  }
  if (orderDate?.start) {
    queryStrings.push(`orderDateStart=${orderDate.start}`);
  }
  if (orderDate?.end) {
    queryStrings.push(`orderDateEnd=${orderDate.end}`);
  }
  if (patientName) {
    queryStrings.push(`patientName=${patientName}`);
  }
  if (patientMRN) {
    queryStrings.push(`patientMRN=${patientMRN}`);
  }
  if (scanNum) {
    queryStrings.push(`scanNum=${scanNum}`);
  }
  if (doctorName) {
    queryStrings.push(`doctorName=${doctorName}`);
  }
  if (affiliationID) {
    queryStrings.push(`affiliationID=${affiliationID}`);
  }
  if (limit) {
    queryStrings.push(`limit=${limit}`);
  }
  if (offset) {
    queryStrings.push(`offset=${offset}`);
  }

  return `${API_ORDERS_URL}search?${queryStrings.join('&')}`;
};
