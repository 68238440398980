import { Map, fromJS } from 'immutable';
import * as types from '../actions/patients';
import { AUTH_LOGOUT } from '../actions/auth';
import { SORT_DESC } from '../../components/shared/Tables/InfiniteScrollTable';

export const searchFields = {
  NAME: 'name',
  MRN: 'mrn',
  DOB: 'dob',
  SEX: 'sex',
  LATEST_UPLOAD_DATE: 'latestUploadDate',
  DOC_NAME: 'doctorName',
};

export const INITIAL_STATE = new Map({
  byId: new Map(),
  patientsTable: new Map({
    search: new Map({
      value: '',
      field: searchFields.NAME,
    }),
    sort: new Map({
      field: searchFields.LATEST_UPLOAD_DATE,
      dir: SORT_DESC,
    }),
    isLoading: false,
  }),
});

function insertPatient(state, payload) {
  const { patient } = payload;
  return state.setIn(['byId', patient.id], fromJS(patient));
}

export function insertPatients(state, payload) {
  let newState = state;
  const { patients } = payload;
  if (patients && patients.length) {
    patients.forEach((patient) => {
      newState = insertPatient(newState, { patient });
    });
  }

  return newState;
}

function setPatientNotes(state, patientID, notes) {
  return state.setIn(['byId', patientID, 'notes'], notes);
}

function setPatientsTableSearch(state, { value, field }) {
  let newState = state;

  if (value !== undefined) {
    newState = newState.setIn(['patientsTable', 'search', 'value'], value);
  }
  if (field !== undefined) {
    newState = newState.setIn(['patientsTable', 'search', 'field'], field);
  }

  return newState;
}

function setPatientsTableSort(state, { field, dir }) {
  let newState = state;

  if (field !== undefined) {
    newState = newState.setIn(['patientsTable', 'sort', 'field'], field);
  }
  if (dir !== undefined) {
    newState = newState.setIn(['patientsTable', 'sort', 'dir'], dir);
  }

  return newState;
}

function setPatientsTableIsLoading(state, isLoading) {
  return state.setIn(['patientsTable', 'isLoading'], isLoading);
}

function resetPatientsTable(state) {
  return state.set('patientsTable', INITIAL_STATE.get('patientsTable'));
}

export default function patientsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case AUTH_LOGOUT:
      return INITIAL_STATE;
    case types.RESET_PATIENTS_TABLE:
      return resetPatientsTable(state);
    case types.RECEIVE_PATIENT:
      return insertPatient(state, action.payload);
    case types.RECEIVE_PATIENTS:
      return insertPatients(state, action.payload);
    case types.SET_PATIENT_NOTES:
      return setPatientNotes(state, action.patientID, action.notes);
    case types.SET_PATIENTS_SEARCH:
      return setPatientsTableSearch(state, action);
    case types.SET_PATIENTS_SORT:
      return setPatientsTableSort(state, action);
    case types.SET_PATIENTS_LOADING:
      return setPatientsTableIsLoading(state, action.isLoading);
    default:
      return state;
  }
}
