import { fromJS } from 'immutable';
import * as types from '../actions';
import { SUCCESS_NOTIFICATION } from '../../components/shared/Notification/Notification';

export const getInitialState = () =>
  fromJS({
    message: null,
    notificationType: SUCCESS_NOTIFICATION,
    showForMS: null,
    closeImmediately: false,
    banner: {
      message: localStorage.getItem('bannerMessage') || '',
      isOpen: localStorage.getItem('bannerIsOpen') === 'true',
      expiresAt: null,
    },
  });

export default function notifications(state = getInitialState(), action) {
  switch (action.type) {
    case types.CLEAR_NOTIFICATION:
      return clearNotification(state, action);
    case types.SHOW_NOTIFICATION:
      return showNotification(state, action);
    case types.SET_BANNER:
      return setBanner(state, action);
    case types.SET_BANNER_IS_OPEN:
      return setBannerIsOpen(state, action);
    default:
      return state;
  }
}

function clearNotification(state, { closeImmediately }) {
  return state
    .set('message', null)
    .set('showForMS', null)
    .set('notificationType', state.get('notificationType')) // persist type so styling doesn't change during animation
    .set('closeImmediately', closeImmediately);
}

function showNotification(state, { message, notificationType, showForMS }) {
  return state
    .set('message', message)
    .set('notificationType', notificationType)
    .set('showForMS', showForMS)
    .set('closeImmediately', false);
}

function setBanner(state, { message, expiresAt }) {
  return state.setIn(['banner', 'message'], message).setIn(['banner', 'expiresAt'], expiresAt);
}

function setBannerIsOpen(state, { isOpen }) {
  return state.setIn(['banner', 'isOpen'], isOpen);
}
