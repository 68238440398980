import React, { useEffect, useCallback, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

import CircularProgress from '@material-ui/core/CircularProgress';

import ContainerHome from '../../components/shared/Container/ContainerHome';
import TextInput from '../../components/shared/Inputs';
import { ButtonDefault } from '../../components/shared/Buttons';

import {
  showNotificationSuccess,
  showNotificationError,
} from '../../modules/notifications/showNotification';

import { loadZxcvbn } from '../../utils/zxcvbn';
import ReactRouterPropTypes from '../../utils/propTypes/react-router';
import { finishAccountCreation } from '../../utils/requests';

import { MIN_PASSWORD_LENGTH } from '../../settings/broncUsers';
import { SUPPORT_EMAIL } from '../../settings/email';

export default function PasswordCreate() {
  const dispatch = useDispatch();
  const history = useHistory();

  const { uid, token } = useParams();

  const [isZxcvbnLoaded, setIsZxcvbnLoaded] = useState(false);

  useEffect(() => {
    async function loadPasswordStrength() {
      try {
        await loadZxcvbn();
        setIsZxcvbnLoaded(true);
      } catch (err) {
        dispatch(
          showNotificationError('An unexpected error was encountered. Please try again later.'),
        );
        history.push('/login');
      }
    }

    loadPasswordStrength();
  }, [dispatch, history]);

  const handleSubmit = useCallback(
    (values) => {
      const { password } = values;

      return finishAccountCreation(uid, token, password)
        .then(() => {
          dispatch(showNotificationSuccess('Your password was successfully created.'));
          history.push('/login');
        })
        .catch((err) => {
          const { error } = (err || {}).response || {};

          if (error === 'Token is expired' || error === 'Token is invalid') {
            dispatch(
              showNotificationError(
                `The link you're using is expired. <br /><br /> Please contact us at ${SUPPORT_EMAIL} to receive a new one.`,
              ),
            );
            return;
          }

          if (error === 'User is already active') {
            dispatch(
              showNotificationError(
                `Your account password has already been created. <br /><br /> If you've forgotten your password, request a password reset via the login page.`,
              ),
            );
            return;
          }

          dispatch(
            showNotificationError(
              'There was a problem creating your password.<br /> Please try again later.',
            ),
          );
          console.error(err); // eslint-disable-line no-console
        });
    },
    [dispatch, history, token, uid],
  );

  return (
    <ContainerHome>
      <S.PasswordContainer>
        <S.MenuTitle>Create Account Password</S.MenuTitle>
        <S.Separator />
        {isZxcvbnLoaded ? (
          <Formik
            initialValues={{
              password: '',
              passwordConfirm: '',
            }}
            validationSchema={Yup.object({
              password: Yup.string()
                .required('Required')
                .min(MIN_PASSWORD_LENGTH, `Must be at least ${MIN_PASSWORD_LENGTH} characters`)
                .passwordStrength(),
              passwordConfirm: Yup.string()
                .required('Required')
                .oneOf([Yup.ref('password')], 'Passwords must match'),
            })}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting }) => (
              <S.FormFormik>
                <Field name='password'>
                  {({ field, meta }) => (
                    <S.Input
                      label='Password'
                      type='password'
                      showError={Boolean(meta.touched && meta.error)}
                      errorMessage={(meta.touched && meta.error) || ' '}
                      {...field}
                      id='accountCreate-password'
                      data-test='password'
                    />
                  )}
                </Field>

                <Field name='passwordConfirm'>
                  {({ field, meta }) => (
                    <S.Input
                      label='Confirm Password'
                      type='password'
                      showError={Boolean(meta.touched && meta.error)}
                      errorMessage={(meta.touched && meta.error) || ' '}
                      {...field}
                      id='accountCreate-passwordConfirm'
                      data-test='confirm-password'
                    />
                  )}
                </Field>

                <S.ButtonContainer>
                  <S.ButtonLayout>
                    <S.SubmitButton
                      type='submit'
                      disabled={isSubmitting}
                      data-test='create-password-button'
                    >
                      Create Password
                    </S.SubmitButton>
                    <S.BackButton to='/login'>Back To Login</S.BackButton>
                  </S.ButtonLayout>
                </S.ButtonContainer>
              </S.FormFormik>
            )}
          </Formik>
        ) : (
          <S.LoadingContainer data-test='accountCreate-loadingIndicator'>
            <CircularProgress style={{ color: '#5d1c9a' }} />
          </S.LoadingContainer>
        )}
      </S.PasswordContainer>
    </ContainerHome>
  );
}

PasswordCreate.propTypes = {
  match: ReactRouterPropTypes.match.isRequired, // eslint-disable-line react/no-typos
  history: ReactRouterPropTypes.history.isRequired, // eslint-disable-line react/no-typos
  authLoginAction: PropTypes.func.isRequired,
  setLogoutWarningTimeout: PropTypes.func.isRequired,
  showNotificationSuccess: PropTypes.func.isRequired,
  showNotificationError: PropTypes.func.isRequired,
};

const S = {};

S.PasswordContainer = styled.div`
  display: flex;
  flex-direction: column;

  width: 437px;
  min-height: 400px;

  background-color: ${(props) => props.theme.colors.white};
  box-shadow: 0 8px 10px -5px ${(props) => props.theme.colors.colorShadowHoverDark},
    0 6px 30px 5px ${(props) => props.theme.colors.colorShadowHoverLight},
    0 16px 24px 2px ${(props) => props.theme.colors.colorShadowHoverMedium};
  padding: 20px 40px;
  border-radius: 4px;
`;

S.MenuTitle = styled.div`
  font-weight: 600;
  font-size: 18px;
  font-family: ${(props) => props.theme.fonts.fontFamilyDefault};
  margin-left: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
`;

S.Separator = styled.div`
  border-color: ${(props) => props.theme.colors.yellow60};
  border-width: 2px;
  border-style: solid;
`;

S.FormFormik = styled(Form)`
  display: flex;
  flex-direction: column;

  padding-top: 25px;
`;

S.Input = styled(TextInput)`
  min-height: 95px;
  margin: 0;
`;

S.ButtonContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  width: 100%;
`;

S.ButtonLayout = styled.div`
  width: 100%;
  height: 100px;
  justify-content: space-evenly;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
`;

S.SubmitButton = styled(ButtonDefault)`
  outline: none;
  background-color: ${(props) => props.theme.colors.purple70};
  width: 100%;

  :hover {
    color: ${(props) => props.theme.colors.white};
    background-color: ${(props) => props.theme.colors.purple70};
  }

  :active,
  :focus {
    background-color: ${(props) => props.theme.colors.purple90};
  }
`;

S.BackButton = styled(Link)`
  color: #4d317c;
  font-weight: bold;
  font-family: ${(props) => props.theme.fonts.fontFamilyDefault};
`;

S.LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  flex-grow: 1;
  width: 100%;
`;
