import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import BaseComponent from '../../components/shared/BaseComponent';

import { activateUser } from '../../utils/requests';

import {
  showNotificationSuccess,
  showNotificationError,
} from '../../modules/notifications/showNotification';

export class ActivateComponent extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      isFetchComplete: false,
    };
  }

  componentDidMount() {
    const { uid, token } = this.props.match.params;

    activateUser(uid, token)
      .then(() => {
        this.setState({ isFetchComplete: true });
        this.props.showNotificationSuccess('Success! Your email has been verified.');
      })
      .catch((err) => {
        const { error } = (err || {}).response || {};

        if (error === 'User is already active') {
          this.props.showNotificationError(
            `Your account has already been activated. <br /><br /> If you've forgotten your password, request a password reset.`,
            15000,
          );
        } else {
          this.props.showNotificationError('Uh oh! Your verification link has expired.');
        }

        this.setState({ isFetchComplete: true });
        // eslint-disable-next-line no-console
        console.error(err);
      });
  }

  render() {
    if (this.state.isFetchComplete) {
      return (
        <Redirect
          to={{
            pathname: '/login',
            state: { from: this.props.location },
          }}
        />
      );
    }

    return null;
  }
}

ActivateComponent.displayName = 'Activate'; // fixes jest snapshot tests

ActivateComponent.propTypes = {
  showNotificationSuccess: PropTypes.func.isRequired,
  showNotificationError: PropTypes.func.isRequired,
};

export default connect(undefined, {
  showNotificationSuccess,
  showNotificationError,
})(ActivateComponent);
