import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import MuiBanner from './MuiBanner';

import { getBannerMessage, getBannerIsOpen } from '../../../modules/notifications/selectors';
import setBannerIsOpen from '../../../modules/notifications/setBannerIsOpen';

export default function StatusBanner({ persistent, className }) {
  const dispatch = useDispatch();

  const message = useSelector(({ notifications }) => getBannerMessage(notifications));
  const isOpen = useSelector(({ notifications }) => getBannerIsOpen(notifications));

  if (!message) {
    return null;
  }

  return (
    <Banner
      open={persistent || isOpen}
      onClose={() => dispatch(setBannerIsOpen(false))}
      label={message}
      paperProps={{ square: true, 'data-test': 'status-banner' }}
      showDismissButton={!persistent}
      dismissButtonProps={{ 'data-test': 'status-banner-dismiss-btn' }}
      appBar
      className={className}
    />
  );
}

StatusBanner.propTypes = {
  persistent: PropTypes.bool,
};

StatusBanner.defaultProps = {
  persistent: false,
};

const Banner = styled(MuiBanner)`
  position: sticky;
  top: 64px;
  z-index: 1201;

  .MuiPaper-root {
    background-color: #ffdfde;
    display: block;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .MuiCard-root {
    background-color: #ffdfde;
    color: #d51313;
  }

  .MuiGrid-container {
    justify-content: center;
    height: 68px;
  }

  .MuiBanner-flex {
    flex-grow: 0;
  }

  .MuiBanner-label {
    .MuiTypography-root {
      font-weight: 500;
    }
  }

  .MuiCardActions-root {
    justify-content: flex-end;
  }

  .MuiBanner-buttons {
    align-self: center;
  }

  .MuiButton-label {
    color: #d51313;
    text-decoration: underline;
    font-weight: 700;
  }

  .MuiDivider-root {
    display: none;
  }
`;
