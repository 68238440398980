export const API_TOKEN_URL = 'auth/get-token/';
export const API_TOKEN_REFRESH_URL = 'auth/refresh-token/';
export const API_ACTIVATE_URL = 'auth/activate/';
export const API_REGISTER_URL = 'auth/register/';
export const API_FINISH_ACCOUNT_URL = 'auth/finish-account/';
export const API_PASSWORD_RESET_REQUEST_URL = 'auth/password-reset/request/';
export const API_PASSWORD_RESET_CONFIRM_URL = 'auth/password-reset/confirm/';
export const API_RESEND_CONFIRMATION_URL = 'auth/resend-confirmation-email/';
export const API_CONFIRM_EMAIL_CHANGE_URL = 'auth/change-email/';

export const registerUserURL = () => API_REGISTER_URL;

export const activateUserURL = () => API_ACTIVATE_URL;

export const resendConfirmationURL = (email) =>
  `${API_RESEND_CONFIRMATION_URL}?email=${encodeURIComponent(email)}`;

export const confirmEmailChangeURL = () => API_CONFIRM_EMAIL_CHANGE_URL;
