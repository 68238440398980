export const VIEWER_ADD_MOUSE_LISTENERS = 'VIEWER_ADD_MOUSE_LISTENERS';
export const VIEWER_MOUNT = 'VIEWER_MOUNT';
export const VIEWER_RESET = 'VIEWER_RESET';
export const VIEWER_LOCK_SCENE = 'VIEWER_LOCK_SCENE';
export const VIEWER_SET_STAGE = 'VIEWER_SET_STAGE';
export const VIEWER_SET_IS_MEASURING_STENT_DIAMETER = 'VIEWER_SET_IS_MEASURING_STENT_DIAMETER';
export const VIEWER_SET_STENT_INFO = 'VIEWER_SET_STENT_INFO';
export const VIEWER_SET_SCENE_LOCKED = 'VIEWER_SET_SCENE_LOCKED';
export const VIEWER_SET_SHOW_ADVANCED_SPHERE_CONTROLS = 'VIEWER_SET_SHOW_ADVANCED_SPHERE_CONTROLS';
export const VIEWER_SET_IS_MEASURING_AIRWAY = 'VIEWER_SET_IS_MEASURING_AIRWAY';
export const VIEWER_SET_SHOW_CT_VIEWS = 'VIEWER_SET_SHOW_CT_VIEWS';
export const VIEWER_SET_IS_MEASURING_CT = 'VIEWER_SET_IS_MEASURING_CT';

export const VIEWER_SET_REVIEW_STAGE = 'VIEWER_SET_REVIEW_STAGE';
export const VIEWER_ROTATE_STENT_REVIEW = 'VIEWER_ROTATE_STENT_REVIEW';
