import colors from './colors';
import fonts from './fonts';

export default {
  dialog: {
    'border-radius': '4px',
    'box-shadow': `0 11px 15px -7px ${colors.colorShadowHoverDark}, 0 9px 46px 8px ${colors.colorShadowHoverLight}, 0 24px 38px 3px ${colors.colorShadowHoverMedium}`,
    'font-family': fonts.fontFamilyDefault,
    'font-size': '14px',
    color: colors.black,
  },
  header: {
    height: '70px',
    display: 'flex',
    'justify-content': 'center',
    'align-items': 'center',
    'font-size': '18px',
    'font-weight': '500',
  },
  footer: {
    height: '100px',
    display: 'flex',
    'justify-content': 'space-between',
    'align-content': 'center',
    'align-items': 'center',
    'text-align': 'inherit',
  },
};
