import * as types from '../actions';
import { getToken } from './selectors';
import { getUser } from '../../utils/requests';

export function setUserInfo(user) {
  return {
    type: types.AUTH_SET_USER_INFO,
    user,
  };
}

export default function fetchUser(userID) {
  return (dispatch, getState) => {
    const token = getToken(getState().auth);

    return getUser(userID, token).then((user) => {
      dispatch(setUserInfo(user));

      return user;
    });
  };
}
