import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import StatusBanner from '../Notification/StatusBanner';

import HomeBackgroundImage from '../../../images/home-page/home-bg-purple.jpg';
import VASLogo from '../../../images/home-page/home-logo-sphere.png';

import { version } from '../../../../package.json';

function ContainerHome({ children, className }) {
  return (
    <S.BackgroundContainer className={className}>
      <S.Background />
      <S.PageContainer>
        <S.StatusBanner persistent />
        <S.BodyContainer>
          <S.ImageContainer>
            <S.Logo src={VASLogo} alt='VisionAir' />
            <S.StentArchitect>Stent Architect</S.StentArchitect>
            <S.Version>v{version}</S.Version>
          </S.ImageContainer>
          {children}
        </S.BodyContainer>
      </S.PageContainer>
    </S.BackgroundContainer>
  );
}

ContainerHome.defaultProps = {
  className: '',
};

ContainerHome.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element])
    .isRequired,
  className: PropTypes.string,
};

export default ContainerHome;

const S = {};

S.BackgroundContainer = styled.div`
  min-height: 557px;
  min-width: 1024px;
  width: 100%;
  overflow: hidden;

  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
`;

S.Background = styled.div`
  height: 100%;
  position: absolute;
  display: flex;
  width: 100%;

  background: url(${HomeBackgroundImage}) no-repeat center center fixed;
  background-size: cover;
`;

S.PageContainer = styled.div`
  height: 100%;
  min-height: 100vh;

  position: relative;
  z-index: 2;

  @media (max-width: 1200px) {
    justify-content: space-around;
  }
`;

S.StatusBanner = styled(StatusBanner)`
  top: 0;
  position: absolute;
  width: 100%;
`;

S.BodyContainer = styled.div`
  width: 1024px;
  height: 100%;
  min-height: 100vh;

  margin: auto;
  padding: 0 30px 5vh 30px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 1200px) {
    justify-content: space-around;
  }
`;

S.ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: -95px;
`;

S.Logo = styled.img`
  margin-bottom: 15px;
`;

S.StentArchitect = styled.div`
  font-family: Inter;
  font-size: 36px;
  font-weight: 100;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: normal;
  color: white;
`;

S.Version = styled.span`
  color: #ffffff;
  margin-top: 5px;
  margin-right: 24px;
  font-family: Inter;
  font-size: 16px;
  font-weight: 200;
  align-self: flex-end;
`;
