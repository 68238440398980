import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';

import BaseComponent from '../shared/BaseComponent';
import TextInput from '../shared/Inputs';
import { ButtonDefault } from '../shared/Buttons';
import TermsAndConditionsModal from './TermsAndConditionsModal';

import { registerUser } from '../../utils/requests';
import ReactRouterPropTypes from '../../utils/propTypes/react-router';
import zxcvbn, { loadZxcvbn } from '../../utils/zxcvbn';

import { showNotificationSuccess } from '../../modules/notifications/showNotification';

import { USERTYPE_DOCTOR, MIN_PASSWORD_LENGTH } from '../../settings/broncUsers';
import { RECAPTCHA_SITEKEY } from '../../settings/api';

export class RegisterComponent extends BaseComponent {
  constructor(...args) {
    super(...args);

    this.state = {
      email: '',
      password: '',
      passwordConfirm: '',
      first: '',
      last: '',
      type: USERTYPE_DOCTOR,
      passwordErrorMessage: '',
      confirmationPasswordErrorMessage: '',
      recaptchaToken: null,
      errorMessage: null,
      showTermsModal: false,
    };
  }

  componentDidMount() {
    loadZxcvbn();
  }

  handleSubmit(e) {
    e.preventDefault();
    this.setState({ errorMessage: null });

    const { recaptchaToken } = this.state;
    const { shouldRenderRecaptcha } = this.props;

    if (shouldRenderRecaptcha && !recaptchaToken) {
      this.setState({ errorMessage: "Please confirm you're not a robot and try again." });
      return;
    }

    if (!this.isFormValid()) {
      return;
    }

    this.setState({ showTermsModal: true });
  }

  handleRegister() {
    const { email, password, type, first, last, recaptchaToken } = this.state;

    const userInfo = {
      email,
      password,
      full_name: `${first} ${last}`,
      firstName: first,
      lastName: last,
      usertype: type,
    };

    registerUser(userInfo, recaptchaToken)
      .then(() => {
        this.props.history.push('/');
        this.props.showNotificationSuccess(
          'You have been successfully registered! <br /><br /> Please check your email to confirm your new account.',
          Infinity,
        );
        this.props.onSuccess();
      })
      .catch((err) => {
        this.setState({
          errorMessage: 'There was a problem while registering. Please try again.',
          showTermsModal: false,
        });
        // eslint-disable-next-line no-console
        console.error(err);
      });
  }

  handleRecaptchaChange(recaptchaToken) {
    this.setState({ recaptchaToken });
  }

  handleFieldChange({ target: { name, value } }) {
    this.setState({ [name]: value });
  }

  handlePasswordConfirmChange({ target: { name, value } }) {
    let confirmationPasswordErrorMessage = '';

    const passwordsMatch = value === this.state.password;

    if (value && !passwordsMatch) {
      confirmationPasswordErrorMessage = 'Passwords must match!';
    }

    this.setState({ [name]: value, confirmationPasswordErrorMessage });
  }

  handlePasswordChange({ target: { name, value } }) {
    const passwordValidation = zxcvbn(value);
    const passwordErrorMessage =
      value && value.length >= MIN_PASSWORD_LENGTH
        ? passwordValidation.feedback.warning
        : `Need at least ${MIN_PASSWORD_LENGTH} characters`;

    this.setState({ [name]: value, passwordErrorMessage });
  }

  isPasswordConfirmValid() {
    const { password, passwordConfirm } = this.state;
    return password === passwordConfirm;
  }

  isFormValid() {
    const { recaptchaToken } = this.state;
    const { shouldRenderRecaptcha } = this.props;

    return (
      (!shouldRenderRecaptcha && this.isPasswordConfirmValid()) ||
      (shouldRenderRecaptcha && recaptchaToken && this.isPasswordConfirmValid())
    );
  }

  render() {
    const { password, passwordConfirm, errorMessage } = this.state;

    return (
      <>
        <Form id='register-form' onSubmit={this.handleSubmit} data-test='register-form'>
          <TextInput
            id='register-email'
            type='email'
            name='email'
            label='Email'
            value={this.state.email}
            onChange={this.handleFieldChange}
            data-test='register-email'
            required
          />
          <PasswordInput
            id='register-password'
            type='password'
            label='Password'
            name='password'
            autoComplete='new-password'
            value={password}
            onChange={this.handlePasswordChange}
            errorMessage={this.state.passwordErrorMessage}
            showError={!!this.state.passwordErrorMessage}
            data-test='register-password'
            required
          />
          <PasswordInput
            id='register-password-confirm'
            type='password'
            label='Confirm Password'
            name='passwordConfirm'
            autoComplete='new-password'
            value={passwordConfirm}
            onChange={this.handlePasswordConfirmChange}
            errorMessage={this.state.confirmationPasswordErrorMessage}
            showError={!!this.state.confirmationPasswordErrorMessage}
            data-test='register-confirm-password'
            required
          />
          <TextInput
            id='register-first-name'
            name='first'
            label='First Name'
            value={this.state.first}
            onChange={this.handleFieldChange}
            data-test='register-first-name'
            required
          />
          <TextInput
            id='register-last-name'
            label='Last Name'
            name='last'
            value={this.state.last}
            onChange={this.handleFieldChange}
            data-test='register-last-name'
            required
          />
          <UserTypeSelectContainer>
            <div>User Type</div>
            <UserTypeSelect id='register-user-type' name='type' data-test='register-user-type'>
              <option value={USERTYPE_DOCTOR}>Doctor</option>
            </UserTypeSelect>
          </UserTypeSelectContainer>
          <Recaptcha className='g-recaptcha' data-test='register-recaptcha'>
            <ReCAPTCHA
              sitekey={RECAPTCHA_SITEKEY}
              onChange={this.handleRecaptchaChange}
              ref={(ref) => {
                this.captchaRef = ref;
              }}
            />
          </Recaptcha>
          <ErrorMessage>{errorMessage}</ErrorMessage>
          <RegisterButton
            name='register'
            type='submit'
            disabled={!!zxcvbn(password).feedback.warning || password !== passwordConfirm}
            data-test='register'
          >
            Register
          </RegisterButton>
        </Form>
        <TermsAndConditionsModal
          open={this.state.showTermsModal}
          onClose={() => this.setState({ showTermsModal: false })}
          onApprove={this.handleRegister}
        />
      </>
    );
  }
}

RegisterComponent.displayName = 'Register'; // fixes jest snapshot tests

RegisterComponent.propTypes = {
  history: ReactRouterPropTypes.history.isRequired,
  shouldRenderRecaptcha: PropTypes.bool.isRequired,
  showNotificationSuccess: PropTypes.func.isRequired,
};

export default withRouter(
  connect(undefined, {
    showNotificationSuccess,
  })(RegisterComponent),
);

const ErrorMessage = styled.div`
  color: ${(props) => props.theme.colors.red70};
  margin-bottom: 20px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
`;

const UserTypeSelectContainer = styled.div`
  font-family: ${(props) => props.theme.fonts.fontFamilyDefault};
  font-size: 14px;
  display: none; /* hide select for now */
`;

const UserTypeSelect = styled.select`
  width: 100%;
  height: 40px;
  background: none;
  border-radius: 4px;
  margin: 10px 0 15px 0;
  padding-left: 10px;
  border: solid 1px ${(props) => props.theme.colors.grey50};
  outline: none;

  :focus {
    box-shadow: 0 2px 4px -1px ${(props) => props.theme.colors.colorShadowActiveDark},
      0 1px 10px 0 ${(props) => props.theme.colors.colorShadowActiveLight},
      0 4px 5px 0 ${(props) => props.theme.colors.colorShadowActiveMedium};
    border: solid 2px ${(props) => props.theme.colors.cyan60};
  }
`;

const Recaptcha = styled.div`
  height: 78px;
  width: 304px;
  margin-top: 10px;
  margin-bottom: 15px;
`;

const RegisterButton = styled(ButtonDefault)`
  outline: none;
  background-color: ${(props) => props.theme.colors.purple70};
  width: 100%;

  :hover {
    color: ${(props) => props.theme.colors.white};
    background-color: ${(props) => props.theme.colors.purple70};
  }

  :active,
  :focus {
    background-color: ${(props) => props.theme.colors.purple90};
  }
`;

const PasswordInput = styled(TextInput)`
  min-height: 95px;
  margin-bottom: 0;
`;
