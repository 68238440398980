import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Glyphicon } from 'react-bootstrap';

import BaseComponent from '../BaseComponent';

const CheckmarkIcon = styled(Glyphicon)`
  font-size: 17px;
`;

export default class Checkbox extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      isChecked: props.initialChecked,
    };
  }

  handleChange(e) {
    const isChecked = e.target.checked;
    this.setState({ isChecked });
    this.props.onChange(isChecked);
  }

  render() {
    const {
      checked,
      onChange,
      icon,
      value,
      className,
      'data-test': dataTest,
      ...rest
    } = this.props;

    const isChecked = checked || this.state.isChecked;

    return (
      <CheckboxContainer className={className}>
        <HiddenCheckbox
          type='checkbox'
          checked={isChecked}
          onChange={this.handleChange}
          value={value}
          className='checkbox-input'
          {...rest}
        />
        <StyledCheckbox checked={isChecked} className='checkbox-border' data-test={dataTest}>
          <IconContainer className='checkbox-icon'>{icon}</IconContainer>
        </StyledCheckbox>
      </CheckboxContainer>
    );
  }
}

Checkbox.propTypes = {
  checked: PropTypes.bool, // controlled
  initialChecked: PropTypes.bool, // uncontrolled
  className: PropTypes.string,
  icon: PropTypes.element,
  onChange: PropTypes.func, // controlled
  value: PropTypes.string,
};

Checkbox.defaultProps = {
  checked: false,
  initialChecked: false,
  className: '',
  icon: <CheckmarkIcon glyph='ok' />,
  onChange: () => {},
  value: undefined,
};

const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
`;

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const IconContainer = styled.div`
  text-align: center;
`;

// import this to override styling
export const StyledCheckbox = styled.div`
  display: inline-block;
  width: 18px;
  height: 18px;
  border-radius: 2px;
  ${IconContainer} {
    visibility: ${(props) => (props.checked ? 'visible' : 'hidden')};
  }
`;
