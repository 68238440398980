import { Map } from 'immutable';

export const LOAD_VIEWER_PROGRESS = 'LOAD_VIEWER_PROGRESS';
export const LOAD_PRIVATE_ROUTES_PROGRESS = 'LOAD_PRIVATE_ROUTES_PROGRESS';
export const LOAD_AIRWAY_WORKER_PROGRESS = 'LOAD_AIRWAY_WORKER_PROGRESS';
export const LOAD_AIRWAY_PROGRESS = 'LOAD_AIRWAY_PROGRESS';
export const LOAD_CENTERLINE_PROGRESS = 'LOAD_CENTERLINE_PROGRESS';

export const getProgress = (state, progressKey) =>
  state.get(progressKey, Map({ loaded: 0, total: 0 }));
