import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';

import BaseComponent from '../../components/shared/BaseComponent';
import ContainerHome from '../../components/shared/Container/ContainerHome';
import TextInput from '../../components/shared/Inputs';
import { ButtonDefault } from '../../components/shared/Buttons';

import { authLoginAction } from '../../modules/auth/authLogin';
import setLogoutWarningTimeout from '../../modules/auth/setLogoutWarningTimeout';

import {
  showNotificationSuccess,
  showNotificationError,
} from '../../modules/notifications/showNotification';

import zxcvbn, { loadZxcvbn } from '../../utils/zxcvbn';
import { getId } from '../../utils/general';
import ReactRouterPropTypes from '../../utils/propTypes/react-router';
import { httpStatusCodes, resetPasswordWithToken } from '../../utils/requests';
import { MIN_PASSWORD_LENGTH } from '../../settings/broncUsers';

export class PasswordResetConfirmComponent extends BaseComponent {
  constructor(...args) {
    super(...args);

    this.state = {
      password: '',
      passwordRepeat: '',
      passwordErrorMessage: '',
      confirmationPasswordErrorMessage: '',
      showExpired: false,
    };

    this.passwordInputGroupAddonId = getId('password-reset-confirm-password-input-group-addon');
    this.passwordRepeatInputGroupAddonId = getId(
      'password-reset-confirm-password-repeat-input-group-addon',
    );
  }

  componentDidMount() {
    loadZxcvbn();
  }

  isPasswordRepeatValid() {
    return this.state.passwordRepeat && this.state.passwordRepeat === this.state.password;
  }

  isFormValid() {
    return this.isPasswordRepeatValid();
  }

  handleSubmit(evt) {
    evt.preventDefault();

    if (!this.isFormValid()) {
      return;
    }

    resetPasswordWithToken(this.props.match.params.token, this.state.password)
      .then(({ token }) => {
        this.props.showNotificationSuccess(
          'Your password was successfully changed.<br/> You are now logged in.',
        );
        localStorage.setItem('token', token);
        this.props.authLoginAction(token);
        this.props.setLogoutWarningTimeout(token);
        this.props.history.push('/patients');
      })
      .catch((err) => {
        const { status } = (err || {}).response || {};

        if (status === httpStatusCodes.NOT_FOUND) {
          this.props.history.push('/password-reset/request');
          this.props.showNotificationError(
            "That password reset link is expired. <br /><br /> Enter your email and we'll send you a new one.",
          );
          return;
        }

        this.props.showNotificationError(
          'There was a problem changing password.<br /> Please try again.',
        );
        console.error(err); // eslint-disable-line no-console
      });
  }

  handlePasswordConfirmChange({ target: { name, value } }) {
    let confirmationPasswordErrorMessage = '';
    const passwordsMatch = value === this.state.password;

    if (value && !passwordsMatch) {
      confirmationPasswordErrorMessage = 'Passwords must match!';
    }

    this.setState({ [name]: value, confirmationPasswordErrorMessage });
  }

  handlePasswordChange({ target: { name, value } }) {
    const passwordValidation = zxcvbn(value);
    const passwordErrorMessage =
      value && value.length >= MIN_PASSWORD_LENGTH
        ? passwordValidation.feedback.warning
        : `Need at least ${MIN_PASSWORD_LENGTH} characters`;
    const confirmationPasswordErrorMessage =
      this.state.passwordRepeat !== value && this.state.passwordRepeat
        ? 'Passwords must match!'
        : '';

    this.setState({ [name]: value, passwordErrorMessage, confirmationPasswordErrorMessage });
  }

  render() {
    return (
      <ContainerHome>
        <PasswordContainer>
          <MenuTitle>Create New Password</MenuTitle>
          <Seperator />
          <FormStyling onSubmit={this.handleSubmit} className='form-horizontal'>
            <Input
              label='New Password'
              type='password'
              name='password'
              value={this.state.password}
              onChange={this.handlePasswordChange}
              aria-describedby={this.passwordInputGroupAddonId}
              data-test='password'
              errorMessage={this.state.passwordErrorMessage}
              showError={!!this.state.passwordErrorMessage}
              required
            />
            <Input
              label='Confirm Password'
              type='password'
              name='passwordRepeat'
              value={this.state.passwordRepeat}
              onChange={this.handlePasswordConfirmChange}
              aria-describedby={this.passwordRepeatInputGroupAddonId}
              data-test='confirm-password'
              errorMessage={this.state.confirmationPasswordErrorMessage}
              showError={!!this.state.confirmationPasswordErrorMessage}
              required
            />
            <ButtonContainer>
              <ButtonLayout>
                <SubmitButton
                  type='submit'
                  name='submit'
                  disabled={
                    !!zxcvbn(this.state.password).feedback.warning ||
                    this.state.password !== this.state.passwordRepeat ||
                    this.state.password === ''
                  }
                  data-test='reset-password-button'
                >
                  Reset Password
                </SubmitButton>
                <BackButton to='/login'>Back To Login</BackButton>
              </ButtonLayout>
            </ButtonContainer>
          </FormStyling>
        </PasswordContainer>
      </ContainerHome>
    );
  }
}

PasswordResetConfirmComponent.displayName = 'PasswordResetConfirm'; // fixes jest snapshot tests

PasswordResetConfirmComponent.propTypes = {
  match: ReactRouterPropTypes.match.isRequired,
  history: ReactRouterPropTypes.history.isRequired,
  authLoginAction: PropTypes.func.isRequired,
  setLogoutWarningTimeout: PropTypes.func.isRequired,
  showNotificationSuccess: PropTypes.func.isRequired,
  showNotificationError: PropTypes.func.isRequired,
};

export default withRouter(
  connect(null, {
    authLoginAction,
    setLogoutWarningTimeout,
    showNotificationSuccess,
    showNotificationError,
  })(PasswordResetConfirmComponent),
);

const PasswordContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.colors.white};
  width: 437px;
  box-shadow: 0 8px 10px -5px ${(props) => props.theme.colors.colorShadowHoverDark},
    0 6px 30px 5px ${(props) => props.theme.colors.colorShadowHoverLight},
    0 16px 24px 2px ${(props) => props.theme.colors.colorShadowHoverMedium};
  padding: 20px 40px;
  border-radius: 4px;
`;

const MenuTitle = styled.div`
  font-weight: 600;
  font-size: 18px;
  font-family: ${(props) => props.theme.fonts.fontFamilyDefault};
  margin-left: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
`;

const Seperator = styled.div`
  border-color: ${(props) => props.theme.colors.yellow60};
  border-width: 2px;
  border-style: solid;
`;

const FormStyling = styled.form`
  padding-top: 25px;
`;

const Input = styled(TextInput)`
  min-height: 95px;
  margin: 0;
`;

const ButtonContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  width: 100%;
`;

const ButtonLayout = styled.div`
  width: 100%;
  height: 100px;
  justify-content: space-evenly;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
`;

const SubmitButton = styled(ButtonDefault)`
  outline: none;
  background-color: ${(props) => props.theme.colors.purple70};
  width: 100%;

  :hover {
    color: ${(props) => props.theme.colors.white};
    background-color: ${(props) => props.theme.colors.purple70};
  }

  :active,
  :focus {
    background-color: ${(props) => props.theme.colors.purple90};
  }
`;

const BackButton = styled(Link)`
  color: #4d317c;
  font-weight: bold;
  font-family: ${(props) => props.theme.fonts.fontFamilyDefault};
`;
