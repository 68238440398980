import { Map, fromJS } from 'immutable';
import * as types from '../actions';

export const INITIAL_STATE = new Map({
  byId: new Map(),
});

function insertStent(state, payload) {
  const { stent } = payload;
  return state.mergeIn(['byId', stent.id], fromJS(stent));
}

export function insertStents(state, payload) {
  let newState = state;
  const { stents } = payload;
  stents.forEach((stent) => {
    newState = insertStent(newState, { stent });
  });

  return newState;
}

export function addStentThumbnail(state, payload) {
  const { stentID, blobURL } = payload;
  return state.setIn(['byId', stentID, 'thumbnail'], blobURL);
}

export function addStentOrderImage(state, { stentID, blobURL }) {
  return state.setIn(['byId', stentID, 'orderImage'], blobURL);
}

export function removeStentThumbnail(state, payload) {
  const { stentID } = payload;
  return state.deleteIn(['byId', stentID, 'thumbnail']);
}

export default function stentsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.CREATE_STENT:
    case types.UPDATE_STENT:
    case types.RECEIVE_STENT:
    case types.DUPLICATE_STENT:
      return insertStent(state, action.payload);
    case types.RECEIVE_STENTS_BY_SCAN:
      return insertStents(state, action.payload);
    case types.ADD_STENT_THUMBNAIL:
      return addStentThumbnail(state, action.payload);
    case types.ADD_STENT_ORDER_IMAGE:
      return addStentOrderImage(state, action.payload);
    case types.REMOVE_STENT_THUMBNAIL:
      return removeStentThumbnail(state, action.payload);
    case types.AUTH_LOGOUT:
      return INITIAL_STATE;
    default:
      return state;
  }
}
