import * as urls from '../settings/api';
import store from '../store';
import showIPWhitelistWarning from '../modules/auth/showIPWhitelistWarning';

export const httpStatusCodes = {
  OK: 200,
  CREATED: 201,
  NO_CONTENT: 204,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  CONFLICT: 409,
  INTERNAL_SERVER_ERROR: 500,
};

export const httpErrorMessages = {
  BAD_REQUEST: 'Bad request.',
  UNAUTHORIZED: 'Unauthorized',
  FORBIDDEN: 'Forbidden',
  IP_WHITELIST_REJECTION: 'IP address rejected due to whitelisting',
  NOT_FOUND: 'Resource not found.',
  DEFAULT: 'An unexpected error was encountered. Please try again.',
};

export class HttpError extends Error {
  constructor(response, message) {
    super(message);
    this.name = this.constructor.name;
    this.response = response;

    Error.captureStackTrace(this, this.constructor); // remove constructor from stack trace
  }
}

export class HttpBadRequestError extends HttpError {
  constructor(response, message = httpErrorMessages.BAD_REQUEST) {
    super(response, message);
  }
}

export class HttpUnauthorizedError extends HttpError {
  constructor(response, message = httpErrorMessages.UNAUTHORIZED) {
    super(response, message);
  }
}

export class HttpForbiddenError extends HttpError {
  constructor(response, message = httpErrorMessages.FORBIDDEN) {
    super(response, message);
  }
}

export class HttpNotFoundError extends HttpError {
  constructor(response, message = httpErrorMessages.NOT_FOUND) {
    super(response, message);
  }
}

export class HttpInternalServerError extends HttpError {
  constructor(response, message = httpErrorMessages.DEFAULT) {
    super(response, message);
  }
}

export function createUser(userInfo, token) {
  const body = {
    ...userInfo,
  };

  return apiFetch(urls.createUserURL(), token, 'POST', body);
}

export function registerUser(userInfo, recaptchaToken) {
  const body = {
    ...userInfo,
    'g-recaptcha-response': recaptchaToken,
  };

  return apiFetch(urls.registerUserURL(), null, 'POST', body);
}

export function activateUser(userID, activateToken) {
  const body = {
    uid: userID,
    token: activateToken,
  };

  return apiFetch(urls.activateUserURL(), null, 'POST', body);
}

export function resendAccountCreationEmail(userID, token) {
  return apiFetch(urls.resendAccountCreationEmailURL(userID), token, 'POST');
}

export function finishAccountCreation(userID, token, password) {
  return apiFetch(urls.API_FINISH_ACCOUNT_URL, null, 'POST', {
    userID,
    token,
    password,
  });
}

export function sendPasswordResetRequest(email) {
  return apiFetch(urls.API_PASSWORD_RESET_REQUEST_URL, null, 'POST', {
    email,
  });
}

export function resetPasswordWithToken(passwordToken, newPassword, forceChange = false) {
  return apiFetch(urls.API_PASSWORD_RESET_CONFIRM_URL, null, 'POST', {
    token: passwordToken,
    forceChange,
    newPassword,
  });
}

export function confirmEmailChange(userID, token) {
  const body = {
    uid: userID,
    token,
  };

  return apiFetch(urls.confirmEmailChangeURL(), null, 'POST', body);
}

export function getUsers(token, { withAffils = false } = {}) {
  return apiFetch(urls.getUsersURL({ withAffils }), token, 'GET');
}

export function getUser(userID, token) {
  return apiFetch(urls.getUserURL(userID), token, 'GET');
}

export function getDocUsers(token) {
  return apiFetch(urls.getUsersByUsertypeURL('doctor'), token, 'GET');
}

export function updateUser(userID, updateObj, token) {
  return apiFetch(urls.updateUserURL(userID), token, 'PATCH', updateObj);
}

export function changeEmail(userID, newEmail, currentPassword, token) {
  const body = {
    newEmail,
    password: currentPassword,
  };

  return apiFetch(urls.changeEmailURL(userID), token, 'POST', body);
}

export function changePassword(userID, currentPassword, newPassword, token) {
  const body = {
    password: currentPassword,
    new_password: newPassword,
  };

  return apiFetch(urls.changePasswordURL(userID), token, 'PATCH', body);
}

export function resendConfirmationEmail(email) {
  return apiFetch(urls.resendConfirmationURL(email), null, 'POST');
}

export function getShippingAddressesByUser(userID, token) {
  return apiFetch(urls.getShippingAddressesByUserURL(userID), token, 'GET');
}

export function getShippingAddress(shippingAddressID, token) {
  return apiFetch(urls.getShippingAddressURL(shippingAddressID), token, 'GET');
}

export function createShippingAddress(address, token) {
  return apiFetch(urls.createShippingAddressURL(), token, 'POST', address);
}

export function updateShippingAddress(shippingAddressID, updateObj, token) {
  return apiFetch(urls.updateShippingAddressURL(shippingAddressID), token, 'PATCH', updateObj);
}

export function deleteShippingAddress(shippingAddressID, token) {
  return apiFetch(urls.deleteShippingAddressURL(shippingAddressID), token, 'DELETE');
}

export function getAffiliations(token) {
  return apiFetch(urls.getAffiliationsURL(), token, 'GET');
}

export function getAffiliationsByUser(userID, token) {
  return apiFetch(urls.getAffiliationsByUserURL(userID), token, 'GET');
}

export function getAffiliationByID(affID, token) {
  return apiFetch(urls.getAffiliationByID(affID), token, 'GET');
}

export function createAffiliation(affiliation, token) {
  return apiFetch(urls.createAffiliationURL(), token, 'POST', affiliation);
}

export function linkUserToAffil(userID, affilID, token) {
  const body = {
    userID,
    link: true,
  };
  return apiFetch(urls.linkAffiliationURL(affilID), token, 'POST', body);
}

export function unlinkUserFromAffil(userID, affilID, token) {
  const body = {
    userID,
    link: false,
  };
  return apiFetch(urls.linkAffiliationURL(affilID), token, 'POST', body);
}

export function updateAffiliation(affiliationID, updatedAffiliationObj, token) {
  return apiFetch(urls.updateAffiliationURL(affiliationID), token, 'PATCH', updatedAffiliationObj);
}

export function updateAffilBilling(affilID, updateBillingObj, token) {
  return apiFetch(urls.updateAffilBillingURL(affilID), token, 'PATCH', updateBillingObj);
}

export function getHospital(hospitalID, token) {
  return apiFetch(urls.getHospitalURL(hospitalID), token, 'GET');
}

export function getHospitals(token) {
  return apiFetch(urls.getHospitalsURL(), token, 'GET');
}

export function getDiagnoses(token) {
  return apiFetch(urls.getDiagnosesURL(), token, 'GET');
}

export function updateScanDiagnoses(scanID, body, token) {
  return apiFetch(urls.updateScanDiagnosesURL(scanID), token, 'PATCH', body);
}

export function getVolume3D(volID, token) {
  return apiFetch(`/api/volume3ds/${volID}`, token, 'GET');
}

export function getDeviceLabelsByPackingList(packingListID, token) {
  return apiFetch(urls.getDeviceLabelsByPackingListURL(packingListID), token, 'GET');
}

export function getPackingListsByOrder(orderNumber) {
  return apiFetch(
    urls.getPackingListsByOrderURL(orderNumber),
    localStorage.getItem('token'),
    'GET',
  );
}

export function fetchBannerMessage(url) {
  return fetch(url, initialize(null, 'GET')).then(parseFetchResponse);
}

export function fetchBundleStats() {
  return fetch('/stats.json', initialize(null, 'GET')).then((res) => {
    if (!res.ok) {
      throw new Error('bundle stats file not found');
    }

    return res.json();
  });
}

export function getJailedAirways(stentID) {
  return apiFetch(urls.getJailedAirwaysURL(stentID), localStorage.getItem('token'), 'GET');
}

export function getESSModels() {
  return apiFetch('/api/stents/ess', localStorage.getItem('token'), 'GET');
}

export function getStandardStentModels() {
  return apiFetch('/api/stents/standard-stents', localStorage.getItem('token'), 'GET');
}

export function uploadOrderFile(file, orderNum, fileName, token, body = {}) {
  const uploadURL = fullUrl(urls.uploadOrderFileURL(orderNum));
  const opts = {
    headers: {
      Authorization: `JWT ${token}`,
      fileName,
    },
    responseType: 'json',
    body,
  };

  return uploadFile(file, uploadURL, 'file', opts);
}

export function uploadPackingList(file, orderNum, token, body = {}) {
  const uploadURL = fullUrl(urls.uploadPackingListURL(orderNum));
  const opts = {
    headers: {
      Authorization: `JWT ${token}`,
      'Response-Type': 'application/json',
    },
    responseType: 'json',
    body,
  };

  return uploadFile(file, uploadURL, 'packingList', opts);
}

export function searchScans({
  scanNum,
  affiliationID,
  doctorName,
  patientName,
  scanStatus, // can supply array of statuses
  acqDate, // supply start/end dates for range
  uploadDate, // supply start/end dates for range
  verifRejDate, // supply start/end dates for range
  sortField,
  sortDir,
  limit,
  offset,
}) {
  const searchValues = {
    scanNum,
    affiliationID,
    doctorName,
    patientName,
    scanStatus,
    acqDate,
    uploadDate,
    verifRejDate,
    limit,
    offset,
  };

  let url = urls.searchScansURL(searchValues);
  if (sortField) {
    url = `${url}&sort=${sortField}:${sortDir}`;
  }

  return apiFetch(url, localStorage.getItem('token'), 'GET');
}

export function searchPatients({
  name,
  doctorName,
  mrn,
  dob,
  latestUploadDateStart, // ISO 8601 format
  latestUploadDateEnd, // ISO 8601 format
  sortField,
  sortDir,
  offset,
  limit,
}) {
  const searchValues = {
    name,
    doctorName,
    mrn,
    dob,
    latestUploadDateStart,
    latestUploadDateEnd,
    offset,
    limit,
  };

  let url = urls.searchPatientsURL(searchValues);
  if (sortField) {
    url = `${url}&sort=${sortField}:${sortDir}`;
  }

  return apiFetch(url, localStorage.getItem('token'), 'GET');
}

export function searchUsers({
  name,
  email,
  usertype,
  withAffils,
  sortField,
  sortDir,
  offset,
  limit,
}) {
  const searchValues = {
    name,
    email,
    usertype,
    withAffils,
    offset,
    limit,
  };

  let url = urls.searchUsersURL(searchValues);
  if (sortField) {
    url += `&sort=${sortField}:${sortDir}`;
  }

  return apiFetch(url, localStorage.getItem('token'), 'GET');
}

export function searchAffiliations({ hospital, state, offset, limit, sortField, sortDir }) {
  const searchValues = { hospital, state, offset, limit };

  let url = urls.searchAffiliationsURL(searchValues);
  if (sortField) {
    url += `&sort=${sortField}:${sortDir}`;
  }

  return apiFetch(url, localStorage.getItem('token'), 'GET');
}

export function searchOrders({
  orderNumber,
  orderStatus, // can supply array of statuses
  orderDate, // supply start/end dates for range
  patientName,
  patientMRN,
  scanNum,
  doctorName,
  affiliationID,
  offset,
  limit,
  sortField,
  sortDir,
}) {
  const searchValues = {
    orderNumber,
    orderStatus,
    orderDate,
    patientName,
    patientMRN,
    scanNum,
    doctorName,
    affiliationID,
    limit,
    offset,
  };

  let url = urls.searchOrdersURL(searchValues);
  if (sortField) {
    url = `${url}&sort=${sortField}:${sortDir}`;
  }

  return apiFetch(url, localStorage.getItem('token'), 'GET');
}

export function apiFetch(path, ...args) {
  const url = fullUrl(path);
  const init = initialize(...args);
  return fetch(url, init).then(parseResponse);
}

export async function fetchFileWithProgress(fileURL, token, onProgressCallback = () => {}) {
  const response = await fetch(fullUrl(fileURL), initialize(token, 'GET'));
  if (!response.ok) {
    return parseResponseError(response);
  }
  if (!response.body) {
    throw Error('ReadableStream unsupported by this browser');
  }

  const contentLength = response.headers.get('content-length');
  if (!contentLength) {
    console.warn('Content-Length response header not found'); // eslint-disable-line no-console
  }

  const totalFileSize = parseInt(contentLength, 10);
  if (totalFileSize === -1) {
    console.warn('Content-Length unknown'); // eslint-disable-line no-console
  }

  const isContentLengthDefined = Boolean(contentLength) && totalFileSize !== -1;
  if (!isContentLengthDefined) {
    onProgressCallback({ loaded: 0, total: 1 });
  }

  let loaded = 0;

  const reader = response.body.getReader();

  const stream = new ReadableStream({
    start(controller) {
      function read() {
        return reader.read().then(({ done, value }) => {
          if (done) {
            if (!isContentLengthDefined) {
              onProgressCallback({ loaded, total: loaded });
            }

            controller.close();
            return;
          }

          loaded += value.byteLength;

          if (isContentLengthDefined) {
            onProgressCallback({ loaded, total: totalFileSize });
          }

          controller.enqueue(value);
          read();
        });
      }

      read();
    },
  });

  return new Response(stream).arrayBuffer();
}

export async function downloadScanZip(scanID, fileName, token) {
  if (fileName.startsWith('https://')) {
    // get presigned S3 URL
    const { s3URL } = await apiFetch(urls.downloadScanZipURL(scanID), token, 'GET');

    // download file from S3
    const res = await fetch(s3URL, initialize(undefined, 'GET'));
    if (!res.ok) {
      return parseResponseError(res);
    }

    return res.arrayBuffer();
  }

  // download from LFS
  return fetchScanFile(scanID, fileName, token);
}

export function fetchScanFile(scanID, fileName, token) {
  const url = urls.getScanFileURL(scanID, fileName);

  return fetch(fullUrl(url), initialize(token, 'GET')).then((res) => {
    if (!res.ok) {
      return parseResponseError(res);
    }

    return res.arrayBuffer();
  });
}

export function fetchStentFile(stentID, fileName, token) {
  const url = fullUrl(urls.getStentFileURL(stentID, fileName));

  return fetch(url, initialize(token, 'GET')).then(async (res) => {
    if (!res.ok) {
      throw await parseResponseError(res);
    }

    return res.blob();
  });
}

export function fetchLabelFile(labelID, filename) {
  const url = fullUrl(urls.getDeviceLabelFileURL(labelID, filename));

  return fetch(url, initialize(localStorage.getItem('token'), 'GET'))
    .then(async (res) => {
      if (!res.ok) {
        throw await parseResponseError(res);
      }

      return res.blob();
    })
    .then((blob) => new File([blob], filename));
}

export function fullUrl(path = '') {
  return urls.API_BASE_URL + path;
}

export function initialize(token, method = 'GET', data, form) {
  const headers = new Headers();
  if (!form) {
    headers.append('Content-Type', 'application/json');
  }
  if (token !== undefined) {
    addAuthHeader(token, headers);
  }

  let body;
  if (form === true) {
    const formdata = addFormData(data);
    body = formdata;
  } else if (data !== undefined) {
    body = JSON.stringify(data);
  }

  return {
    method,
    headers,
    body,
  };
}

export function addAuthHeader(token, headers = new Headers()) {
  headers.append('Authorization', `JWT ${token}`);
  return headers;
}

export function addFormData(data, formData = new FormData()) {
  Object.entries(data).forEach(([name, val]) => {
    formData.append(name, val);
  });

  return formData;
}

export function parseResponse(response) {
  // assume it's a fetch request if not XHR
  return isXHR(response) ? parseXHRResponse(response) : parseFetchResponse(response);
}

export function isXHR(response) {
  return response.constructor.name === 'XMLHttpRequest';
}

export async function parseXHRResponse(xhrResponse) {
  const { status, response } = xhrResponse;

  if (status / 100 !== 2) {
    // ERROR
    throw await parseResponseError(xhrResponse);
  }

  return response;
}

export async function parseFetchResponse(fetchResponse) {
  if (!fetchResponse.ok) {
    // ERROR
    throw await parseResponseError(fetchResponse);
  }

  return fetchResponse.json();
}

async function parseResponseError(response) {
  let error;

  // https://css-tricks.com/using-fetch/#article-header-id-5
  const json = await response.json?.();
  const resp = { ...json, status: response.status, statusText: response.statusText };

  switch (response.status) {
    case httpStatusCodes.BAD_REQUEST:
      error = new HttpBadRequestError(resp);
      break;
    case httpStatusCodes.UNAUTHORIZED:
      error = new HttpUnauthorizedError(resp);
      break;
    case httpStatusCodes.FORBIDDEN:
      error = new HttpForbiddenError(resp);
      if (error.response?.error === 'IP address rejected') {
        error = new HttpForbiddenError(resp, httpErrorMessages.IP_WHITELIST_REJECTION);
        store.dispatch(showIPWhitelistWarning(true));
      }
      break;
    case httpStatusCodes.NOT_FOUND:
      error = new HttpNotFoundError(resp);
      break;
    default:
      error = new HttpInternalServerError(resp);
      break;
  }

  throw error;
}

export function uploadFile(file, url, apiFieldName, opts, onProgressCallback = () => {}) {
  // promisify XHR
  // eslint-disable-next-line promise/avoid-new
  return new Promise((resolve, reject) => {
    // eslint-disable-line promise/avoid-new
    const xhr = new XMLHttpRequest();

    // attach listeners before opening!
    xhr.upload.onprogress = (e) => {
      if (e.lengthComputable) {
        const percentage = Math.round((e.loaded / e.total) * 100);
        onProgressCallback(percentage);
      }
    };

    xhr.onload = async () => {
      try {
        const { status } = xhr;
        const data = await parseXHRResponse(xhr);
        resolve({ status, data });
      } catch (err) {
        console.error(err); // eslint-disable-line no-console
        reject(xhr);
      }
    };

    xhr.onerror = () => {
      const status = httpStatusCodes.NOT_FOUND;
      const message = 'Network error occurred.';
      reject({ status, message }); // eslint-disable-line prefer-promise-reject-errors
    };

    xhr.open('POST', url, true);

    // add headers, if any
    if (opts.headers) {
      Object.keys(opts.headers).forEach((key) => {
        xhr.setRequestHeader(key, opts.headers[key]);
      });
    }

    // set response type, if any
    if (opts.responseType) {
      xhr.responseType = opts.responseType;
    }

    // create request body by merging file with specified existing body
    const data = {
      [apiFieldName]: file,
      ...opts.body,
    };
    const body = addFormData(data);

    // send request
    xhr.send(body);
  });
}
