import { hot } from 'react-hot-loader/root';
import React from 'react';
import jwt from 'jwt-decode';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import CaptureRoute404 from './Route404';
import Activate from './Activate';
import PasswordCreate from './PasswordCreate';
import PasswordResetRequest from './PasswordResetRequest';
import PasswordResetConfirm from './PasswordResetConfirm';
import EmailChange from './EmailChange';

import Home from './Home';
import lazyLoadHOC from '../../components/shared/LoadingIndicators/lazyLoadHOC';
import Notification from '../../components/shared/Notification/Notification';
import HaveIBeenPwnedModal from '../../components/shared/Modals/HaveIBeenPwnedModal';

import setLogoutWarningTimeout from '../../modules/auth/setLogoutWarningTimeout';
import { authLoginAction } from '../../modules/auth/authLogin';
import store from '../../store';

const LazyPrivateRoutes = lazyLoadHOC(React.lazy(() => import('../private/PrivateRoutes')));

if (localStorage.getItem('token') !== null) {
  const token = localStorage.getItem('token');
  const expiresAtMs = jwt(token).exp * 1000;

  if (expiresAtMs <= Date.now()) {
    localStorage.removeItem('token');
  } else {
    store.dispatch(authLoginAction(token));
    store.dispatch(setLogoutWarningTimeout(token));
  }
}

function Routes() {
  return (
    <Provider store={store}>
      <>
        <Notification />
        <Router>
          <HaveIBeenPwnedModal />
          <CaptureRoute404>
            <Switch>
              <Route exact path='/' component={Home} />
              <Route exact path='/logout' render={(props) => <Home {...props} shouldLogout />} />
              <Route exact path='/login' component={Home} />
              <Route
                exact
                path='/signup'
                render={(props) => (
                  <Home
                    {...props}
                    initialActiveTab='register'
                    shouldRenderRecaptcha={process.env.VITE_TARGET_ENV === 'production'}
                  />
                )}
              />
              <Route exact path='/activate/:uid/:token' component={Activate} />
              <Route exact path='/finish-account/:uid/:token' component={PasswordCreate} />
              <Route exact path='/password-reset/request' component={PasswordResetRequest} />
              <Route exact path='/password-reset/confirm/:token' component={PasswordResetConfirm} />
              <Route exact path='/change-email/:uid/:token' component={EmailChange} />
              <Route component={LazyPrivateRoutes} />
            </Switch>
          </CaptureRoute404>
        </Router>
      </>
    </Provider>
  );
}

// expose store when run in Cypress
if (window.Cypress) {
  window.store = store;
}

export default hot(Routes);
