import { fetchBundleStats } from './requests';
import store from '../store';
import setProgress from '../modules/progress/setProgress';
import {
  LOAD_VIEWER_PROGRESS,
  LOAD_PRIVATE_ROUTES_PROGRESS,
  LOAD_AIRWAY_WORKER_PROGRESS,
} from '../modules/progress/selectors';

export const VIEWER_CHUNK_NAME = /^assets\/ViewerPage.[a-f0-9]+.js/;
export const PRIVATE_ROUTES_CHUNK_NAME = /^assets\/PrivateRoutes.[a-f0-9]+.js/;

export const AIRWAY_CHUNK_NAME = /^assets\/airway.[a-f0-9]+.js/;
export const AIRWAY_SIZE = 734000;

export const CHUNKS = [
  {
    chunkName: VIEWER_CHUNK_NAME,
    progressKey: LOAD_VIEWER_PROGRESS,
  },
  {
    chunkName: PRIVATE_ROUTES_CHUNK_NAME,
    progressKey: LOAD_PRIVATE_ROUTES_PROGRESS,
  },
  {
    chunkName: AIRWAY_CHUNK_NAME,
    progressKey: LOAD_AIRWAY_WORKER_PROGRESS,
    estimatedSize: AIRWAY_SIZE,
  },
];

const bundleStatsPromise = fetchBundleStats()
  .then((bundleStats) => {
    CHUNKS.forEach(({ chunkName, progressKey, estimatedSize }) => {
      const chunkSize = getChunkSize(chunkName, bundleStats, estimatedSize);
      store.dispatch(setProgress(progressKey, 0, chunkSize));
    });

    return Object.freeze(bundleStats);
  })
  .catch((err) => {
    // eslint-disable-next-line no-console
    console.error(err);
    return null;
  });

export const getBundleStats = () => bundleStatsPromise;

export function getChunkSize(chunkName, bundleStats, estimatedSize) {
  if (estimatedSize) {
    return estimatedSize;
  }

  let chunkNameRegex = chunkName;
  if (!(chunkNameRegex instanceof RegExp)) {
    chunkNameRegex = new RegExp(chunkNameRegex);
  }

  const rootChildren = bundleStats.tree.children;
  const chunkRoot = rootChildren.find((child) => chunkNameRegex.test(child.name));
  if (!chunkRoot) {
    console.error(`No chunk found with name matching ${chunkName}`); // eslint-disable-line no-console
    return [];
  }

  const childrenUIDs = getChildrenUIDs(chunkRoot);

  const chunkSize = childrenUIDs.reduce(
    (accum, uid) => accum + bundleStats.nodeParts[uid].renderedLength,
    0,
  );

  return chunkSize;
}

function getChildrenUIDs(root, uids = []) {
  root.children.forEach((child) => {
    if (child.uid) {
      uids.push(child.uid);
      return;
    }
    if (child.children) {
      getChildrenUIDs(child, uids);
    }
  });

  return uids;
}
