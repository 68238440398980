import { Map } from 'immutable';
import jwt from 'jwt-decode';
import * as types from '../actions';

export const INITIAL_STATE = new Map({
  connected: true,
  showLogoutWarning: false,
  token: null,
  userID: null,
  userType: null,
  email: null,
  firstName: null,
  lastName: null,
  isActive: null,
  tokenExpiration: null,
  tokenIssuedAt: null,
  tokenOrigIssuedAt: null,
  refreshedAtMs: null,
  showOnboardingModal: false,
  showHaveIBeenPwnedModal: false,
  pwnedPasswordCount: 0,
  showIPWhitelistWarning: false,
});

export default function auth(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.AUTH_LOGIN:
      return authLogin(state, action.payload);
    case types.AUTH_LOGOUT:
      return authLogout(state);
    case types.AUTH_REFRESH:
      return authRefresh(state, action.payload);
    case types.SHOW_LOGOUT_WARNING:
      return showLogoutWarning(state, action);
    case types.AUTH_SET_CONNECTED:
      return authSetConnected(state, action.payload);
    case types.AUTH_SET_USER_INFO:
      return setUserInfo(state, action.user);
    case types.AUTH_SHOW_ONBOARDING_MODAL:
      return showOnboardingModal(state, action);
    case types.AUTH_SHOW_HIBP_MODAL:
      return showHIBPModal(state, action);
    case types.AUTH_SHOW_IP_WHITELIST_WARNING:
      return showIPWhitelistWarning(state, action);
    default:
      return state;
  }
}

function authLogout(state) {
  return state.delete('token');
}

function showLogoutWarning(state, { showWarning }) {
  return state.set('showLogoutWarning', showWarning);
}

function authSetToken(state, token) {
  if (!token) {
    return state.set('token', null);
  }

  const { exp, iat, id, usertype, email, origIssuedAt } = jwt(token);

  return state
    .set('token', token)
    .set('tokenExpiration', exp)
    .set('tokenIssuedAt', iat)
    .set('tokenOrigIssuedAt', origIssuedAt)
    .set('userID', id)
    .set('email', email)
    .set('userType', usertype);
}

function authLogin(state, payload) {
  return authSetToken(state, payload.token);
}

function authRefresh(state, payload) {
  return authSetToken(state, payload.token).set('refreshedAtMs', payload.refreshedAtMs);
}

function authSetConnected(state, payload) {
  return state.set('connected', payload.isConnected);
}

function setUserInfo(state, user) {
  return state.withMutations((mutState) =>
    mutState
      .set('firstName', user.firstName)
      .set('lastName', user.lastName)
      .set('isActive', user.isActive),
  );
}

function showOnboardingModal(state, { show }) {
  return state.set('showOnboardingModal', show);
}

function showHIBPModal(state, { show, pwnedPasswordCount }) {
  return state.set('showHaveIBeenPwnedModal', show).set('pwnedPasswordCount', pwnedPasswordCount);
}

function showIPWhitelistWarning(state, { show }) {
  return state.set('showIPWhitelistWarning', show);
}
