import React from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';

const Bounce = keyframes`
  0%, 80%, 100% {
    transform: scale(0);
  } 40% {
    transform: scale(1.0);
  }
`;

const StyledDotsLoading = styled.div`
  display: inline-block;
`;

const Dot = styled.span`
  width: ${(props) => (props.big ? '20px' : '12px')};
  height: ${(props) => (props.big ? '20px' : '12px')};
  background-color: ${(props) => props.color || '#000'};
  border-radius: 100%;
  display: inline-block;
  animation: ${Bounce} 1s infinite ease-in-out both;
  &:first-child {
    animation-delay: -0.32s;
  }
  &:nth-child(2) {
    animation-delay: -0.16s;
  }
`;

function LoadingThreeDots({ color, big, ...rest }) {
  return (
    <StyledDotsLoading {...rest}>
      <Dot color={color} big={big} style={{}} />
      <Dot color={color} big={big} style={{}} />
      <Dot color={color} big={big} style={{}} />
    </StyledDotsLoading>
  );
}

LoadingThreeDots.propTypes = {
  color: PropTypes.string,
  big: PropTypes.bool,
};

LoadingThreeDots.defaultProps = {
  color: '',
  big: false,
};

export default LoadingThreeDots;
