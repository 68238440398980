import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import ViewerLoadingScreen from '../../viewerPage/ViewerLoadingScreen';
import VisionairLoadingIcon from './VisionairLoadingIcon';
import ContainerLoggedIn from '../Container/ContainerLoggedIn';

import setViewerLoadingScreenVisible from '../../../modules/viewerPage/setViewerLoadingScreenVisible';
import reactRouter from '../../../utils/propTypes/react-router';

export const DEFAULT_3D_OBJECT_SIZE = 1000000; // 1 MB

export function LoadingScreenComponent({ location }) {
  if (/\/viewer\/\d+$/.test(location.pathname)) {
    return <ViewerLoadingScreen />;
  }

  return (
    <ContainerLoggedIn>
      <VisionairLoadingIcon />
    </ContainerLoggedIn>
  );
}

LoadingScreenComponent.propTypes = {
  // eslint-disable-next-line react/no-typos
  location: reactRouter.location.isRequired,
};

const mapDispatchToProps = {
  setViewerLoadingScreenVisible,
};

export default withRouter(connect(undefined, mapDispatchToProps)(LoadingScreenComponent));
