import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect, withRouter } from 'react-router-dom';

import { showNotificationError } from '../../modules/notifications/showNotification';

import reactRouter from '../../utils/propTypes/react-router';

export function Route404({ redirectTo, location }) {
  console.error(`Page not found: ${location?.pathname}`); // eslint-disable-line no-console

  return <Redirect to={{ state: { routeNotFound: true, pathname: redirectTo } }} />;
}

Route404.propTypes = {
  redirectTo: PropTypes.string,
  // eslint-disable-next-line react/no-typos
  location: reactRouter.location.isRequired,
};

Route404.defaultProps = {
  redirectTo: null,
};

export function CaptureRoute404Component({
  children,
  location,
  showNotificationError: showErrorNotification,
}) {
  useEffect(() => {
    if (/\/viewer\/\d+/.test(location?.pathname)) {
      // hide groove widget on Viewer Page
      window.groove.widget('unload');
      window.groove.loaded = false;
    } else if (!window.groove.loaded) {
      // show groove widget on every other page
      window.groove.widget('load');
      window.groove.loaded = true;
    }
  }, [location]);

  if (location?.state?.routeNotFound) {
    // route was not found, redirect to path if specified
    showErrorNotification('The page you requested could not be found.', 5000);
    const redirectToPath = location.state?.pathname ? location.state.pathname : '/patients';

    return <Redirect to={{ pathname: redirectToPath }} />;
  }

  // route was found, continue as normal
  return children;
}

CaptureRoute404Component.propTypes = {
  showNotificationError: PropTypes.func.isRequired,
};

export default withRouter(connect(undefined, { showNotificationError })(CaptureRoute404Component));
