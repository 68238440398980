import * as Yup from 'yup';

const defaultZxcvbn = {
  feedback: {
    warning: '',
  },
};
let zxcvbn;

export default function checkPassword(password) {
  if (!zxcvbn) {
    return defaultZxcvbn;
  }

  return zxcvbn(password);
}

export function loadZxcvbn() {
  if (zxcvbn) {
    return Promise.resolve();
  }

  return Promise.all([
    import('@zxcvbn-ts/core'),
    import('@zxcvbn-ts/language-common'),
    import('@zxcvbn-ts/language-en'),
  ]).then(([corePkg, commonPkg, enPkg]) => {
    const opts = {
      dictionary: {
        ...commonPkg.default.dictionary,
        ...enPkg.default.dictionary,
      },
      graphs: commonPkg.default.adjacencyGraphs,
      translations: enPkg.default.translations,
    };
    corePkg.zxcvbnOptions.setOptions(opts);

    zxcvbn = corePkg.zxcvbn; // eslint-disable-line prefer-destructuring
  });
}

function passwordStrength() {
  // eslint-disable-next-line func-names
  return this.test('password-strength', '', function (value) {
    const { path, createError } = this;

    if (!value) {
      return true;
    }

    const passwordStrengthError = zxcvbn(value).feedback.warning;
    if (passwordStrengthError) {
      return createError({ path, message: passwordStrengthError });
    }

    return true;
  });
}
Yup.addMethod(Yup.string, 'passwordStrength', passwordStrength);
