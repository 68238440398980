import { createMuiTheme } from '@material-ui/core/styles';

import fonts from './fonts';

const muiTheme = createMuiTheme({
  typography: {
    fontFamily: fonts.fontFamilyDefault,
    button: {
      textTransform: 'none',
    },
  },
});

export default muiTheme;
