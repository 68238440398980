import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import Nav from '../Nav/Nav';
import StatusBanner from '../Notification/StatusBanner';

import { getBannerIsOpen } from '../../../modules/notifications/selectors';

function ContainerLoggedIn({ children, className }) {
  const isBannerOpen = useSelector(({ notifications }) => getBannerIsOpen(notifications));

  return (
    <PageContainer className={className}>
      <Nav />
      <StatusBanner />
      <BodyContainer isBannerOpen={isBannerOpen}>{children}</BodyContainer>
    </PageContainer>
  );
}

ContainerLoggedIn.defaultProps = {
  className: '',
};

ContainerLoggedIn.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element])
    .isRequired,
  className: PropTypes.string,
};

const PageContainer = styled.div`
  width: 100%;
  min-height: 100%;

  font-family: ${(props) => props.theme.fonts.fontFamilyDefault};
`;

const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f7f7f7;

  min-height: ${({ isBannerOpen }) =>
    isBannerOpen ? 'calc(100vh - 124px);' : 'calc(100vh - 64px);'};
`;

function HeaderFullWidth({ children, className }) {
  return (
    <FullWidth className={className}>
      <Header>{children}</Header>
    </FullWidth>
  );
}

HeaderFullWidth.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

HeaderFullWidth.defaultProps = {
  className: '',
};

const FullWidth = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const Header = styled.div`
  min-width: 1024px;
  max-width: 1920px;
  width: 100%;

  min-height: 100px;

  padding-left: 100px;
  padding-right: 100px;
  @media (max-width: 1440px) {
    padding-left: 60px;
    padding-right: 60px;
  }
`;

const Content = styled.div`
  min-width: 1024px;
  max-width: 1920px;
  width: 100%;

  flex-grow: 1;

  padding-left: 100px;
  padding-right: 100px;
  @media (max-width: 1440px) {
    padding-left: 60px;
    padding-right: 60px;
  }
`;

ContainerLoggedIn.Header = Header;
ContainerLoggedIn.HeaderFullWidth = HeaderFullWidth;
ContainerLoggedIn.Content = Content;

export default ContainerLoggedIn;
