import colors from './colors';
import fonts from './fonts';

export default {
  PageHeading: {
    'font-family': fonts.fontFamilyDefault,
    'font-size': '24px',
    color: colors.black,
  },

  BodyDefault: {
    'font-family': fonts.fontFamilyDefault,
    'font-size': '14px',
    color: colors.black,
  },
  BodyBold: {
    'font-size': '14px',
    'font-weight': '500',
  },

  BodySmall: {
    'font-family': fonts.fontFamilyDefault,
    'font-size': '12px',
    color: colors.black,
  },
  BodySmallBold: {
    'font-size': '12px',
    'font-weight': '500',
  },
};
