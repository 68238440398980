export const AUTH_LOGIN = 'AUTH_LOGIN';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const SHOW_LOGOUT_WARNING = 'SHOW_LOGOUT_WARNING';
export const AUTH_REFRESH = 'AUTH_REFRESH';
export const AUTH_SET_CONNECTED = 'AUTH_SET_CONNECTED';
export const AUTH_APPROVE = 'AUTH_APPROVE';
export const AUTH_SET_USER_INFO = 'AUTH_SET_USER_INFO';
export const AUTH_SHOW_ONBOARDING_MODAL = 'AUTH_SHOW_ONBOARDING_MODAL';
export const AUTH_SHOW_HIBP_MODAL = 'AUTH_SHOW_HIBP_MODAL';
export const AUTH_SHOW_IP_WHITELIST_WARNING = 'AUTH_SHOW_IP_WHITELIST_WARNING';
